import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationBandageHeart: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-bandage"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 73.3333C36.7555 73.3333 26.2667 67.0667 18.7555 60.6222C10.5333 53.6 1.77777 44.8889 1.77777 30.4C1.77777 21.6444 5.33333 16.0889 8.3111 12.9778C12.1333 8.97778 17.2444 6.66667 22.3111 6.66667C31.5555 6.66667 37.6444 13.6 40.0444 16.8889C40.8889 15.7778 42.1778 14.2222 43.8667 12.6667C48.0889 8.75556 52.8889 6.66667 57.6444 6.66667C62.7111 6.66667 67.8222 8.97778 71.6444 12.9778C74.6222 16.0889 78.1778 21.6444 78.1778 30.4C78.1778 44.8889 69.4222 53.6 61.2 60.6222C54.2222 66.6222 43.6444 73.3333 40 73.3333ZM22.3555 9.33334C13.5555 9.33334 4.44444 17.2 4.44444 30.4C4.44444 43.7333 12.7555 51.9556 20.4889 58.6222C28.1333 65.1556 37.9111 70.6667 40 70.6667C42.1333 70.6667 51.4667 65.5111 59.5111 58.6222C67.2889 51.9556 75.5555 43.7778 75.5555 30.4C75.5555 17.2 66.4444 9.33334 57.6444 9.33334C53.5111 9.33334 49.4667 11.1111 45.6889 14.5778C42.8 17.2444 41.1555 19.9556 41.1111 20C40.8889 20.4 40.4444 20.6222 40 20.6222C39.5111 20.6222 39.1111 20.3556 38.8444 19.9556C38.8 19.8667 32.6222 9.33334 22.3555 9.33334Z"
        fill={color}
      />
      <path
        d="M33.4222 60.8889C31.2889 60.8889 29.2889 59.9556 27.5111 58.1778C25.6889 56.3555 24.8 54.3111 24.8889 52.1778C25.0222 48.7111 27.6 46.2222 27.9555 45.9111L54 20.3111C54.3111 19.9556 56.8444 17.4222 60.3111 17.3333C62.4444 17.2889 64.4889 18.2222 66.2667 20.0444C68.0889 21.9111 68.9778 23.9111 68.9333 26.0889C68.8444 29.5556 66.2667 32.0444 65.9555 32.3111L39.7778 58.0444C39.5111 58.3556 36.9778 60.8444 33.5111 60.9333C33.4667 60.8889 33.4667 60.8889 33.4222 60.8889ZM60.4444 20C60.4 20 60.4 20 60.3555 20C57.9111 20.0444 55.9555 22.1333 55.9111 22.1333L55.8667 22.1778L29.7778 47.8222L29.7333 47.8667C29.7333 47.8667 27.6 49.8222 27.5555 52.2667C27.5111 53.6444 28.1333 55.0222 29.4222 56.2667C30.7111 57.5556 32.0889 58.2222 33.4667 58.2222C35.9111 58.1778 37.8667 56.1778 37.8667 56.1778L37.9111 56.1333L64.0889 30.4L64.1333 30.3556C64.1333 30.3556 66.1778 28.4444 66.2667 26C66.3111 24.5778 65.6889 23.2444 64.4 21.9111C63.1111 20.6222 61.7778 20 60.4444 20Z"
        fill={color}
      />
      <path
        d="M46.8445 50.5778C46.4889 50.5778 46.1778 50.4444 45.9111 50.1778L35.8667 40C35.6 39.7333 35.4667 39.4222 35.4667 39.0667C35.4667 38.7111 35.6 38.3556 35.8667 38.1333L46.0445 28.1333C46.5778 27.6 47.4222 27.6444 47.9111 28.1333L57.9556 38.3111C58.2222 38.5778 58.3556 38.8889 58.3556 39.2444C58.3556 39.6 58.2222 39.9556 57.9556 40.1778L47.7778 50.1778C47.5111 50.4444 47.2 50.5778 46.8445 50.5778ZM38.7111 39.0667L46.8889 47.3333L55.1556 39.2L46.9778 30.9333L38.7111 39.0667Z"
        fill={color}
      />
      <path
        d="M59.0667 23.7778C59.3612 23.7778 59.6 23.539 59.6 23.2445C59.6 22.9499 59.3612 22.7111 59.0667 22.7111C58.7721 22.7111 58.5333 22.9499 58.5333 23.2445C58.5333 23.539 58.7721 23.7778 59.0667 23.7778Z"
        fill={color}
      />
      <path
        d="M59.0666 24.2222C58.8 24.2222 58.5333 24.1333 58.3555 23.9111C58.1778 23.7333 58.0889 23.4667 58.0889 23.2C58.0889 22.9333 58.1778 22.7111 58.4 22.4889C58.8 22.0889 59.4222 22.1333 59.7778 22.4889C59.9555 22.6667 60.0444 22.9333 60.0444 23.2C60.0444 23.4667 59.9555 23.6889 59.7333 23.9111C59.5555 24.1333 59.2889 24.2222 59.0666 24.2222ZM59.0666 23.1556C59.0222 23.1556 59.0222 23.1556 59.0222 23.2C58.9778 23.2445 58.9778 23.2445 58.9778 23.2445C58.9778 23.2445 58.9778 23.2889 59.0222 23.2889C59.0666 23.3333 59.1111 23.3333 59.1555 23.2889C59.2 23.2445 59.2 23.2445 59.2 23.2445C59.2 23.2445 59.2 23.2 59.1555 23.2C59.1111 23.1556 59.0666 23.1556 59.0666 23.1556Z"
        fill={color}
      />
      <path
        d="M61.1111 25.8667C61.4056 25.8667 61.6444 25.6279 61.6444 25.3334C61.6444 25.0388 61.4056 24.8 61.1111 24.8C60.8165 24.8 60.5778 25.0388 60.5778 25.3334C60.5778 25.6279 60.8165 25.8667 61.1111 25.8667Z"
        fill={color}
      />
      <path
        d="M61.1111 26.3111C60.8444 26.3111 60.5777 26.2222 60.4 26C60.2222 25.8222 60.1333 25.5556 60.1333 25.2889C60.1333 25.0222 60.2222 24.8 60.4444 24.5778C60.8444 24.2222 61.4666 24.2222 61.8222 24.5778C62 24.7556 62.0889 25.0222 62.0889 25.2889C62.0889 25.5556 62 25.7778 61.7777 26C61.6 26.2222 61.3333 26.3111 61.1111 26.3111ZM61.1111 25.2445C61.0666 25.2445 61.0666 25.2445 61.0666 25.2889C61.0222 25.2889 61.0222 25.3333 61.0222 25.3333C61.0222 25.3333 61.0222 25.3778 61.0666 25.3778C61.1111 25.4222 61.1555 25.4222 61.2 25.3778C61.2444 25.3778 61.2444 25.3333 61.2444 25.3333C61.2444 25.3333 61.2444 25.2889 61.2 25.2889C61.1555 25.2445 61.1111 25.2445 61.1111 25.2445Z"
        fill={color}
      />
      <path
        d="M63.1555 28C63.4501 28 63.6889 27.7612 63.6889 27.4667C63.6889 27.1721 63.4501 26.9333 63.1555 26.9333C62.861 26.9333 62.6222 27.1721 62.6222 27.4667C62.6222 27.7612 62.861 28 63.1555 28Z"
        fill={color}
      />
      <path
        d="M63.1555 28.4445C62.8889 28.4445 62.6222 28.3556 62.4444 28.1333C62.2666 27.9556 62.1777 27.6889 62.1777 27.4222C62.1777 27.1556 62.2666 26.9333 62.4889 26.7111C62.8889 26.3556 63.5111 26.3556 63.8666 26.7111C64.0444 26.8889 64.1333 27.1556 64.1333 27.4222C64.1333 27.6889 64.0444 27.9111 63.8222 28.1333C63.6444 28.3556 63.4222 28.4445 63.1555 28.4445ZM63.1555 27.3778C63.1111 27.3778 63.1111 27.3778 63.1111 27.4222C63.0666 27.4667 63.0666 27.4667 63.0666 27.4667C63.0666 27.4667 63.0666 27.5111 63.1111 27.5111C63.1555 27.5556 63.2 27.5556 63.2444 27.5111C63.2888 27.4667 63.2888 27.4667 63.2888 27.4667C63.2888 27.4667 63.2888 27.4222 63.2444 27.4222C63.2 27.3778 63.2 27.3778 63.1555 27.3778Z"
        fill={color}
      />
      <path
        d="M57.4222 27.3778C57.7168 27.3778 57.9556 27.139 57.9556 26.8445C57.9556 26.5499 57.7168 26.3111 57.4222 26.3111C57.1277 26.3111 56.8889 26.5499 56.8889 26.8445C56.8889 27.139 57.1277 27.3778 57.4222 27.3778Z"
        fill={color}
      />
      <path
        d="M57.4222 27.8222C57.1556 27.8222 56.8889 27.7334 56.7111 27.5111C56.5333 27.3334 56.4445 27.0667 56.4445 26.8C56.4445 26.5334 56.5333 26.3111 56.7556 26.0889C57.1556 25.6889 57.7778 25.7334 58.1333 26.0889C58.3111 26.2667 58.4 26.5334 58.4 26.8C58.4 27.0667 58.3111 27.2889 58.0889 27.5111C57.9556 27.7334 57.6889 27.8222 57.4222 27.8222ZM57.4222 26.7556C57.3778 26.7556 57.3778 26.7556 57.3778 26.8C57.3333 26.8 57.3333 26.8445 57.3333 26.8445C57.3333 26.8445 57.3333 26.8889 57.3778 26.8889C57.4222 26.9334 57.4667 26.9334 57.5111 26.8889C57.5556 26.8445 57.5556 26.8445 57.5556 26.8445C57.5556 26.8445 57.5556 26.8 57.5111 26.8C57.5111 26.7556 57.4667 26.7556 57.4222 26.7556Z"
        fill={color}
      />
      <path
        d="M59.5111 29.5111C59.8057 29.5111 60.0445 29.2723 60.0445 28.9778C60.0445 28.6832 59.8057 28.4445 59.5111 28.4445C59.2166 28.4445 58.9778 28.6832 58.9778 28.9778C58.9778 29.2723 59.2166 29.5111 59.5111 29.5111Z"
        fill={color}
      />
      <path
        d="M59.5111 29.9556C59.2444 29.9556 58.9778 29.8667 58.8 29.6445C58.6222 29.4667 58.5333 29.2 58.5333 28.9333C58.5333 28.6667 58.6222 28.4445 58.8444 28.2222C59.2444 27.8667 59.8667 27.8667 60.2222 28.2222C60.4 28.4 60.4889 28.6667 60.4889 28.9333C60.4889 29.2 60.4 29.4222 60.1778 29.6445C60 29.8667 59.7333 29.9556 59.5111 29.9556ZM59.5111 28.8889C59.4667 28.8889 59.4667 28.8889 59.4667 28.9333C59.4222 28.9778 59.4222 28.9778 59.4222 28.9778C59.4222 28.9778 59.4222 29.0222 59.4667 29.0222C59.5111 29.0667 59.5556 29.0667 59.6 29.0222C59.6444 28.9778 59.6444 28.9778 59.6444 28.9778C59.6444 28.9778 59.6444 28.9333 59.6 28.9333C59.5556 28.8889 59.5111 28.8889 59.5111 28.8889Z"
        fill={color}
      />
      <path
        d="M53.7778 28.8889C54.0723 28.8889 54.3111 28.6501 54.3111 28.3556C54.3111 28.061 54.0723 27.8222 53.7778 27.8222C53.4832 27.8222 53.2444 28.061 53.2444 28.3556C53.2444 28.6501 53.4832 28.8889 53.7778 28.8889Z"
        fill={color}
      />
      <path
        d="M53.7778 29.3333C53.5111 29.3333 53.2444 29.2445 53.0667 29.0222C52.8889 28.8445 52.8 28.5778 52.8 28.3111C52.8 28.0445 52.8889 27.8222 53.1111 27.6C53.5111 27.2 54.1333 27.2445 54.4889 27.6C54.6667 27.7778 54.7555 28.0445 54.7555 28.3111C54.7555 28.5778 54.6667 28.8 54.4444 29.0222C54.2667 29.2445 54.0444 29.3333 53.7778 29.3333ZM53.7778 28.2667C53.7333 28.2667 53.7333 28.2667 53.7333 28.3111C53.6889 28.3111 53.6889 28.3556 53.6889 28.3556C53.6889 28.3556 53.6889 28.4 53.7333 28.4C53.7778 28.4445 53.8222 28.4445 53.8667 28.4C53.9111 28.4 53.9111 28.3556 53.9111 28.3556C53.9111 28.3556 53.9111 28.3111 53.8667 28.3111C53.8222 28.2667 53.8222 28.2667 53.7778 28.2667Z"
        fill={color}
      />
      <path
        d="M55.8222 31.0222C56.1168 31.0222 56.3555 30.7835 56.3555 30.4889C56.3555 30.1944 56.1168 29.9556 55.8222 29.9556C55.5277 29.9556 55.2889 30.1944 55.2889 30.4889C55.2889 30.7835 55.5277 31.0222 55.8222 31.0222Z"
        fill={color}
      />
      <path
        d="M55.8222 31.4667C55.5555 31.4667 55.2889 31.3778 55.1111 31.1556C54.9333 30.9778 54.8444 30.7111 54.8444 30.4445C54.8444 30.1778 54.9333 29.9556 55.1555 29.7334C55.5555 29.3778 56.1778 29.3778 56.5333 29.7334C56.7111 29.9111 56.8 30.1778 56.8 30.4445C56.8 30.7111 56.7111 30.9334 56.4889 31.1556C56.3111 31.3778 56.0889 31.4667 55.8222 31.4667ZM55.8222 30.3556C55.7778 30.3556 55.7778 30.3556 55.7778 30.4C55.7333 30.4 55.7333 30.4445 55.7333 30.4445C55.7333 30.4445 55.7333 30.4889 55.7778 30.4889C55.8222 30.5334 55.8666 30.5334 55.9111 30.4889C55.9555 30.4445 55.9555 30.4445 55.9555 30.4445C55.9555 30.4445 55.9555 30.4 55.9111 30.4C55.8666 30.4 55.8666 30.3556 55.8222 30.3556Z"
        fill={color}
      />
      <path
        d="M57.9111 33.1111C58.2057 33.1111 58.4445 32.8724 58.4445 32.5778C58.4445 32.2832 58.2057 32.0445 57.9111 32.0445C57.6166 32.0445 57.3778 32.2832 57.3778 32.5778C57.3778 32.8724 57.6166 33.1111 57.9111 33.1111Z"
        fill={color}
      />
      <path
        d="M57.9111 33.5556C57.6445 33.5556 57.3778 33.4667 57.2 33.2445C57.0222 33.0667 56.9333 32.8 56.9333 32.5333C56.9333 32.2667 57.0222 32.0445 57.2445 31.8222C57.6445 31.4667 58.2667 31.4667 58.6222 31.8222C58.8 32 58.8889 32.2667 58.8889 32.5333C58.8889 32.8 58.8 33.0222 58.5778 33.2445C58.4 33.4667 58.1333 33.5556 57.9111 33.5556ZM57.9111 32.4889C57.8667 32.4889 57.8667 32.4889 57.8667 32.5333C57.8222 32.5778 57.8222 32.5778 57.8222 32.5778C57.8222 32.5778 57.8222 32.6222 57.8667 32.6222C57.9111 32.6667 57.9556 32.6667 58 32.6222C58.0445 32.6222 58.0445 32.5778 58.0445 32.5778C58.0445 32.5778 58.0445 32.5333 58 32.5333C57.9556 32.4889 57.9111 32.4889 57.9111 32.4889Z"
        fill={color}
      />
      <path
        d="M36.0444 46.1333C36.339 46.1333 36.5778 45.8945 36.5778 45.6C36.5778 45.3054 36.339 45.0667 36.0444 45.0667C35.7499 45.0667 35.5111 45.3054 35.5111 45.6C35.5111 45.8945 35.7499 46.1333 36.0444 46.1333Z"
        fill={color}
      />
      <path
        d="M36.0444 46.5778C35.7778 46.5778 35.5111 46.4889 35.3333 46.2667C35.1555 46.0889 35.0667 45.8222 35.0667 45.5556C35.0667 45.2889 35.1555 45.0667 35.3778 44.8444C35.7778 44.4444 36.4 44.4889 36.7555 44.8444C36.9333 45.0222 37.0222 45.2889 37.0222 45.5556C37.0222 45.8222 36.9333 46.0444 36.7111 46.2667C36.5778 46.4889 36.3111 46.5778 36.0444 46.5778ZM36.0444 45.5111C36 45.5111 36 45.5111 36 45.5556C35.9555 45.6 35.9555 45.6 35.9555 45.6C35.9555 45.6 35.9555 45.6444 36 45.6444C36.0444 45.6889 36.0889 45.6889 36.1333 45.6444C36.1778 45.6444 36.1778 45.6 36.1778 45.6C36.1778 45.6 36.1778 45.5556 36.1333 45.5556C36.1333 45.5111 36.0889 45.5111 36.0444 45.5111Z"
        fill={color}
      />
      <path
        d="M38.1333 48.2222C38.4279 48.2222 38.6666 47.9834 38.6666 47.6889C38.6666 47.3943 38.4279 47.1555 38.1333 47.1555C37.8388 47.1555 37.6 47.3943 37.6 47.6889C37.6 47.9834 37.8388 48.2222 38.1333 48.2222Z"
        fill={color}
      />
      <path
        d="M38.1333 48.6667C37.8666 48.6667 37.6 48.5778 37.4222 48.3556C37.2444 48.1778 37.1555 47.9111 37.1555 47.6444C37.1555 47.3778 37.2444 47.1555 37.4666 46.9333C37.8666 46.5333 38.4889 46.5778 38.8444 46.9333C39.0222 47.1111 39.1111 47.3778 39.1111 47.6444C39.1111 47.9111 39.0222 48.1333 38.8 48.3556C38.6222 48.5778 38.3555 48.6667 38.1333 48.6667ZM38.1333 47.6C38.0889 47.6 38.0889 47.6 38.0889 47.6444C38.0444 47.6889 38.0444 47.6889 38.0444 47.6889C38.0444 47.6889 38.0444 47.7333 38.0889 47.7333C38.1333 47.7778 38.1777 47.7778 38.2222 47.7333C38.2666 47.7333 38.2666 47.6889 38.2666 47.6889C38.2666 47.6889 38.2666 47.6444 38.2222 47.6444C38.1777 47.6 38.1333 47.6 38.1333 47.6Z"
        fill={color}
      />
      <path
        d="M40.1777 50.3556C40.4723 50.3556 40.7111 50.1168 40.7111 49.8222C40.7111 49.5277 40.4723 49.2889 40.1777 49.2889C39.8832 49.2889 39.6444 49.5277 39.6444 49.8222C39.6444 50.1168 39.8832 50.3556 40.1777 50.3556Z"
        fill={color}
      />
      <path
        d="M40.1777 50.8C39.9111 50.8 39.6444 50.7111 39.4666 50.4889C39.2888 50.3111 39.2 50.0445 39.2 49.7778C39.2 49.5111 39.2888 49.2889 39.5111 49.0667C39.9111 48.6667 40.5333 48.7111 40.8888 49.0667C41.0666 49.2445 41.1555 49.5111 41.1555 49.7778C41.1555 50.0445 41.0666 50.2667 40.8444 50.4889C40.6666 50.7111 40.4444 50.8 40.1777 50.8ZM40.1777 49.7333C40.1333 49.7333 40.1333 49.7333 40.1333 49.7778C40.0888 49.7778 40.0888 49.8222 40.0888 49.8222C40.0888 49.8222 40.0888 49.8667 40.1333 49.8667C40.1777 49.9111 40.2222 49.9111 40.2666 49.8667C40.3111 49.8667 40.3111 49.8222 40.3111 49.8222C40.3111 49.8222 40.3111 49.7778 40.2666 49.7778C40.2222 49.7333 40.2222 49.7333 40.1777 49.7333Z"
        fill={color}
      />
      <path
        d="M34.4445 49.7333C34.739 49.7333 34.9778 49.4946 34.9778 49.2C34.9778 48.9055 34.739 48.6667 34.4445 48.6667C34.1499 48.6667 33.9111 48.9055 33.9111 49.2C33.9111 49.4946 34.1499 49.7333 34.4445 49.7333Z"
        fill={color}
      />
      <path
        d="M34.4445 50.1778C34.1778 50.1778 33.9111 50.0889 33.7333 49.8667C33.5556 49.6889 33.4667 49.4222 33.4667 49.1556C33.4667 48.8889 33.5556 48.6667 33.7778 48.4444C34.1778 48.0889 34.8 48.0889 35.1556 48.4444C35.3333 48.6222 35.4222 48.8889 35.4222 49.1556C35.4222 49.4222 35.3333 49.6444 35.1111 49.8667C34.9333 50.0889 34.7111 50.1778 34.4445 50.1778ZM34.4445 49.1111C34.4 49.1111 34.4 49.1111 34.4 49.1556C34.3556 49.2 34.3556 49.2 34.3556 49.2C34.3556 49.2 34.3556 49.2444 34.4 49.2444C34.4445 49.2889 34.4889 49.2889 34.5333 49.2444C34.5778 49.2444 34.5778 49.2 34.5778 49.2C34.5778 49.2 34.5778 49.1556 34.5333 49.1556C34.4889 49.1111 34.4889 49.1111 34.4445 49.1111Z"
        fill={color}
      />
      <path
        d="M36.5333 51.8667C36.8279 51.8667 37.0667 51.6279 37.0667 51.3333C37.0667 51.0388 36.8279 50.8 36.5333 50.8C36.2388 50.8 36 51.0388 36 51.3333C36 51.6279 36.2388 51.8667 36.5333 51.8667Z"
        fill={color}
      />
      <path
        d="M36.5333 52.3111C36.2667 52.3111 36 52.2222 35.8222 52C35.6444 51.8222 35.5555 51.5556 35.5555 51.2889C35.5555 51.0222 35.6444 50.8 35.8667 50.5778C36.2667 50.2222 36.8889 50.2222 37.2444 50.5778C37.4222 50.7556 37.5111 51.0222 37.5111 51.2889C37.5111 51.5556 37.4222 51.7778 37.2 52C37.0222 52.2222 36.7555 52.3111 36.5333 52.3111ZM36.5333 51.2C36.4889 51.2 36.4889 51.2 36.4889 51.2444C36.4444 51.2889 36.4444 51.3333 36.4889 51.3778C36.5333 51.4222 36.5778 51.4222 36.6222 51.3778C36.6667 51.3778 36.6667 51.3333 36.6667 51.3333C36.6667 51.3333 36.6667 51.2889 36.6222 51.2889C36.5778 51.2444 36.5333 51.2 36.5333 51.2Z"
        fill={color}
      />
      <path
        d="M30.8 51.2444C31.0945 51.2444 31.3333 51.0057 31.3333 50.7111C31.3333 50.4166 31.0945 50.1778 30.8 50.1778C30.5054 50.1778 30.2667 50.4166 30.2667 50.7111C30.2667 51.0057 30.5054 51.2444 30.8 51.2444Z"
        fill={color}
      />
      <path
        d="M30.8 51.6889C30.5333 51.6889 30.2666 51.6 30.0889 51.3778C29.9111 51.2 29.8222 50.9333 29.8222 50.6667C29.8222 50.4 29.9111 50.1778 30.1333 49.9556C30.5333 49.6 31.1555 49.6 31.5111 49.9556C31.6889 50.1333 31.7778 50.4 31.7778 50.6667C31.7778 50.9333 31.6889 51.1556 31.4667 51.3778C31.2889 51.6 31.0222 51.6889 30.8 51.6889ZM30.8 50.6222C30.7555 50.6222 30.7555 50.6222 30.7555 50.6667C30.7111 50.7111 30.7111 50.7556 30.7555 50.8C30.8 50.8444 30.8444 50.8444 30.8889 50.8C30.9333 50.8 30.9333 50.7556 30.9333 50.7556C30.9333 50.7556 30.9333 50.7111 30.8889 50.7111C30.8444 50.6222 30.8 50.6222 30.8 50.6222Z"
        fill={color}
      />
      <path
        d="M32.8444 53.3333C33.139 53.3333 33.3778 53.0946 33.3778 52.8C33.3778 52.5055 33.139 52.2667 32.8444 52.2667C32.5499 52.2667 32.3111 52.5055 32.3111 52.8C32.3111 53.0946 32.5499 53.3333 32.8444 53.3333Z"
        fill={color}
      />
      <path
        d="M32.8444 53.8222C32.5778 53.8222 32.3111 53.7333 32.1333 53.5111C31.9555 53.3333 31.8666 53.0667 31.8666 52.8C31.8666 52.5333 31.9555 52.3111 32.1778 52.0889C32.5778 51.6889 33.2 51.7333 33.5555 52.0889C33.7333 52.2667 33.8222 52.5333 33.8222 52.8C33.8222 53.0667 33.7333 53.2889 33.5111 53.5111C33.3333 53.6889 33.1111 53.8222 32.8444 53.8222ZM32.8444 52.7111C32.8 52.7111 32.8 52.7111 32.8 52.7556C32.7555 52.7556 32.7555 52.8 32.7555 52.8C32.7555 52.8 32.7555 52.8444 32.8 52.8444C32.8444 52.8889 32.8889 52.8889 32.9333 52.8444C32.9777 52.8 32.9777 52.7556 32.9333 52.7111C32.8889 52.7556 32.8889 52.7111 32.8444 52.7111Z"
        fill={color}
      />
      <path
        d="M34.9334 55.4667C35.2279 55.4667 35.4667 55.2279 35.4667 54.9333C35.4667 54.6388 35.2279 54.4 34.9334 54.4C34.6388 54.4 34.4 54.6388 34.4 54.9333C34.4 55.2279 34.6388 55.4667 34.9334 55.4667Z"
        fill={color}
      />
      <path
        d="M34.9333 55.9111C34.6667 55.9111 34.4 55.8222 34.2222 55.6C34.0445 55.4222 33.9556 55.1556 33.9556 54.8889C33.9556 54.6222 34.0445 54.4 34.2667 54.1778C34.6667 53.7778 35.2889 53.8222 35.6445 54.1778C35.8222 54.3556 35.9111 54.6222 35.9111 54.8889C35.9111 55.1556 35.8222 55.3778 35.6 55.6C35.4222 55.8222 35.1556 55.9111 34.9333 55.9111ZM34.9333 54.8444C34.8889 54.8444 34.8889 54.8444 34.8889 54.8889C34.8445 54.8889 34.8445 54.9333 34.8445 54.9333C34.8445 54.9333 34.8445 54.9778 34.8889 54.9778C34.9333 55.0222 34.9778 55.0222 35.0222 54.9778C35.0667 54.9778 35.0667 54.9333 35.0667 54.9333C35.0667 54.9333 35.0667 54.8889 35.0222 54.8889C34.9778 54.8444 34.9333 54.8444 34.9333 54.8444Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationBandageHeart.defaultProps = {
  id: 'illustration-bandaid-heart',
};

export default IllustrationBandageHeart;
