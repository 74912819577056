import IIconType from '../IIconType';

/**
 * @param {IIconType} context - The size and color
 * @returns {React.FC<IIconType>} IconOutlineArrowUpCircle Component
 */
const IconOutlineArrowUpCircle: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-arrow-up-circle"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4727 6.71484C12.3438 6.58594 12.1719 6.5 12 6.5C11.7852 6.5 11.6133 6.58594 11.4844 6.71484L7.35938 10.8398C7.10156 11.0977 7.10156 11.5703 7.35938 11.8281C7.61719 12.0859 8.08984 12.0859 8.34766 11.8281L11.3125 8.86328V16.8125C11.3125 17.1992 11.6133 17.5 12 17.5C12.3438 17.5 12.6875 17.1992 12.6875 16.8125V8.86328L15.6094 11.8281C15.8672 12.0859 16.3398 12.0859 16.5977 11.8281C16.8555 11.5703 16.8555 11.0977 16.5977 10.8398L12.4727 6.71484ZM12 1C5.89844 1 1 5.94141 1 12C1 18.1016 5.89844 23 12 23C18.0586 23 23 18.1016 23 12C23 5.94141 18.0586 1 12 1ZM12 21.625C6.67188 21.625 2.375 17.3281 2.375 12C2.375 6.71484 6.67188 2.375 12 2.375C17.2852 2.375 21.625 6.71484 21.625 12C21.625 17.3281 17.2852 21.625 12 21.625Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineArrowUpCircle;
