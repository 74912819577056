import IIconType from '../IIconType';

/**
 * @param {IIconType} props { size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineMegaPhone: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-mega-phone"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 2.00003C19.2266 2.00003 19.5 2.93753 19.5 3.25003V19.5C19.5 19.8907 19.1875 20.75 18.25 20.75C17.9766 20.75 17.625 20.6328 17.4688 20.4766L14.1094 17.8203C12.4688 16.4922 10.3594 15.75 8.25 15.75H4.5C3.09375 15.75 2 14.6563 2 13.25V9.50003C2 8.13284 3.09375 7.00003 4.5 7.00003H8.25C10.3594 7.00003 12.4688 6.29691 14.1484 4.96878L17.4688 2.31253C17.6641 2.11722 17.9375 2.00003 18.25 2.00003ZM8.25 14.5V8.25003H4.5C3.79688 8.25003 3.25 8.83597 3.25 9.50003V13.25C3.25 13.9532 3.79688 14.5 4.5 14.5H8.25ZM18.2109 19.5C18.2109 19.5 18.25 19.5 18.2109 19.461V3.28909L14.8906 5.94534C13.3281 7.19534 11.4531 7.93753 9.5 8.17191V14.6172C11.4531 14.8516 13.3281 15.5938 14.8906 16.8438L18.2109 19.5ZM9.46094 17C9.5 18.0157 10.2812 18.9922 10.2812 18.9922C10.7109 19.5 10.8672 20.2422 10.6328 20.8672C10.3594 21.5703 9.77344 22 9.10938 22H7.07812C6.57031 22 6.10156 21.7657 5.78906 21.336C5.67188 21.1407 4.42188 19.3047 4.5 17C4.5 16.6485 4.77344 16.375 5.125 16.375C5.47656 16.4141 5.75 16.6875 5.75 17.0391C5.67188 18.9922 6.76562 20.5547 6.84375 20.5938C6.88281 20.711 7 20.75 7.07812 20.75H9.10938C9.22656 20.75 9.38281 20.6328 9.42188 20.4766C9.5 20.2422 9.46094 19.9688 9.34375 19.8125C9.34375 19.8125 8.25 18.5235 8.21094 17.0391C8.21094 16.6875 8.48438 16.4141 8.83594 16.375C9.1875 16.4141 9.46094 16.6485 9.46094 17ZM21.375 9.50003C21.6875 9.50003 22 9.81253 22 10.125V12.625C22 12.9766 21.6875 13.25 21.375 13.25C21.0234 13.25 20.75 12.9766 20.75 12.625V10.125C20.75 9.81253 21.0234 9.50003 21.375 9.50003Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineMegaPhone;
