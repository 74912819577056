import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPetStore: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-pet-store"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.1111 77.7778H4.88888C4.13332 77.7778 3.55554 77.2 3.55554 76.4444V36.4444C3.55554 35.6889 4.13332 35.1111 4.88888 35.1111C5.64443 35.1111 6.22221 35.6889 6.22221 36.4444V75.1111H73.7778V36.4889C73.7778 35.7333 74.3555 35.1556 75.1111 35.1556C75.8666 35.1556 76.4444 35.7333 76.4444 36.4889V76.4444C76.4444 77.2 75.8666 77.7778 75.1111 77.7778Z"
        fill={color}
      />
      <path
        d="M14.4444 77.3778C13.6889 77.3778 13.1111 76.8 13.1111 76.0445V42.2222C13.1111 41.4667 13.6889 40.8889 14.4444 40.8889H34.8889C35.6444 40.8889 36.2222 41.4667 36.2222 42.2222V75.8667C36.2222 76.6222 35.6444 77.2 34.8889 77.2C34.1333 77.2 33.5555 76.6222 33.5555 75.8667V43.5556H15.7778V76.0445C15.7778 76.8 15.2 77.3778 14.4444 77.3778Z"
        fill={color}
      />
      <path
        d="M30.8889 59.1111C31.6253 59.1111 32.2222 58.5142 32.2222 57.7778C32.2222 57.0414 31.6253 56.4445 30.8889 56.4445C30.1525 56.4445 29.5555 57.0414 29.5555 57.7778C29.5555 58.5142 30.1525 59.1111 30.8889 59.1111Z"
        fill={color}
      />
      <path
        d="M60.1333 71.8222C59.8222 71.8222 59.5111 71.7778 59.2 71.7333C58 71.5111 57.0667 70.8445 56.4 70.2222C55.7778 70.8 54.8444 71.4667 53.6 71.7333C52.2222 72 50.8444 71.6889 49.4667 70.7556C48.4444 70.0889 47.8222 69.1111 47.6 67.9556C47.1556 65.5556 48.6222 63.0222 49.2889 62.0889C51.0667 59.5111 54.4 56.6222 56.3556 56.6222C58.4444 56.6222 61.6 59.3778 63.4222 62.0889C64.0889 63.0667 65.6 65.5556 65.1111 67.9556C64.8889 69.1111 64.2667 70.0889 63.2444 70.7556C62.2667 71.4222 61.2 71.8222 60.1333 71.8222ZM56.4 58.8C55.7333 58.8445 53.0222 60.5778 51.1556 63.2889C50.1333 64.8 49.6 66.4 49.8222 67.5111C49.9111 68.0889 50.2222 68.5333 50.7556 68.8889C51.6 69.4667 52.4444 69.6889 53.2 69.5111C54.5778 69.2444 55.4667 67.9111 55.4667 67.9111C55.6889 67.6 56 67.4222 56.4 67.4222C56.8 67.4222 57.1111 67.6 57.3333 67.9111C57.3333 67.9111 57.8667 68.7111 58.7556 69.2C59.8222 69.7778 60.8889 69.6889 62.0889 68.8889C62.6222 68.5333 62.8889 68.0889 63.0222 67.5111C63.2444 66.4 62.7111 64.8 61.6889 63.2889C59.7333 60.5333 57.1556 58.8 56.4 58.8Z"
        fill={color}
      />
      <path
        d="M65.1556 61.3333C64.7111 61.3333 64.2667 61.2445 63.8222 61.0222C62.8889 60.4889 62.4889 59.7778 62.3556 59.2889C62.0889 58.3556 62.3111 57.2889 63.0222 56.0889C63.6 55.0667 64.5333 54.3111 65.5555 54.0444C66.3111 53.8222 67.0667 53.9556 67.6889 54.3111C69.0222 55.0667 69.9111 56.8 68.4889 59.2445C67.6889 60.5333 66.4 61.3333 65.1556 61.3333ZM66.3556 56.0889C66.2667 56.0889 66.1778 56.0889 66.1333 56.1333C65.6889 56.2667 65.2444 56.6222 64.9778 57.1556C64.4444 58.0445 64.4889 58.4889 64.5333 58.6667C64.5333 58.7111 64.5778 58.8889 64.9333 59.0667C65.3333 59.2889 66.1333 58.9333 66.6222 58.0889C67.4667 56.6667 66.8444 56.3111 66.6222 56.1778C66.4889 56.1333 66.4 56.0889 66.3556 56.0889Z"
        fill={color}
      />
      <path
        d="M47.6889 61.3333C46.4445 61.3333 45.1556 60.5333 44.3556 59.2C42.9334 56.7111 43.8222 55.0222 45.1556 54.2667C45.7778 53.9111 46.5334 53.8222 47.2889 54C48.3111 54.2667 49.2 55.0222 49.8222 56.0445C50.5333 57.2445 50.7556 58.3111 50.4889 59.2445C50.3556 59.7778 49.9556 60.4889 49.0222 60.9778C48.5778 61.2445 48.1334 61.3333 47.6889 61.3333ZM46.4889 56.0889C46.4 56.0889 46.3111 56.0889 46.2667 56.1333C46.0445 56.2667 45.4667 56.6222 46.2667 58.0445C46.7556 58.8889 47.5556 59.2445 47.9556 59.0222C48.2667 58.8445 48.3111 58.6667 48.3556 58.6222C48.4 58.4889 48.4445 58.0445 47.9111 57.1111C47.6 56.6222 47.1556 56.2222 46.7556 56.0889C46.6667 56.1333 46.5778 56.0889 46.4889 56.0889Z"
        fill={color}
      />
      <path
        d="M60 55.1556C59.7333 55.1556 59.4667 55.1111 59.2 55.0667C58.2667 54.8445 57.5111 54.3111 57.0667 53.5556C56.4889 52.5778 56.4445 51.2445 56.9333 49.7333C57.6445 47.5111 59.6444 46.1333 61.6444 46.5333C62.5333 46.7111 63.2 47.2 63.6444 48C64.2667 49.0222 64.3556 50.4445 63.9111 51.9111C63.2889 53.8667 61.6889 55.1556 60 55.1556ZM60.9333 48.6667C60.1778 48.6667 59.3778 49.3333 59.0667 50.3556C58.8 51.2445 58.7556 52.0445 58.9778 52.4445C59.0667 52.5778 59.2 52.8 59.6889 52.8889C60.4889 53.0667 61.4222 52.3556 61.7778 51.2C62.0445 50.3556 62 49.5556 61.7333 49.0667C61.6 48.8445 61.4222 48.7111 61.1556 48.6667C61.1111 48.7111 61.0222 48.6667 60.9333 48.6667Z"
        fill={color}
      />
      <path
        d="M52.3556 55.6C50.6223 55.6 49.0223 54.3111 48.4 52.3111C47.9556 50.8444 48.0445 49.4667 48.6667 48.4C49.1111 47.6444 49.8223 47.1556 50.6667 46.9333C52.6667 46.5333 54.6667 47.9111 55.3778 50.1333C55.8667 51.6889 55.8223 53.0222 55.2445 53.9556C54.8 54.7111 54.0889 55.2444 53.1111 55.4667C52.8445 55.5556 52.5778 55.6 52.3556 55.6ZM51.3778 49.1111C51.2889 49.1111 51.2 49.1111 51.1111 49.1556C50.8445 49.2 50.6667 49.3333 50.5334 49.5556C50.2667 50.0444 50.2223 50.8444 50.4889 51.6889C50.8445 52.8444 51.7778 53.5556 52.5778 53.3778C53.0223 53.2889 53.2 53.0667 53.2889 52.9333C53.5111 52.5333 53.5111 51.7778 53.2 50.8444C52.9334 49.7778 52.1778 49.1111 51.3778 49.1111Z"
        fill={color}
      />
      <path
        d="M74.4444 16H5.1111C4.35555 16 3.77777 15.4222 3.77777 14.6667V3.55556C3.77777 2.80001 4.35555 2.22223 5.1111 2.22223H74.4444C75.2 2.22223 75.7778 2.80001 75.7778 3.55556V14.6667C75.7778 15.4222 75.1555 16 74.4444 16ZM6.44444 13.3333H73.1111V4.8889H6.44444V13.3333Z"
        fill={color}
      />
      <path
        d="M40.2222 39.1111C35.3778 39.1111 31.4222 34.9778 31.4222 29.8667C31.4222 29.8222 31.4222 29.8222 31.4222 29.7778L32.3111 14.5778C32.3556 13.8667 32.9334 13.3333 33.6445 13.3333H46.8C47.5111 13.3333 48.0889 13.8667 48.1334 14.5778L49.0222 29.7778C49.0222 29.8222 49.0222 29.8222 49.0222 29.8667C49.0222 34.9778 45.0667 39.1111 40.2222 39.1111ZM34.0889 29.9111C34.0889 33.5111 36.8445 36.4445 40.2222 36.4445C43.6 36.4445 46.3556 33.5111 46.3556 29.9111L45.5111 16H34.8889L34.0889 29.9111Z"
        fill={color}
      />
      <path
        d="M55.1555 39.1111C50.3111 39.1111 46.3555 35.1556 46.3555 30.3111C46.3555 29.5556 46.9333 28.9778 47.6889 28.9778C48.4444 28.9778 49.0222 29.5556 49.0222 30.3111C49.0222 33.6889 51.7778 36.4445 55.1555 36.4445C58.4889 36.4445 61.2444 33.7333 61.2889 30.4L59.5111 16.1778C59.4222 15.4667 59.9555 14.8 60.6666 14.7111C61.4222 14.6222 62.0444 15.1556 62.1333 15.8667L63.9111 30.1778C63.9111 30.2222 63.9111 30.2667 63.9111 30.3556C63.9555 35.1556 60 39.1111 55.1555 39.1111Z"
        fill={color}
      />
      <path
        d="M70.0889 39.1111C65.2444 39.1111 61.2889 35.1556 61.2889 30.3111C61.2889 29.5556 61.8667 28.9778 62.6222 28.9778C63.3778 28.9778 63.9555 29.5556 63.9555 30.3111C63.9555 33.6889 66.7111 36.4445 70.0889 36.4445C71.3333 36.4445 72.4889 36.0889 73.5111 35.3778C75.4667 34.0445 76.4444 31.5111 75.9555 29.0667L73.1556 15.3333C73.0222 14.6222 73.4667 13.9111 74.1778 13.7778C74.8889 13.6445 75.6 14.0889 75.7333 14.8L78.5333 28.5333C79.2444 32.0889 77.8667 35.6445 74.9778 37.6C73.5555 38.5778 71.8222 39.1111 70.0889 39.1111Z"
        fill={color}
      />
      <path
        d="M10.3555 39.1111C7.28888 39.1111 4.53333 37.5111 2.79999 34.8C1.46666 32.6222 0.977772 30 1.46666 27.3333L3.77777 14.8889C3.91111 14.1778 4.62222 13.6889 5.33333 13.8222C6.04444 13.9556 6.53333 14.6667 6.39999 15.3778L4.08888 27.8222C3.73333 29.8222 4.04444 31.7778 5.06666 33.3778C6.26666 35.3333 8.22222 36.4444 10.3555 36.4444C13.7333 36.4444 16.4889 33.6889 16.4889 30.3111C16.4889 29.5556 17.0667 28.9778 17.8222 28.9778C18.5778 28.9778 19.1556 29.5556 19.1556 30.3111C19.1556 35.1556 15.2 39.1111 10.3555 39.1111Z"
        fill={color}
      />
      <path
        d="M25.2889 39.1111C20.4444 39.1111 16.4889 35.1556 16.4889 30.3111C16.4889 30.2667 16.4889 30.2222 16.4889 30.1778L17.8222 15.8667C17.9111 15.1556 18.5333 14.5778 19.2889 14.6667C20 14.7556 20.5778 15.3778 20.4889 16.1333L19.1556 30.4C19.1556 33.7333 21.9111 36.4445 25.2889 36.4445C28.6667 36.4445 31.4222 33.6889 31.4222 30.3111C31.4222 29.5556 32 28.9778 32.7556 28.9778C33.5111 28.9778 34.0889 29.5556 34.0889 30.3111C34.0889 35.1556 30.1333 39.1111 25.2889 39.1111Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationPetStore.defaultProps = {
  id: 'illustration-pet-store',
};

export default IllustrationPetStore;
