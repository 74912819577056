import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationCalendar: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-calendar"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.1333 78.2222H11.8667C8.13334 78.2222 5.11111 75.2 5.11111 71.4667V15.2C5.11111 11.4667 8.13334 8.44444 11.8667 8.44444H68.1333C71.8667 8.44444 74.8889 11.4667 74.8889 15.2V71.4667C74.8889 75.2 71.8667 78.2222 68.1333 78.2222ZM11.8667 11.1111C9.6 11.1111 7.77778 12.9333 7.77778 15.2V71.4667C7.77778 73.7333 9.6 75.5556 11.8667 75.5556H68.1333C70.4 75.5556 72.2222 73.7333 72.2222 71.4667V15.2C72.2222 12.9333 70.4 11.1111 68.1333 11.1111H11.8667Z"
        fill={color}
      />
      <path d="M28.2222 3.1111H27.7778V8.44443H28.2222V3.1111Z" fill={color} />
      <path
        d="M28.2222 9.77777H27.7778C27.0222 9.77777 26.4445 9.19999 26.4445 8.44444V3.1111C26.4445 2.35555 27.0222 1.77777 27.7778 1.77777H28.2222C28.9778 1.77777 29.5556 2.35555 29.5556 3.1111V8.44444C29.5556 9.19999 28.9778 9.77777 28.2222 9.77777Z"
        fill={color}
      />
      <path d="M52.2222 3.1111H51.7778V8.44443H52.2222V3.1111Z" fill={color} />
      <path
        d="M52.2222 9.77777H51.7778C51.0222 9.77777 50.4444 9.19999 50.4444 8.44444V3.1111C50.4444 2.35555 51.0222 1.77777 51.7778 1.77777H52.2222C52.9778 1.77777 53.5556 2.35555 53.5556 3.1111V8.44444C53.5556 9.19999 52.9778 9.77777 52.2222 9.77777Z"
        fill={color}
      />
      <path
        d="M73.5556 24H6.44445C5.68889 24 5.11111 23.4222 5.11111 22.6667C5.11111 21.9111 5.68889 21.3333 6.44445 21.3333H73.5556C74.3111 21.3333 74.8889 21.9111 74.8889 22.6667C74.8889 23.4222 74.3111 24 73.5556 24Z"
        fill={color}
      />
      <path
        d="M20.6667 39.5555H10.8889C10.1333 39.5555 9.55556 38.9778 9.55556 38.2222V28.4444C9.55556 27.6889 10.1333 27.1111 10.8889 27.1111H20.6667C21.4222 27.1111 22 27.6889 22 28.4444V38.2222C22 38.9778 21.4222 39.5555 20.6667 39.5555ZM12.2222 36.8889H19.3333V29.7778H12.2222V36.8889Z"
        fill={color}
      />
      <path
        d="M36.8 39.5555H27.0222C26.2667 39.5555 25.6889 38.9778 25.6889 38.2222V28.4444C25.6889 27.6889 26.2667 27.1111 27.0222 27.1111H36.8C37.5556 27.1111 38.1333 27.6889 38.1333 28.4444V38.2222C38.1333 38.9778 37.5556 39.5555 36.8 39.5555ZM28.3556 36.8889H35.4667V29.7778H28.3556V36.8889Z"
        fill={color}
      />
      <path
        d="M52.9778 39.5555H43.2C42.4444 39.5555 41.8667 38.9778 41.8667 38.2222V28.4444C41.8667 27.6889 42.4444 27.1111 43.2 27.1111H52.9778C53.7333 27.1111 54.3111 27.6889 54.3111 28.4444V38.2222C54.3111 38.9778 53.6889 39.5555 52.9778 39.5555ZM44.5333 36.8889H51.6444V29.7778H44.5333V36.8889Z"
        fill={color}
      />
      <path
        d="M69.1111 39.5555H59.3333C58.5778 39.5555 58 38.9778 58 38.2222V28.4444C58 27.6889 58.5778 27.1111 59.3333 27.1111H69.1111C69.8667 27.1111 70.4444 27.6889 70.4444 28.4444V38.2222C70.4444 38.9778 69.8667 39.5555 69.1111 39.5555ZM60.6667 36.8889H67.7778V29.7778H60.6667V36.8889Z"
        fill={color}
      />
      <path
        d="M69.1111 55.1111H59.3333C58.5778 55.1111 58 54.5333 58 53.7778V44C58 43.2444 58.5778 42.6667 59.3333 42.6667H69.1111C69.8667 42.6667 70.4444 43.2444 70.4444 44V53.7778C70.4444 54.5333 69.8667 55.1111 69.1111 55.1111ZM60.6667 52.4444H67.7778V45.3333H60.6667V52.4444Z"
        fill={color}
      />
      <path
        d="M20.6667 55.5555H10.8889C10.1333 55.5555 9.55556 54.9778 9.55556 54.2222V44.4444C9.55556 43.6889 10.1333 43.1111 10.8889 43.1111H20.6667C21.4222 43.1111 22 43.6889 22 44.4444V54.2222C22 54.9778 21.4222 55.5555 20.6667 55.5555ZM12.2222 52.8889H19.3333V45.7778H12.2222V52.8889Z"
        fill={color}
      />
      <path
        d="M36.8 55.5555H27.0222C26.2667 55.5555 25.6889 54.9778 25.6889 54.2222V44.4444C25.6889 43.6889 26.2667 43.1111 27.0222 43.1111H36.8C37.5556 43.1111 38.1333 43.6889 38.1333 44.4444V54.2222C38.1333 54.9778 37.5556 55.5555 36.8 55.5555ZM28.3556 52.8889H35.4667V45.7778H28.3556V52.8889Z"
        fill={color}
      />
      <path
        d="M20.6667 71.5555H10.8889C10.1333 71.5555 9.55556 70.9778 9.55556 70.2222V60.4444C9.55556 59.6889 10.1333 59.1111 10.8889 59.1111H20.6667C21.4222 59.1111 22 59.6889 22 60.4444V70.2222C22 70.9778 21.4222 71.5555 20.6667 71.5555ZM12.2222 68.8889H19.3333V61.7778H12.2222V68.8889Z"
        fill={color}
      />
      <path
        d="M36.8 71.5555H27.0222C26.2667 71.5555 25.6889 70.9778 25.6889 70.2222V60.4444C25.6889 59.6889 26.2667 59.1111 27.0222 59.1111H36.8C37.5556 59.1111 38.1333 59.6889 38.1333 60.4444V70.2222C38.1333 70.9778 37.5556 71.5555 36.8 71.5555ZM28.3556 68.8889H35.4667V61.7778H28.3556V68.8889Z"
        fill={color}
      />
      <path
        d="M52.9778 71.5555H43.2C42.4444 71.5555 41.8667 70.9778 41.8667 70.2222V60.4444C41.8667 59.6889 42.4444 59.1111 43.2 59.1111H52.9778C53.7333 59.1111 54.3111 59.6889 54.3111 60.4444V70.2222C54.3111 70.9778 53.6889 71.5555 52.9778 71.5555ZM44.5333 68.8889H51.6444V61.7778H44.5333V68.8889Z"
        fill={color}
      />
      <path
        d="M69.1111 71.5555H59.3333C58.5778 71.5555 58 70.9778 58 70.2222V60.4444C58 59.6889 58.5778 59.1111 59.3333 59.1111H69.1111C69.8667 59.1111 70.4444 59.6889 70.4444 60.4444V70.2222C70.4444 70.9778 69.8667 71.5555 69.1111 71.5555ZM60.6667 68.8889H67.7778V61.7778H60.6667V68.8889Z"
        fill={color}
      />
      <path
        d="M48.0889 55.6444C46.7111 55.6444 44.0889 53.4222 44.0889 53.4222C43.1111 52.5778 41.0667 50.8444 41.0667 47.8667C41.0667 46.5333 41.5111 45.2889 42.4 44.4C43.2 43.5556 44.3111 43.0667 45.3778 43.0667C46.4889 43.0667 47.4222 43.5556 48.1333 44.1333C48.8 43.6 49.6889 43.0667 50.8444 43.0667C52.9778 43.0667 55.1556 44.8444 55.1556 47.8667C55.1556 50.8 53.1111 52.5778 52.1333 53.4222C52.0889 53.4222 49.4667 55.6444 48.0889 55.6444ZM45.3333 45.7333C44.9778 45.7333 44.5778 45.9111 44.2667 46.2222C44 46.4889 43.6889 47.0222 43.6889 47.8667C43.6889 49.4667 44.6667 50.4444 45.7778 51.3778C46.6222 52.0889 47.6 52.7111 48.0889 52.9333C48.5333 52.7111 49.4667 52.1333 50.3556 51.3778C51.4667 50.4444 52.4444 49.4667 52.4444 47.8667C52.4444 46.4 51.4667 45.7333 50.8 45.7333C49.9111 45.7333 49.2 46.8444 49.2 46.8444C48.9778 47.2444 48.5333 47.5111 48.0444 47.5111C47.5556 47.5111 47.1556 47.2444 46.8889 46.8444C46.9333 46.8 46.2667 45.7333 45.3333 45.7333Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationCalendar.defaultProps = {
  id: 'illustration-calendar',
};

export default IllustrationCalendar;
