import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationMicrochip: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-microchip"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62 68.5334H18C14.4 68.5334 11.4222 65.6 11.4222 61.9556V18C11.4222 14.4 14.3556 11.4222 18 11.4222H61.9556C65.5556 11.4222 68.5334 14.3556 68.5334 18V61.9556C68.5334 65.6 65.6 68.5334 62 68.5334ZM18 14.1334C15.8667 14.1334 14.0889 15.8667 14.0889 18.0445V62C14.0889 64.1334 15.8222 65.9111 18 65.9111H61.9556C64.0889 65.9111 65.8667 64.1778 65.8667 62V18C65.8667 15.8667 64.1334 14.0889 61.9556 14.0889H18V14.1334Z"
        fill={color}
      />
      <path
        d="M55.2889 61.8223H24.7111C21.1111 61.8223 18.1334 58.8889 18.1334 55.2445V24.7111C18.1334 21.1111 21.0667 18.1334 24.7111 18.1334H55.2889C58.8889 18.1334 61.8667 21.0667 61.8667 24.7111V55.2889C61.8222 58.8889 58.8889 61.8223 55.2889 61.8223ZM24.7111 20.8445C22.5778 20.8445 20.8 22.5778 20.8 24.7556V55.3334C20.8 57.4667 22.5334 59.2445 24.7111 59.2445H55.2889C57.4223 59.2445 59.2 57.5111 59.2 55.3334V24.7111C59.2 22.5778 57.4667 20.8 55.2889 20.8H24.7111V20.8445Z"
        fill={color}
      />
      <path
        d="M19.3778 12.8889C18.6222 12.8889 18.0444 12.3111 18.0444 11.5556V5.77779C18.0444 5.02224 18.6222 4.44446 19.3778 4.44446C20.1333 4.44446 20.7111 5.02224 20.7111 5.77779V11.5556C20.7111 12.2667 20.1333 12.8889 19.3778 12.8889Z"
        fill={color}
      />
      <path
        d="M40 12.8889C39.2445 12.8889 38.6667 12.3111 38.6667 11.5556V5.77779C38.6667 5.02224 39.2445 4.44446 40 4.44446C40.7556 4.44446 41.3334 5.02224 41.3334 5.77779V11.5556C41.3334 12.2667 40.7556 12.8889 40 12.8889Z"
        fill={color}
      />
      <path
        d="M60.6222 12.8889C59.8667 12.8889 59.2889 12.3111 59.2889 11.5556V5.77779C59.2889 5.02224 59.8667 4.44446 60.6222 4.44446C61.3778 4.44446 61.9555 5.02224 61.9555 5.77779V11.5556C61.9555 12.2667 61.3333 12.8889 60.6222 12.8889Z"
        fill={color}
      />
      <path
        d="M19.3778 75.5556C18.6222 75.5556 18.0444 74.9778 18.0444 74.2223V68.4445C18.0444 67.6889 18.6222 67.1111 19.3778 67.1111C20.1333 67.1111 20.7111 67.6889 20.7111 68.4445V74.2223C20.7111 74.9778 20.1333 75.5556 19.3778 75.5556Z"
        fill={color}
      />
      <path
        d="M40 75.5556C39.2445 75.5556 38.6667 74.9778 38.6667 74.2223V68.4445C38.6667 67.6889 39.2445 67.1111 40 67.1111C40.7556 67.1111 41.3334 67.6889 41.3334 68.4445V74.2223C41.3334 74.9778 40.7556 75.5556 40 75.5556Z"
        fill={color}
      />
      <path
        d="M60.6222 75.5556C59.8667 75.5556 59.2889 74.9778 59.2889 74.2223V68.4445C59.2889 67.6889 59.8667 67.1111 60.6222 67.1111C61.3778 67.1111 61.9555 67.6889 61.9555 68.4445V74.2223C61.9555 74.9778 61.3333 75.5556 60.6222 75.5556Z"
        fill={color}
      />
      <path
        d="M11.5556 20.7111H5.77779C5.02224 20.7111 4.44446 20.1334 4.44446 19.3778C4.44446 18.6222 5.02224 18.0445 5.77779 18.0445H11.5556C12.3111 18.0445 12.8889 18.6222 12.8889 19.3778C12.8889 20.1334 12.2667 20.7111 11.5556 20.7111Z"
        fill={color}
      />
      <path
        d="M11.5556 41.3334H5.77779C5.02224 41.3334 4.44446 40.7556 4.44446 40C4.44446 39.2445 5.02224 38.6667 5.77779 38.6667H11.5556C12.3111 38.6667 12.8889 39.2445 12.8889 40C12.8889 40.7556 12.2667 41.3334 11.5556 41.3334Z"
        fill={color}
      />
      <path
        d="M11.5556 61.9556H5.77779C5.02224 61.9556 4.44446 61.3778 4.44446 60.6222C4.44446 59.8667 5.02224 59.2889 5.77779 59.2889H11.5556C12.3111 59.2889 12.8889 59.8667 12.8889 60.6222C12.8889 61.3778 12.2667 61.9556 11.5556 61.9556Z"
        fill={color}
      />
      <path
        d="M74.2223 20.7111H68.4445C67.6889 20.7111 67.1111 20.1334 67.1111 19.3778C67.1111 18.6222 67.6889 18.0445 68.4445 18.0445H74.2223C74.9778 18.0445 75.5556 18.6222 75.5556 19.3778C75.5556 20.1334 74.9778 20.7111 74.2223 20.7111Z"
        fill={color}
      />
      <path
        d="M74.2223 41.3334H68.4445C67.6889 41.3334 67.1111 40.7556 67.1111 40C67.1111 39.2445 67.6889 38.6667 68.4445 38.6667H74.2223C74.9778 38.6667 75.5556 39.2445 75.5556 40C75.5556 40.7556 74.9778 41.3334 74.2223 41.3334Z"
        fill={color}
      />
      <path
        d="M74.2223 61.9556H68.4445C67.6889 61.9556 67.1111 61.3778 67.1111 60.6222C67.1111 59.8667 67.6889 59.2889 68.4445 59.2889H74.2223C74.9778 59.2889 75.5556 59.8667 75.5556 60.6222C75.5556 61.3778 74.9778 61.9556 74.2223 61.9556Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationMicrochip.defaultProps = {
  id: 'illustration-microchip',
};

export default IllustrationMicrochip;
