import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineBowtie: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-bowtie"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.44723 7.01559C6.30464 7.46421 9.61621 8.67625 10.8869 9.27282C11.1318 9.09045 11.435 8.9826 11.7632 8.9826H13.9737C14.1682 8.9826 14.3538 9.02046 14.5238 9.08927C16.0028 8.45497 18.9642 7.42201 21.5528 7.01559C22.3386 6.8922 23 7.51877 23 8.27911V15.7261C23 16.5203 22.2779 17.1621 21.4626 16.9636C19.8202 16.5638 17.1265 15.6636 14.727 14.1102C14.5066 14.2422 14.249 14.3181 13.9737 14.3181H11.7632C11.334 14.3181 10.9477 14.1337 10.6784 13.8394C8.18259 15.5598 5.27304 16.5411 3.53735 16.9636C2.72213 17.1621 2 16.5203 2 15.7261V8.27911C2 7.51877 2.66137 6.8922 3.44723 7.01559ZM3.10526 8.27911C3.10526 8.16122 3.19947 8.10135 3.2767 8.11348C5.93043 8.53012 9.03335 9.64907 10.308 10.2283C10.2958 10.3051 10.2895 10.3839 10.2895 10.4642V12.7567C7.89634 14.4757 4.99474 15.4656 3.27725 15.8836C3.2331 15.8944 3.19566 15.8842 3.16404 15.8581C3.13064 15.8305 3.10526 15.7853 3.10526 15.7261V8.27911ZM15.397 13.222C17.6383 14.6566 20.1666 15.5048 21.7227 15.8836C21.7669 15.8944 21.8043 15.8842 21.836 15.8581C21.8694 15.8305 21.8947 15.7853 21.8947 15.7261V8.27911C21.8947 8.16122 21.8005 8.10135 21.7233 8.11348C19.4543 8.46971 16.857 9.33936 15.3548 9.9461C15.4146 10.1074 15.4474 10.2819 15.4474 10.4642V12.8365C15.4474 12.9699 15.4298 13.0991 15.397 13.222ZM13.9737 10.0938H11.7632C11.5597 10.0938 11.3947 10.2596 11.3947 10.4642V12.8365C11.3947 13.0411 11.5597 13.2069 11.7632 13.2069H13.9737C14.1772 13.2069 14.3421 13.0411 14.3421 12.8365V10.4642C14.3421 10.2596 14.1772 10.0938 13.9737 10.0938Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineBowtie;
