import IIconType from '../IIconType';

/**
 * @param {IIconType} props - The props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineList: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-list"
    >
      <path
        d="M3.72581 6.46154H4.95161V5.23077H3.72581V6.46154ZM2.5 4.92308C2.5 4.42308 2.88306 4 3.41935 4H5.25806C5.75605 4 6.17742 4.42308 6.17742 4.92308V6.76923C6.17742 7.30769 5.75605 7.69231 5.25806 7.69231H3.41935C2.88306 7.69231 2.5 7.30769 2.5 6.76923V4.92308ZM8.62903 5.23077H20.8871C21.1935 5.23077 21.5 5.53846 21.5 5.84615C21.5 6.19231 21.1935 6.46154 20.8871 6.46154H8.62903C8.28427 6.46154 8.01613 6.19231 8.01613 5.84615C8.01613 5.53846 8.28427 5.23077 8.62903 5.23077ZM8.62903 11.3846H20.8871C21.1935 11.3846 21.5 11.6923 21.5 12C21.5 12.3462 21.1935 12.6154 20.8871 12.6154H8.62903C8.28427 12.6154 8.01613 12.3462 8.01613 12C8.01613 11.6923 8.28427 11.3846 8.62903 11.3846ZM8.62903 17.5385H20.8871C21.1935 17.5385 21.5 17.8462 21.5 18.1538C21.5 18.5 21.1935 18.7692 20.8871 18.7692H8.62903C8.28427 18.7692 8.01613 18.5 8.01613 18.1538C8.01613 17.8462 8.28427 17.5385 8.62903 17.5385ZM3.72581 11.3846V12.6154H4.95161V11.3846H3.72581ZM3.41935 10.1538H5.25806C5.75605 10.1538 6.17742 10.5769 6.17742 11.0769V12.9231C6.17742 13.4615 5.75605 13.8462 5.25806 13.8462H3.41935C2.88306 13.8462 2.5 13.4615 2.5 12.9231V11.0769C2.5 10.5769 2.88306 10.1538 3.41935 10.1538ZM3.72581 18.7692H4.95161V17.5385H3.72581V18.7692ZM2.5 17.2308C2.5 16.7308 2.88306 16.3077 3.41935 16.3077H5.25806C5.75605 16.3077 6.17742 16.7308 6.17742 17.2308V19.0769C6.17742 19.6154 5.75605 20 5.25806 20H3.41935C2.88306 20 2.5 19.6154 2.5 19.0769V17.2308Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineList;
