import { headingFontClasses } from '@/components/atoms/Typography/TextClasses';

/**
 * IHeading Interface for Heading component
 *
 * @interface
 */
export interface IHeading extends React.HTMLAttributes<HTMLHeadingElement> {
  /** The size of the heading. Can be 'h1', 'h2', 'h3', 'h4', 'h5', or 'h6'. */
  size: 'display1' | 'display2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * The font of the heading to display. Can be 'petco' or 'amasis'
   *
   * @default 'petco'
   */
  font?: 'petco' | 'amasis';
  /** The content of the heading. */
  children: string | React.ReactNode;
}

/**
 * Heading Type Types of headings allowed
 *
 * @type {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'}
 */
export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * Heading - The heading is used to encapsulate H1-H6 tags. It allows for the
 * size to be changed and the text to be passed in as a child.
 *
 * @param {IHeading} props - The props for the Heading component
 * @returns {React.FC<IHeading>} Heading Component
 */
const Heading: React.FC<IHeading> = ({
  size,
  font = 'petco',
  children,
  className,
  ...rest
}: IHeading) => {
  let HeadingTag: HeadingType;
  // Set the Heading Tag based on the size prop passed in.
  if (size === 'display1' || size === 'display2') {
    HeadingTag = 'h1';
  } else {
    HeadingTag = size;
  }
  const headingFontClass = headingFontClasses[font];
  const fontTypeClass = font === 'petco' ? 'font-petco' : 'font-amasis';
  const tagClasses = `text-${size} ${fontTypeClass} ${headingFontClass} ${className}`;
  return (
    <HeadingTag className={tagClasses} data-testid={size} {...rest}>
      {children}
    </HeadingTag>
  );
};

export default Heading;
