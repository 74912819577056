import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationCommunity: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-community"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7555 55.3778C14 55.3778 11.7333 53.1111 11.7333 50.3555C11.7333 47.6 14 45.3333 16.7555 45.3333C19.5111 45.3333 21.7778 47.5555 21.7778 50.3555C21.7778 53.1555 19.5111 55.3778 16.7555 55.3778ZM16.7555 48C15.4667 48 14.4 49.0666 14.4 50.3555C14.4 51.6444 15.4667 52.7111 16.7555 52.7111C18.0444 52.7111 19.1111 51.6444 19.1111 50.3555C19.1111 49.0666 18.0444 48 16.7555 48Z"
        fill={color}
      />
      <path
        d="M22.4444 63.7778H11.0667C10.2667 63.7778 9.51111 63.4222 9.02222 62.8C8.53333 62.1778 8.31111 61.3333 8.53333 60.5778C9.42222 56.7111 12.8 54 16.8 54C20.8 54 24.1778 56.7111 25.0667 60.5778C25.2444 61.3778 25.0667 62.1778 24.5778 62.8C24 63.4222 23.2444 63.7778 22.4444 63.7778ZM11.1111 61.1111H22.4444C21.8222 58.4889 19.5111 56.6222 16.8 56.6222C14.0444 56.6667 11.7333 58.4889 11.1111 61.1111Z"
        fill={color}
      />
      <path
        d="M16.9333 70.9333C8.35555 70.9333 1.33333 63.9111 1.33333 55.3333C1.33333 46.7111 8.35555 39.7333 16.9333 39.7333C25.5111 39.7333 32.5333 46.7556 32.5333 55.3333C32.5778 63.9111 25.5555 70.9333 16.9333 70.9333ZM16.9333 42.3556C9.82222 42.3556 3.99999 48.1778 3.99999 55.3333C3.99999 62.4889 9.82222 68.2667 16.9333 68.2667C24.0444 68.2667 29.8667 62.4444 29.8667 55.3333C29.9111 48.1778 24.0889 42.3556 16.9333 42.3556Z"
        fill={color}
      />
      <path
        d="M64.9333 64.9333C62.4444 64.9333 60.4444 62.9333 60.4444 60.4444C60.4444 57.9555 62.4444 55.9555 64.9333 55.9555C67.4222 55.9555 69.4222 57.9555 69.4222 60.4444C69.4222 62.9333 67.4222 64.9333 64.9333 64.9333ZM64.9333 58.6222C63.9111 58.6222 63.1111 59.4222 63.1111 60.4444C63.1111 61.4666 63.9111 62.2666 64.9333 62.2666C65.9555 62.2666 66.7555 61.4666 66.7555 60.4444C66.7555 59.4222 65.9555 58.6222 64.9333 58.6222Z"
        fill={color}
      />
      <path
        d="M69.8222 72.1333H60.0889C59.3333 72.1333 58.6666 71.7778 58.1778 71.2C57.6889 70.6222 57.5555 69.8666 57.6889 69.1111C58.4444 65.6889 61.4667 63.3333 64.9333 63.3333C68.4444 63.3333 71.4222 65.6889 72.1778 69.1111C72.3555 69.8222 72.1778 70.6222 71.6889 71.2C71.2444 71.7778 70.5778 72.1333 69.8222 72.1333ZM60.3555 69.4666H69.5111C68.9333 67.4222 67.0667 66.0444 64.9333 66.0444C62.8 66.0444 60.9333 67.4222 60.3555 69.4666Z"
        fill={color}
      />
      <path
        d="M65.1111 78.2222C57.6444 78.2222 51.5555 72.1333 51.5555 64.6666C51.5555 57.2 57.6444 51.1111 65.1111 51.1111C72.5778 51.1111 78.6667 57.2 78.6667 64.6666C78.6667 72.1333 72.5778 78.2222 65.1111 78.2222ZM65.1111 53.8222C59.1111 53.8222 54.2222 58.7111 54.2222 64.7111C54.2222 70.7111 59.1111 75.6 65.1111 75.6C71.1111 75.6 76 70.7111 76 64.7111C76 58.7111 71.1111 53.8222 65.1111 53.8222Z"
        fill={color}
      />
      <path
        d="M46.0889 18.2222C43.1111 18.2222 40.7111 15.8222 40.7111 12.8445C40.7111 9.86668 43.1111 7.46667 46.0889 7.46667C49.0667 7.46667 51.4667 9.86668 51.4667 12.8445C51.4667 15.8222 49.0667 18.2222 46.0889 18.2222ZM46.0889 10.1333C44.5778 10.1333 43.3778 11.3333 43.3778 12.8445C43.3778 14.3556 44.5778 15.5556 46.0889 15.5556C47.6 15.5556 48.8 14.3556 48.8 12.8445C48.8 11.3333 47.6 10.1333 46.0889 10.1333Z"
        fill={color}
      />
      <path
        d="M52.3111 27.4667H39.8222C38.9778 27.4667 38.1778 27.1111 37.6889 26.4444C37.1556 25.7778 36.9778 24.9333 37.1555 24.0889C38.1333 19.9111 41.7778 16.9778 46.0889 16.9778C50.4 16.9778 54.0444 19.9111 55.0222 24.0889C55.2 24.9333 55.0222 25.7778 54.4889 26.4444C53.9555 27.1111 53.1555 27.4667 52.3111 27.4667ZM46.0889 19.6444C43.0222 19.6444 40.4444 21.7333 39.7333 24.6667C39.7333 24.6667 39.7333 24.7111 39.7333 24.7556C39.7778 24.8 39.7778 24.8 39.7778 24.8H52.2667C52.2667 24.8 52.3111 24.8 52.3111 24.7556C52.3555 24.7111 52.3111 24.6667 52.3111 24.6667C51.7333 21.7333 49.1111 19.6444 46.0889 19.6444Z"
        fill={color}
      />
      <path
        d="M46.3111 35.2889C36.9333 35.2889 29.3333 27.6444 29.3333 18.3111C29.3333 8.97776 36.9333 1.33331 46.3111 1.33331C55.6889 1.33331 63.2889 8.97776 63.2889 18.3111C63.2889 27.6444 55.6444 35.2889 46.3111 35.2889ZM46.3111 3.99998C38.4 3.99998 31.9556 10.4 31.9556 18.3111C31.9556 26.2222 38.4 32.6222 46.2667 32.6222C54.1778 32.6222 60.5778 26.1778 60.5778 18.3111C60.5778 10.4444 54.1778 3.99998 46.3111 3.99998Z"
        fill={color}
      />
      <path
        d="M10.5333 19.5556C9.19999 19.5556 6.7111 17.4667 6.66666 17.4222C5.73333 16.6222 3.77777 14.9778 3.77777 12.1333C3.77777 9.24444 5.86666 7.51111 7.9111 7.51111C8.97777 7.51111 9.82222 7.95555 10.4889 8.48889C11.1111 7.95555 11.9555 7.51111 13.0667 7.51111C15.1111 7.51111 17.2 9.24444 17.2 12.1333C17.2 14.9333 15.2444 16.6222 14.3111 17.4222C14.3555 17.4667 11.8667 19.5556 10.5333 19.5556ZM7.95555 10.2222C7.37777 10.2222 6.48888 10.8444 6.48888 12.1778C6.48888 13.6444 7.42222 14.5778 8.44444 15.4222C9.19999 16.0889 10.1333 16.6222 10.5778 16.8444C11.0222 16.6222 11.8667 16.0889 12.6667 15.4222C13.6889 14.5333 14.6222 13.6444 14.6222 12.1778C14.6222 10.8444 13.7333 10.2222 13.1555 10.2222C12.4444 10.2222 11.8222 11.0222 11.7333 11.2444C11.5111 11.6444 11.0667 11.9111 10.5778 11.9111C10.0889 11.9111 9.68888 11.6444 9.42222 11.2444C9.37777 11.2 8.75555 10.2222 7.95555 10.2222Z"
        fill={color}
      />
      <path
        d="M38.5333 78.6666C37.2 78.6666 34.7111 76.5777 34.6667 76.5333C33.7333 75.7333 31.7778 74.0889 31.7778 71.2444C31.7778 68.3555 33.8667 66.6222 35.9111 66.6222C36.9778 66.6222 37.8222 67.0666 38.4889 67.6C39.1111 67.0666 39.9555 66.6222 41.0667 66.6222C43.1111 66.6222 45.2 68.3555 45.2 71.2444C45.2 74.0444 43.2444 75.7333 42.3111 76.5333C42.3555 76.5777 39.8667 78.6666 38.5333 78.6666ZM35.9555 69.3333C35.3778 69.3333 34.4889 69.9555 34.4889 71.2889C34.4889 72.7555 35.4222 73.6889 36.4444 74.5333C37.2 75.2 38.1333 75.7333 38.5778 75.9555C39.0222 75.7333 39.8667 75.2 40.6667 74.5333C41.6889 73.6444 42.6222 72.7555 42.6222 71.2889C42.6222 69.9555 41.7333 69.3333 41.1555 69.3333C40.4444 69.3333 39.8222 70.1333 39.7333 70.3555C39.5111 70.7555 39.0667 70.9778 38.5778 71.0222C38.0889 71.0222 37.6889 70.7555 37.4222 70.3555C37.3778 70.3111 36.7555 69.3333 35.9555 69.3333Z"
        fill={color}
      />
      <path
        d="M71.8667 47.1111C70.5333 47.1111 68.0444 45.0222 68 44.9778C67.0667 44.1778 65.1111 42.5333 65.1111 39.6889C65.1111 38.4 65.5556 37.2 66.4 36.3555C67.1556 35.5555 68.2222 35.0667 69.2889 35.0667C70.3556 35.0667 71.2 35.5111 71.8667 36.0444C72.4889 35.5111 73.3333 35.0667 74.4444 35.0667C76.4889 35.0667 78.5778 36.8 78.5778 39.6889C78.5778 42.4889 76.6222 44.1778 75.6889 44.9778C75.6889 45.0222 73.2 47.1111 71.8667 47.1111ZM69.2889 37.7778C68.9778 37.7778 68.6222 37.9555 68.3556 38.2222C68.1333 38.4889 67.8222 38.9333 67.8222 39.7333C67.8222 41.2 68.7556 42.1333 69.7778 42.9778C70.5333 43.6444 71.4667 44.1778 71.9111 44.4C72.3556 44.1778 73.2 43.6444 74 42.9778C75.0222 42.0889 75.9556 41.2 75.9556 39.7333C75.9556 38.4 75.0667 37.7778 74.4889 37.7778C73.6889 37.7778 73.0667 38.8 73.0667 38.8C72.8 39.2 72.3556 39.4666 71.9111 39.4222C71.4222 39.4222 71.0222 39.1555 70.7556 38.7555C70.7111 38.7555 70.0889 37.7778 69.2889 37.7778Z"
        fill={color}
      />
      <path
        d="M30.8889 22.4C30.7555 22.4 30.5778 22.3555 30.4444 22.3111L14.4889 16.8C13.7778 16.5778 13.4222 15.7778 13.6444 15.1111C13.8667 14.4 14.6667 14.0444 15.3333 14.2666L31.2889 19.8222C32 20.0444 32.3555 20.8444 32.1333 21.5111C31.9555 22.0444 31.4222 22.4 30.8889 22.4Z"
        fill={color}
      />
      <path
        d="M6.17778 47.2889C6.13333 47.2889 6.13333 47.2889 6.08889 47.2889C5.33333 47.2444 4.8 46.6222 4.84444 45.8667L6.8 17.1111C6.84444 16.3555 7.46667 15.8222 8.22222 15.8667C8.97778 15.9111 9.51111 16.5333 9.46666 17.2889L7.51111 46.0444C7.46666 46.7555 6.84444 47.2889 6.17778 47.2889Z"
        fill={color}
      />
      <path
        d="M26.5778 46.0889C26.3111 46.0889 26.0889 46 25.8667 45.8667C25.2444 45.4667 25.0667 44.6222 25.4667 44L35.0222 29.4667C35.4222 28.8444 36.2667 28.6667 36.8889 29.0667C37.5111 29.4667 37.6889 30.3111 37.2889 30.9333L27.7333 45.4667C27.4222 45.8667 26.9778 46.0889 26.5778 46.0889Z"
        fill={color}
      />
      <path
        d="M59.1555 55.3778C58.6222 55.3778 58.1333 55.0667 57.9555 54.5778L48.8889 34.0444C48.5778 33.3778 48.8889 32.5778 49.5555 32.2667C50.2222 31.9555 51.0222 32.2667 51.3333 32.9333L60.3555 53.4667C60.6667 54.1333 60.3555 54.9333 59.6889 55.2444C59.5111 55.3333 59.3333 55.3778 59.1555 55.3778Z"
        fill={color}
      />
      <path
        d="M67.5556 38.4889C67.2 38.4889 66.8 38.3111 66.5333 38L58.1333 28.1333C57.6444 27.5556 57.7333 26.7111 58.2667 26.2667C58.8445 25.7778 59.6889 25.8667 60.1333 26.4L68.5333 36.2667C69.0222 36.8444 68.9333 37.6889 68.4 38.1333C68.1778 38.3556 67.8667 38.4889 67.5556 38.4889Z"
        fill={color}
      />
      <path
        d="M42.8889 75.4667C42.3111 75.4667 41.7778 75.0667 41.6 74.4889C41.4222 73.7778 41.8222 73.0667 42.5333 72.8445L54.2667 69.6889C54.9778 69.5111 55.6889 69.9111 55.9111 70.6222C56.0889 71.3333 55.6889 72.0445 54.9778 72.2667L43.2444 75.4222C43.1555 75.4667 43.0222 75.4667 42.8889 75.4667Z"
        fill={color}
      />
      <path
        d="M34.1778 75.4667C34.0444 75.4667 33.9111 75.4667 33.8222 75.4222L17.9556 70.8889C17.2444 70.6667 16.8444 69.9555 17.0222 69.2444C17.2444 68.5333 17.9555 68.1333 18.6667 68.3111L34.5333 72.8889C35.2444 73.1111 35.6444 73.8222 35.4667 74.5333C35.2889 75.1111 34.7556 75.4667 34.1778 75.4667Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationCommunity.defaultProps = {
  id: 'illustration-community',
};

export default IllustrationCommunity;
