import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlinePrinter: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-printer"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M19.86 7H18.5V5C18.5217 4.49233 18.3413 3.99675 17.9983 3.62186C17.6553 3.24698 17.1776 3.02335 16.67 3H8.33002C7.82242 3.02335 7.34478 3.24698 7.00175 3.62186C6.65873 3.99675 6.47831 4.49233 6.50002 5V7H5.14002C4.43628 7.00529 3.76331 7.28924 3.2685 7.78968C2.77369 8.29011 2.49735 8.96625 2.50002 9.67V16.33C2.49735 17.0338 2.77369 17.7099 3.2685 18.2103C3.76331 18.7108 4.43628 18.9947 5.14002 19H6.00002C6.00002 19.5304 6.21073 20.0391 6.58581 20.4142C6.96088 20.7893 7.46959 21 8.00002 21H17C17.5305 21 18.0392 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19H19.86C20.5638 18.9947 21.2367 18.7108 21.7315 18.2103C22.2264 17.7099 22.5027 17.0338 22.5 16.33V9.67C22.5027 8.96625 22.2264 8.29011 21.7315 7.78968C21.2367 7.28924 20.5638 7.00529 19.86 7ZM8.50002 5H16.5V7H8.50002V5ZM8.00002 19V15H17V19H8.00002ZM20.5 16.33C20.5027 16.5034 20.4371 16.6708 20.3174 16.7962C20.1976 16.9216 20.0333 16.9948 19.86 17H19V15C19 14.4696 18.7893 13.9609 18.4142 13.5858C18.0392 13.2107 17.5305 13 17 13H8.00002C7.46959 13 6.96088 13.2107 6.58581 13.5858C6.21073 13.9609 6.00002 14.4696 6.00002 15V17H5.14002C4.96672 16.9948 4.80244 16.9216 4.68269 16.7962C4.56293 16.6708 4.49731 16.5034 4.50002 16.33V9.67C4.49731 9.49664 4.56293 9.32919 4.68269 9.20382C4.80244 9.07845 4.96672 9.00523 5.14002 9H19.86C20.0333 9.00523 20.1976 9.07845 20.3174 9.20382C20.4371 9.32919 20.5027 9.49664 20.5 9.67V16.33Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlinePrinter.defaultProps = {
  id: 'icon-outline-printer',
};
export default IconOutlinePrinter;
