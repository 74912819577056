import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineEnvelopeLight: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-envelope-light"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 4.5H4.5C3.09375 4.5 2 5.63281 2 7V17C2 18.4062 3.09375 19.5 4.5 19.5H19.5C20.8672 19.5 22 18.4062 22 17V7C22 5.63281 20.8672 4.5 19.5 4.5ZM4.5 5.75H19.5C20.1641 5.75 20.75 6.33594 20.75 7V8.44531L13.0938 14.1484C12.4297 14.6562 11.5312 14.6562 10.8672 14.1484L3.25 8.40625V7C3.25 6.33594 3.79688 5.75 4.5 5.75ZM20.75 17C20.75 17.7031 20.1641 18.25 19.5 18.25H4.5C3.79688 18.25 3.25 17.7031 3.25 17V9.96875L10.125 15.125C10.6719 15.5547 11.3359 15.7891 12 15.7891C12.625 15.7891 13.2891 15.5547 13.875 15.125L20.75 9.96875V17Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineEnvelopeLight;
