import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationMoneyJar: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-money-jar"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.7778 14.8889C34.5333 14.8889 29.5556 14.4444 25.8222 13.6C21.2889 12.5778 19.1111 11.1556 19.1111 9.24445C19.1111 7.33333 21.2889 5.91111 25.8222 4.88889C29.5556 4 34.5333 3.55556 39.7778 3.55556C45.0222 3.55556 50 4 53.7333 4.84445C58.2667 5.86667 60.4444 7.28889 60.4444 9.2C60.4444 11.1111 58.2667 12.5333 53.7333 13.5556C50.0444 14.4444 45.0667 14.8889 39.7778 14.8889ZM21.8222 9.24445C22.7111 10.3556 29.1556 12.2667 39.7778 12.2667C50.4 12.2667 56.8444 10.4 57.7333 9.24445C56.8444 8.13333 50.4 6.22222 39.7778 6.22222C29.1556 6.22222 22.7111 8.08889 21.8222 9.24445Z"
        fill={color}
      />
      <path
        d="M39.8667 19.7778C34.5778 19.7778 29.6 19.2444 25.8667 18.3111C21.4222 17.2 19.2 15.6 19.2 13.5556C19.2 12.8 19.7778 12.2222 20.5333 12.2222C21.2889 12.2222 21.8667 12.8 21.8667 13.5556C22.1333 14.7556 28.7111 17.1111 39.8667 17.1111C51.0222 17.1111 57.6 14.7556 57.8667 13.5556C57.8667 12.8 58.4445 12.2222 59.2 12.2222C59.9556 12.2222 60.5333 12.8444 60.5333 13.5556C60.5333 15.6 58.2667 17.2 53.8667 18.3111C50.1333 19.2444 45.1556 19.7778 39.8667 19.7778Z"
        fill={color}
      />
      <path
        d="M39.8222 24.6667C32.1333 24.6667 19.1111 23.4222 19.1111 18.6667V9.33333C19.1111 8.57778 19.6889 8 20.4444 8C21.2 8 21.7778 8.57778 21.7778 9.33333V18.6667C22.1778 19.8222 28.7556 22 39.8222 22C50.8889 22 57.4667 19.8222 57.8667 18.6222V9.33333C57.8667 8.57778 58.4444 8 59.2 8C59.9556 8 60.5333 8.57778 60.5333 9.33333V18.6667C60.5333 23.4222 47.5111 24.6667 39.8222 24.6667Z"
        fill={color}
      />
      <path
        d="M58.6667 76.4444H21.0667C16.7556 76.4444 13.2445 72.8444 13.2445 68.4444V51.7333C13.2445 44.4 13.2445 34.7111 13.2 24.9778C13.2 24.2667 13.2 23.3333 13.6 22.4444C14.4889 20.5778 16.6222 19.7333 18.3556 19.0667C18.6667 18.9333 18.9778 18.8 19.2445 18.7111C19.9111 18.4 20.7111 18.7111 20.9778 19.4222C21.2889 20.0889 20.9778 20.8889 20.2667 21.1556C19.9556 21.2889 19.6445 21.4222 19.2889 21.5556C18 22.0889 16.4 22.7111 15.9556 23.6C15.7778 23.9556 15.7778 24.4889 15.7778 25.0222C15.7778 34.7111 15.8222 44.4 15.8222 51.7778V68.4444C15.8222 71.3778 18.1333 73.7778 20.9778 73.7778H58.6667C61.5111 73.7778 63.8222 71.3778 63.8222 68.4444V51.7333C63.8222 43.8667 63.8222 34.5333 63.8667 24.9778C63.8667 24.4444 63.8667 23.9111 63.6889 23.5556C63.2889 22.6667 61.6445 22 60.4 21.5111C60.0445 21.3778 59.7333 21.2444 59.4222 21.1111C58.7556 20.8 58.4445 20.0444 58.7111 19.3778C59.0222 18.7111 59.7778 18.4 60.4445 18.6667C60.7111 18.8 61.0222 18.8889 61.3333 19.0222C63.0667 19.6889 65.2 20.5778 66.0889 22.4C66.4889 23.2889 66.4889 24.2222 66.4889 24.9333C66.4889 34.4444 66.4445 43.7778 66.4445 51.6889V68.4444C66.4889 72.8444 62.9778 76.4444 58.6667 76.4444Z"
        fill={color}
      />
      <path
        d="M27.1111 74.6667C21.4667 74.6667 16.8889 70.0889 16.8889 64.4445C16.8889 62.0889 17.6889 59.7778 19.2 58C21.1556 55.6 24.0444 54.2222 27.1111 54.2222C28.3111 54.2222 29.4667 54.4445 30.6222 54.8445C34.2667 56.1778 36.8889 59.5111 37.2889 63.3778C37.3333 63.7333 37.3333 64.0889 37.3333 64.4445C37.3333 70.0889 32.7556 74.6667 27.1111 74.6667ZM27.1111 56.8889C24.8444 56.8889 22.7111 57.9111 21.2444 59.6889C20.1333 61.0222 19.5556 62.7111 19.5556 64.4445C19.5556 68.6222 22.9333 72 27.1111 72C31.2889 72 34.6667 68.6222 34.6667 64.4445C34.6667 64.1778 34.6667 63.9111 34.6222 63.6445C34.3111 60.8 32.4 58.3111 29.6889 57.3333C28.8889 57.0222 28 56.8889 27.1111 56.8889Z"
        fill={color}
      />
      <path
        d="M20.6222 60.4889L19.6889 60.0445C16.3556 58.5333 14.2222 55.2 14.2222 51.5556C14.2222 46.4 18.4 42.2222 23.5556 42.2222C27.3778 42.2222 30.8889 44.6222 32.2667 48.2222C32.6667 49.2889 32.8889 50.4445 32.8889 51.5556C32.8889 53.4667 32.3111 55.2889 31.2445 56.8445L30.6667 57.6889L29.6889 57.3333C28.8445 57.0222 28 56.8889 27.1111 56.8889C24.8445 56.8889 22.7111 57.9111 21.2445 59.6889L20.6222 60.4889ZM23.5556 44.8889C19.8667 44.8889 16.8889 47.8667 16.8889 51.5556C16.8889 53.8222 18.0445 55.9556 19.9556 57.1556C21.8667 55.2889 24.4 54.2222 27.1111 54.2222C27.9111 54.2222 28.7556 54.3111 29.5111 54.4889C29.9556 53.5556 30.2222 52.5778 30.2222 51.5556C30.2222 50.7556 30.0889 49.9111 29.7778 49.1556C28.8 46.6222 26.3111 44.8889 23.5556 44.8889Z"
        fill={color}
      />
      <path
        d="M34.7556 64.9778L34.6222 63.6444C34.3111 60.8 32.4 58.3111 29.6889 57.3333L28.0889 56.7556L29.0667 55.3333C29.8222 54.2222 30.2222 52.8889 30.2222 51.5556C30.2222 50.7556 30.0889 49.9111 29.7778 49.1556L29.3778 48.0889L30.3556 47.5111C31.7778 46.6667 33.4222 46.1778 35.1111 46.1778C40.2667 46.1778 44.4445 50.3556 44.4445 55.5111C44.4445 55.9556 44.4 56.4444 44.3556 56.8889L44.2222 57.7333L43.4222 58C42.7111 58.2222 41.4667 58.8444 40.7556 60.7111C40.5333 61.3333 40.4 61.9556 40.3556 62.5778L40.3111 63.2889L39.6889 63.6444C38.5778 64.2667 37.3333 64.6667 36.0445 64.8L34.7556 64.9778ZM32.0445 55.4667C34.5333 56.8444 36.3556 59.2 37.0222 61.9111C37.2889 61.8222 37.5556 61.7333 37.8222 61.6444C37.9111 61.0222 38.0889 60.4 38.3111 59.7778C38.9778 57.9556 40.2222 56.5333 41.8222 55.7778C41.8222 55.6889 41.8222 55.6 41.8222 55.5111C41.8222 51.8222 38.8445 48.8444 35.1556 48.8444C34.3111 48.8444 33.4667 49.0222 32.6667 49.3333C32.8445 50.0444 32.9333 50.8 32.9333 51.5111C32.8889 52.9333 32.5778 54.2667 32.0445 55.4667Z"
        fill={color}
      />
      <path
        d="M56.4445 60.4444C52.0445 60.4444 48.4445 56.8444 48.4445 52.4444C48.4445 48.0444 52.0445 44.4444 56.4445 44.4444C60.8445 44.4444 64.4445 48.0444 64.4445 52.4444C64.4445 56.8444 60.8445 60.4444 56.4445 60.4444ZM56.4445 47.1111C53.5111 47.1111 51.1111 49.5111 51.1111 52.4444C51.1111 55.3778 53.5111 57.7778 56.4445 57.7778C59.3778 57.7778 61.7778 55.3778 61.7778 52.4444C61.7778 49.5111 59.3778 47.1111 56.4445 47.1111Z"
        fill={color}
      />
      <path
        d="M56.4445 76C53.6889 76 51.1556 74.6222 49.6889 72.2667L48.8445 70.8889L50.3556 70.3111C52.3111 69.5556 54.2667 68.4889 55.2 66.0889C55.7333 64.6667 55.6 63.2 54.8445 62.1333L53.5111 60.2667L55.7778 60.0444C56 60.0444 56.2222 60 56.4445 60C60.8445 60 64.4445 63.6 64.4445 68C64.4445 72.4 60.8445 76 56.4445 76ZM52.9778 72.0444C53.9111 72.8444 55.1556 73.3333 56.4445 73.3333C59.3778 73.3333 61.7778 70.9333 61.7778 68C61.7778 65.6 60.1778 63.5556 58 62.8889C58.2667 64.1778 58.1778 65.6 57.6445 67.0222C56.6667 69.6444 54.8444 71.1111 52.9778 72.0444Z"
        fill={color}
      />
      <path
        d="M44.9778 74.3111C44.5778 74.3111 44.2222 74.2667 44 74.1778C42.6222 73.6889 40.4889 70.4 39.6889 69.0222C38.6222 67.1111 37.6445 64.9778 37.7778 62.4444C37.8222 61.5556 38 60.6667 38.3111 59.8222C39.1111 57.6889 40.6667 56.1333 42.6667 55.5111C43.9111 55.1111 45.2 55.1556 46.3556 55.5556C48.1778 56.2222 49.2 57.7333 49.7333 58.8889C50.9334 58.3556 52.6667 57.8667 54.4445 58.5333C55.4667 58.9333 56.4 59.6444 57.0667 60.5778C58.3556 62.3556 58.5778 64.7111 57.7333 67.0222C56.4445 70.4444 53.6889 71.9111 51.3778 72.8C51.1556 72.8889 50.8889 72.9778 50.6667 73.0667C49.3333 73.5555 46.6667 74.3111 44.9778 74.3111ZM44.8889 71.6444C45.5556 71.6444 47.6445 71.2889 49.6445 70.5778C49.8667 70.4889 50.0889 70.4444 50.2667 70.3556C52.2667 69.6 54.2222 68.5333 55.1111 66.0889C55.6445 64.6667 55.5111 63.2 54.7556 62.1333C54.4 61.6 53.9111 61.2444 53.3778 61.0667C51.6445 60.4 49.6889 61.9111 49.6889 61.9555C49.3334 62.2667 48.8 62.3111 48.4 62.1333C47.9556 61.9556 47.6445 61.5556 47.5556 61.1111C47.5556 61.1111 47.1111 58.7111 45.3333 58.0444C44.7556 57.8222 44.0445 57.8222 43.3778 58.0444C42.7111 58.2667 41.4222 58.8889 40.7556 60.7556C40.5333 61.3778 40.4 62 40.3556 62.6222C40.2667 64.5333 41.1111 66.2667 41.9556 67.7333C43.0222 69.5555 44.4 71.2444 44.8889 71.6444Z"
        fill={color}
      />
      <path
        d="M27.3333 67.0667L27.1556 66.3556C26.2222 66.4445 25.6889 65.9556 25.4667 65.4222L26.1778 65.0222C26.3111 65.4222 26.7111 65.7333 27.2889 65.5556C27.7333 65.4222 27.9111 65.1556 27.8222 64.8444C27.7778 64.5778 27.5111 64.4445 27.2 64.4889L26.5778 64.5333C25.8667 64.5778 25.3333 64.2222 25.2 63.6444C25.0222 62.9778 25.4222 62.3556 26.0445 62.0445L25.8667 61.3333L26.4889 61.1556L26.6667 61.9111C27.4222 61.8667 27.8667 62.2667 28.0445 62.6667L27.3778 63.0667C27.2889 62.8444 26.9778 62.5333 26.4889 62.6667C26.0889 62.7556 25.9111 63.0667 25.9556 63.3333C26 63.5556 26.2222 63.7333 26.5333 63.6889L27.1556 63.6444C27.9556 63.6 28.4889 63.9556 28.6222 64.5778C28.7556 65.1556 28.4889 65.8222 27.7778 66.1333L27.9556 66.8444L27.3333 67.0667Z"
        fill={color}
      />
      <path
        d="M54.9333 54.1778L55.2444 53.6444C54.6222 53.1556 54.6222 52.5333 54.8 52.0889L55.4222 52.2667C55.2889 52.5778 55.3333 52.9778 55.7778 53.2444C56.0889 53.4222 56.4 53.3778 56.5333 53.1556C56.6222 52.9778 56.5778 52.7556 56.3556 52.5778L55.9556 52.2222C55.5111 51.8222 55.3778 51.2889 55.6444 50.8444C55.9556 50.3556 56.5333 50.1778 57.1111 50.4L57.4222 49.8667L57.9111 50.1333L57.6 50.6667C58.0889 51.0667 58.0889 51.6 57.9556 51.9556L57.3333 51.7778C57.4222 51.6 57.4222 51.2444 57.0222 51.0222C56.7556 50.8444 56.4444 50.9333 56.3111 51.1111C56.2222 51.2889 56.2222 51.4667 56.4444 51.6889L56.8444 52.0444C57.3333 52.4889 57.4222 53.0222 57.2 53.4667C56.9333 53.9111 56.4 54.1333 55.7778 53.8667L55.4667 54.4L54.9333 54.1778Z"
        fill={color}
      />
      <path
        d="M22.8889 52.3111L22.9778 51.6889C22.2222 51.4667 21.9556 50.8889 22 50.4L22.6667 50.3111C22.6667 50.6667 22.8444 51.0222 23.3778 51.1111C23.7333 51.1556 24 51.0222 24.0444 50.7556C24.0889 50.5333 23.9556 50.3556 23.6889 50.2667L23.2 50.0889C22.6222 49.8667 22.3556 49.4667 22.4 48.9333C22.4889 48.3556 22.9778 48 23.6 48L23.6889 47.3778L24.2222 47.4667L24.1333 48.0889C24.7556 48.2667 24.9333 48.7556 24.9333 49.1556L24.2667 49.2444C24.2667 49.0222 24.1333 48.7111 23.6889 48.6667C23.3778 48.6222 23.1111 48.8 23.0667 49.0222C23.0222 49.2444 23.1556 49.3778 23.3778 49.5111L23.8667 49.6889C24.4889 49.9111 24.8 50.4 24.7111 50.8889C24.6222 51.3778 24.2222 51.8222 23.5111 51.8222L23.4222 52.4444L22.8889 52.3111Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationMoneyJar.defaultProps = {
  id: 'illustration-money-jar',
};

export default IllustrationMoneyJar;
