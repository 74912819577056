import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationBinocular: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-binocular"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5111 69.1556C9.59999 69.1556 1.55554 61.1111 1.55554 51.2C1.55554 41.2889 9.59999 33.2444 19.5111 33.2444C29.4222 33.2444 37.4667 41.3333 37.4667 51.2C37.4667 61.0667 29.4222 69.1556 19.5111 69.1556ZM19.5111 35.9556C11.0667 35.9556 4.22221 42.8 4.22221 51.2444C4.22221 59.6889 11.0667 66.5333 19.5111 66.5333C27.9555 66.5333 34.8 59.6889 34.8 51.2444C34.8 42.8 27.9555 35.9556 19.5111 35.9556Z"
        fill={color}
      />
      <path
        d="M9.06667 52.5333C8.31112 52.5333 7.73334 51.9555 7.73334 51.2C7.73334 44.7111 13.0222 39.4222 19.5111 39.4222C20.2667 39.4222 20.8444 40 20.8444 40.7555C20.8444 41.5111 20.2667 42.0888 19.5111 42.0888C14.4889 42.0888 10.4 46.1777 10.4 51.2C10.4 51.9555 9.82223 52.5333 9.06667 52.5333Z"
        fill={color}
      />
      <path
        d="M60.4889 69.1556C50.5778 69.1556 42.5333 61.1111 42.5333 51.2C42.5333 41.2889 50.5778 33.2444 60.4889 33.2444C70.4 33.2444 78.4444 41.2889 78.4444 51.2C78.4444 61.1111 70.3556 69.1556 60.4889 69.1556ZM60.4889 35.9556C52.0444 35.9556 45.2 42.8 45.2 51.2444C45.2 59.6889 52.0444 66.5333 60.4889 66.5333C68.9333 66.5333 75.7778 59.6889 75.7778 51.2444C75.7778 42.8 68.8889 35.9556 60.4889 35.9556Z"
        fill={color}
      />
      <path
        d="M50.0445 52.5333C49.2889 52.5333 48.7111 51.9555 48.7111 51.2C48.7111 44.7111 54 39.4222 60.4889 39.4222C61.2445 39.4222 61.8222 40 61.8222 40.7555C61.8222 41.5111 61.2445 42.0888 60.4889 42.0888C55.4667 42.0888 51.3778 46.1777 51.3778 51.2C51.3778 51.9555 50.7556 52.5333 50.0445 52.5333Z"
        fill={color}
      />
      <path d="M43.9111 45.6H35.5555V48.2666H43.9111V45.6Z" fill={color} />
      <path
        d="M43.9111 22.0444H35.5111V24.7111H43.9111V22.0444Z"
        fill={color}
      />
      <path
        d="M43.9111 50.0889H35.5111V52.7555H43.9111V50.0889Z"
        fill={color}
      />
      <path
        d="M3.55555 47.7333C3.42222 47.7333 3.33333 47.7333 3.19999 47.6889C2.48888 47.5111 2.08888 46.7555 2.26666 46.0444L9.2 21.1555C10.8889 15.0666 16.4444 10.8444 22.7556 10.8444C30.5333 10.8444 36.8444 17.1555 36.8444 24.9333V46.4C36.8444 47.1555 36.2667 47.7333 35.5111 47.7333C34.7556 47.7333 34.1778 47.1555 34.1778 46.4V24.9333C34.1778 18.6222 29.0667 13.5111 22.7556 13.5111C17.6444 13.5111 13.1111 16.9333 11.7778 21.8666L4.84444 46.7555C4.66666 47.3333 4.13333 47.7333 3.55555 47.7333Z"
        fill={color}
      />
      <path
        d="M76.4444 47.7333C75.8666 47.7333 75.3333 47.3333 75.1555 46.7555L68.2222 21.8666C66.8444 16.9333 62.3111 13.5111 57.2444 13.5111C50.9333 13.5111 45.8222 18.6222 45.8222 24.9333V46.4C45.8222 47.1555 45.2444 47.7333 44.4889 47.7333C43.7333 47.7333 43.1555 47.1555 43.1555 46.4V24.9333C43.1555 17.1555 49.4666 10.8444 57.2444 10.8444C63.5555 10.8444 69.1111 15.0666 70.8 21.1555L77.7333 46.0444C77.9111 46.7555 77.5111 47.5111 76.8 47.6889C76.7111 47.6889 76.5777 47.7333 76.4444 47.7333Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationBinocular.defaultProps = {
  id: 'illustration-binocular',
};

export default IllustrationBinocular;
