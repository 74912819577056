import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineMessage: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      data-testid="icon-outline-message"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 2H4.5C3.09375 2 2 3.13816 2 4.51181V15.7757C2 17.1494 3.09375 18.2483 4.5 18.2483H8.25V21.545C8.25 21.9375 8.67969 22.1337 8.99219 21.8983L13.875 18.2483H19.5C20.8672 18.2483 22 17.1101 22 15.7757V4.51181C22 3.13816 20.9062 2 19.5 2ZM20.7891 15.815C20.7891 16.5214 20.2031 17.0709 19.5391 17.0709H13.875C13.6016 17.0709 13.3672 17.1886 13.1328 17.3456L9.53906 20.0536V17.6988C9.53906 17.3848 9.22656 17.0709 8.91406 17.0709H4.53906C3.83594 17.0709 3.28906 16.5214 3.28906 15.815V4.51181C3.28906 3.84461 3.83594 3.25591 4.53906 3.25591H19.5391C20.2031 3.25591 20.7891 3.84461 20.7891 4.51181V15.815Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineMessage;
