import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPartyPopperFilled: React.FC<IIconType> = ({
  size,
  color,
  id,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-party-popper-filled"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.2 36.1333C40.8889 36.1333 40.6222 36.0444 40.3555 35.8222C39.7778 35.3778 39.6889 34.5333 40.1778 33.9556L55.3333 15.3333C55.7778 14.7556 56.6222 14.6667 57.2 15.1556C57.7777 15.6 57.8666 16.4444 57.3778 17.0222L42.2222 35.6444C41.9555 36 41.5555 36.1333 41.2 36.1333Z"
        fill={color}
      />
      <path
        d="M48.3556 42C48 42 47.6444 41.8667 47.3778 41.6C46.8889 41.0667 46.8889 40.2222 47.4222 39.7333C47.5555 39.6445 58.8 29.2 71.7333 35.4222C72.4 35.7333 72.6667 36.5333 72.3556 37.2C72.0444 37.8667 71.2444 38.1333 70.5778 37.8222C59.2889 32.4 49.6444 41.2889 49.2444 41.6889C49.0222 41.8667 48.6667 42 48.3556 42Z"
        fill={color}
      />
      <path
        d="M34.0444 30.3111C33.8222 30.3111 33.5555 30.2667 33.3333 30.1333C32.7111 29.7333 32.5333 28.9333 32.8889 28.3111C33.2 27.8222 39.9111 16.5778 32.3111 6.62222C31.8667 6.04444 31.9555 5.2 32.5778 4.75555C33.1555 4.31111 34 4.4 34.4444 5.02222C43.1555 16.4444 35.2444 29.6 35.1555 29.7333C34.8889 30.0889 34.4889 30.3111 34.0444 30.3111Z"
        fill={color}
      />
      <path
        d="M61.9111 29.7778C61.4222 29.7778 61.0222 29.6889 60.7111 29.6444C60.6222 29.6444 58.9333 29.2889 58.4444 28.6667C57.9555 28.0444 58.0444 26.2667 58.0444 26.2667C58.0889 25.6 58.1333 24.1778 59.3777 23.2C60.6666 22.1778 62.1777 22.5333 62.8888 23.4667C63.2444 23.9111 63.3333 24.4 63.3777 24.8444C63.7777 24.9778 64.2222 25.2 64.5777 25.6444C65.2889 26.5778 65.2888 28.1333 64 29.1111C63.2888 29.6444 62.5333 29.7778 61.9111 29.7778ZM61.0666 27.9111C61.8666 28.0889 62.4 28.0889 62.8888 27.6889C63.3333 27.3333 63.3333 26.8889 63.2 26.7111C63.0222 26.4889 62.5777 26.4889 62.4889 26.4889C62.1777 26.5333 61.8666 26.4 61.6889 26.1333C61.5111 25.8667 61.4666 25.5555 61.5555 25.2444C61.5555 25.2444 61.7333 24.7555 61.5111 24.5333C61.3777 24.3555 60.9333 24.2222 60.4889 24.5778C60 24.9778 59.8666 25.5111 59.8222 26.3111C59.8222 26.8 59.8666 27.2444 59.9111 27.5111C60.1777 27.6444 60.6222 27.8222 61.0666 27.9111Z"
        fill={color}
      />
      <path
        d="M58.0444 48.6667C56.8 48.6667 55.9555 47.9556 55.4666 47.6C55.4222 47.5556 54.0444 46.4444 53.9111 45.6889C53.7778 44.9333 54.6666 43.3778 54.6666 43.3778C55.0222 42.8 55.6889 41.5556 57.2444 41.2889C57.9555 41.1556 58.6666 41.2889 59.2444 41.6444C59.7778 42 60.1778 42.5333 60.2666 43.1111C60.3555 43.6889 60.2666 44.1778 60.0444 44.5333C60.3555 44.8444 60.6666 45.2444 60.7555 45.7778C60.9778 46.9333 60.2222 48.3111 58.6222 48.5778C58.4444 48.6667 58.2666 48.6667 58.0444 48.6667ZM55.7333 45.3778C55.9111 45.6 56.2222 45.9111 56.5778 46.2222C57.2 46.7111 57.6889 46.9778 58.3111 46.8889C58.8889 46.8 59.0666 46.4 59.0222 46.1333C58.9778 45.8667 58.5778 45.6444 58.4889 45.6C58.1778 45.4667 58 45.2444 57.9111 44.9333C57.8666 44.6222 57.9555 44.3111 58.2222 44.0889C58.2222 44.0889 58.5778 43.7333 58.5333 43.4222C58.5333 43.3333 58.4444 43.2 58.2666 43.1111C58.1333 43.0222 57.9111 42.9333 57.5555 43.0222C56.9333 43.1556 56.5778 43.5556 56.1778 44.2667C56 44.6667 55.8222 45.1111 55.7333 45.3778Z"
        fill={color}
      />
      <path
        d="M28.7111 25.2C27.9555 25.2 26.6222 24.1333 26.5333 24.0889C25.9999 23.6444 24.9333 22.7556 24.8888 21.2C24.8444 19.5556 26.0444 18.5778 27.1999 18.5333C27.7777 18.5333 28.2222 18.7111 28.5777 18.9778C28.9333 18.7111 29.3777 18.4889 29.9555 18.4889C31.1111 18.4889 32.3555 19.4222 32.3555 21.0667C32.3999 22.6222 31.3333 23.5556 30.7999 24.0444C30.8444 24 29.5111 25.2 28.7111 25.2ZM27.2888 20.2667C27.0222 20.2667 26.7111 20.5333 26.7111 21.1111C26.7111 21.7333 27.0666 22.1778 27.6888 22.6667C28.0444 22.9778 28.4444 23.2 28.7111 23.3333C28.9333 23.2 29.3333 22.9333 29.6888 22.6222C30.3111 22.0889 30.6222 21.6444 30.6222 21.0222C30.6222 20.4444 30.2666 20.1778 29.9999 20.1778C29.6888 20.1778 29.4222 20.6222 29.4222 20.6222C29.2444 20.8889 28.9777 21.0667 28.6666 21.0667C28.3555 21.0667 28.0444 20.9333 27.9111 20.6667C27.8222 20.5778 27.5111 20.2667 27.2888 20.2667Z"
        fill={color}
      />
      <path
        d="M44.4444 16.7556C44.1333 16.7556 43.8666 16.7111 43.6444 16.6222C42.9333 16.2667 42.2666 14.6667 42.2222 14.6222C41.9555 14 41.4222 12.7111 42.0888 11.2889C42.3999 10.6222 42.9333 10.1333 43.5555 9.91111C44.1777 9.68888 44.7999 9.73333 45.3777 9.99999C45.8666 10.2667 46.1777 10.6222 46.3999 11.0222C46.7999 10.9333 47.3333 10.9778 47.8222 11.2C48.8888 11.7333 49.5111 13.1556 48.7999 14.6222C48.0888 16.0444 46.7111 16.3556 46.0888 16.5333C46.1333 16.5333 45.2444 16.7556 44.4444 16.7556ZM44.4444 11.5556C44.3999 11.5556 44.3111 11.5556 44.2222 11.6C44.0888 11.6444 43.8666 11.7778 43.7333 12.0889C43.4666 12.6667 43.5999 13.2 43.9111 13.9556C44.0888 14.4 44.3555 14.8 44.4888 15.0222C44.7555 15.0222 45.2444 14.9778 45.6888 14.8444C46.4888 14.6667 46.9777 14.4444 47.2444 13.8667C47.5111 13.3333 47.2888 12.9333 47.0666 12.8444C46.7999 12.7111 46.3555 12.9778 46.3555 12.9778C46.0888 13.1556 45.7777 13.1556 45.4666 13.0222C45.1555 12.8889 44.9777 12.6222 44.9777 12.3111C44.9777 12.1333 44.8444 11.7778 44.6222 11.6444C44.6222 11.6 44.5333 11.5556 44.4444 11.5556Z"
        fill={color}
      />
      <path
        d="M52.5333 52.6222L21.4667 27.2889C20.4444 26.4444 18.8889 26.9778 18.6222 28.2667L16.4889 37.8222L42.6667 59.8667L52.1778 55.5556C53.3778 55.0667 53.5556 53.4222 52.5333 52.6222Z"
        fill={color}
      />
      <path
        d="M42.6222 61.2444C42.3111 61.2444 42 61.1556 41.7778 60.9333L15.6 38.8889C15.2 38.5778 15.0666 38.0444 15.1555 37.6L17.2889 28C17.5111 26.9333 18.2666 26.0889 19.2889 25.7333C20.3111 25.3778 21.4222 25.5556 22.2666 26.2667L53.3333 51.5556C54.1778 52.2222 54.5778 53.2889 54.4444 54.3556C54.3111 55.4222 53.6444 56.3556 52.6222 56.8L43.1111 61.1111C43.0222 61.2 42.8 61.2444 42.6222 61.2444ZM17.9555 37.3778L42.8444 58.3556L51.6 54.4C51.7778 54.3111 51.8666 54.1333 51.8666 54.0444C51.8666 53.9556 51.8666 53.7778 51.6889 53.6444L20.6222 28.3556C20.4444 28.2222 20.2666 28.2667 20.1778 28.2667C20.0889 28.3111 19.9555 28.4 19.9111 28.5778L17.9555 37.3778Z"
        fill={color}
      />
      <path
        d="M37.5555 62.1778L15.2444 43.4222L13.7777 49.9556L31.5555 64.8889L37.5555 62.1778Z"
        fill={color}
      />
      <path
        d="M31.5555 66.2222C31.2444 66.2222 30.9333 66.1333 30.7111 65.9111L12.9333 50.9333C12.5333 50.6222 12.4 50.0889 12.4888 49.6444L13.9555 43.1111C14.0444 42.6667 14.4 42.2667 14.8444 42.1333C15.2888 42 15.7777 42.0889 16.1333 42.3556L38.4444 61.1556C38.8 61.4667 38.9777 61.9111 38.8888 62.3556C38.8444 62.8 38.5333 63.2 38.1333 63.3778L32.1333 66.0889C31.9111 66.1778 31.7333 66.2222 31.5555 66.2222ZM15.2444 49.4222L31.7777 63.3333L35.0666 61.8222L16.0444 45.8222L15.2444 49.4222Z"
        fill={color}
      />
      <path
        d="M12.6222 55.0667L8.84439 72.0444C8.53328 73.4667 9.9555 74.6667 11.2888 74.0444L26.7999 67.0222L12.6222 55.0667Z"
        fill={color}
      />
      <path
        d="M10.5777 75.5555C9.86664 75.5555 9.19997 75.3333 8.62219 74.8444C7.68886 74.0889 7.28886 72.9333 7.55553 71.7778L11.3333 54.8C11.4222 54.3555 11.7777 53.9555 12.2222 53.8222C12.6666 53.6889 13.1555 53.7778 13.5111 54.0444L27.7333 66C28.0889 66.3111 28.2666 66.7555 28.1777 67.2C28.1333 67.6444 27.8222 68.0444 27.4222 68.2222L11.9111 75.2444C11.4666 75.4222 11.0222 75.5555 10.5777 75.5555ZM13.4666 57.5111L10.1777 72.3555C10.1333 72.5778 10.2666 72.7111 10.3111 72.8C10.4 72.8444 10.5777 72.9778 10.7555 72.8444L24.3555 66.6667L13.4666 57.5111Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationPartyPopperFilled.defaultProps = {
  id: 'illustration-party-poppwer-filled',
};

export default IllustrationPartyPopperFilled;
