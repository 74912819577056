import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineArrowUp: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-arrow-up"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M17.9999 15C17.7663 15.0004 17.5399 14.9191 17.3599 14.77L11.9999 10.29L6.62994 14.61C6.52765 14.693 6.40996 14.7551 6.28362 14.7925C6.15728 14.8299 6.02479 14.842 5.89376 14.8281C5.76273 14.8142 5.63575 14.7745 5.52011 14.7113C5.40448 14.6481 5.30246 14.5627 5.21994 14.46C5.13688 14.3577 5.07485 14.24 5.03741 14.1137C4.99998 13.9873 4.98789 13.8548 5.00183 13.7238C5.01577 13.5928 5.05546 13.4658 5.11864 13.3502C5.18181 13.2345 5.26722 13.1325 5.36994 13.05L11.3699 8.21998C11.5489 8.0729 11.7733 7.99249 12.0049 7.99249C12.2366 7.99249 12.461 8.0729 12.6399 8.21998L18.6399 13.22C18.7412 13.3039 18.8249 13.407 18.8863 13.5234C18.9476 13.6397 18.9854 13.767 18.9975 13.898C19.0096 14.029 18.9957 14.1611 18.9566 14.2867C18.9176 14.4123 18.8541 14.5289 18.7699 14.63C18.6769 14.7447 18.5596 14.8374 18.4264 14.9013C18.2933 14.9653 18.1476 14.999 17.9999 15Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineArrowUp.defaultProps = {
  id: 'icon-outline-arrow-up',
};

export default IconOutlineArrowUp;
