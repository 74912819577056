/**
 * Take in photo object key or photo path and return the full url
 *
 * @param {string} photoPath - The photo path
 * @returns {string} - The complete url
 */
const generateImageUrl = (photoPath?: string | undefined): string => {
  const baseCDN = process.env.NEXT_PUBLIC_CDN_BASE_URL;
  const placeholder = '/assets/lost/placeholder/pet-placeholder.jpg';
  if (!photoPath) {
    return `${baseCDN}${placeholder}`;
  }
  if (photoPath.startsWith('http')) {
    return photoPath;
  }
  if (photoPath.startsWith('/')) {
    return `${baseCDN}${photoPath}`;
  }
  return `${baseCDN}/${photoPath}`;
};

export default generateImageUrl;
