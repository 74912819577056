import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineNavigationArrow: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-navigation-arrow"
    >
      <path
        d="M17.6151 6.41631C17.3741 6.14854 17.0528 6.01465 16.7047 6.01465C16.5172 6.01465 16.3566 6.0682 16.1959 6.12176L6.79701 9.97771C6.23468 10.2187 5.91336 10.8078 6.02047 11.4237C6.15435 12.0128 6.6899 12.468 7.30578 12.468H11.5634V16.7256C11.5634 17.3683 12.0722 17.9842 12.8487 17.9842C13.3575 17.9842 13.8395 17.6628 14.0269 17.1809L17.9097 7.80874C18.0971 7.32674 17.99 6.76442 17.6151 6.41631ZM17.1063 7.48741L13.2772 16.8863C13.1968 17.0737 13.0362 17.1541 12.8755 17.1541C12.5809 17.1541 12.4203 16.9131 12.4203 16.7524V11.5844H7.30578C7.11834 11.5844 6.9309 11.4505 6.90412 11.263C6.82379 11.0488 6.9309 10.8614 7.11834 10.781L16.5708 6.92508C16.5976 6.8983 16.6511 6.87153 16.7047 6.87153C16.8118 6.87153 16.9189 6.92508 16.9992 7.00541C17.1331 7.1393 17.1867 7.32674 17.1063 7.48741Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineNavigationArrow;
