import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationMegaphone: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-megaphone"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8667 46.1778H15.4667C7.9111 46.1778 1.77777 40.0444 1.77777 32.4889C1.77777 24.9333 7.9111 18.7556 15.5111 18.7556H31.8667C32.7111 18.7556 33.4222 19.4222 33.4222 20.3111V44.6222C33.3778 45.4667 32.7111 46.1778 31.8667 46.1778ZM15.5111 21.4667C9.42222 21.4667 4.44444 26.4 4.44444 32.4889C4.44444 38.5778 9.37777 43.5111 15.4667 43.5111H30.7555V21.4667H15.5111Z"
        fill={color}
      />
      <path
        d="M61.4666 53.9111C60.9778 53.9111 60.4889 53.8667 59.9555 53.7333L32.2666 46.3111C31.3333 46.0444 30.7111 45.2444 30.7111 44.2667V20.7111C30.7111 19.7778 31.3333 18.9333 32.2222 18.6667L60.8 10.4889C62.3111 10.0444 63.9111 10.3556 65.1555 11.2889C66.4 12.2222 67.1111 13.6889 67.1111 15.2444V48.1333C67.1111 49.9111 66.3111 51.6 64.8889 52.6667C63.9111 53.4667 62.7111 53.9111 61.4666 53.9111ZM60.6666 51.1111C61.6 51.3778 62.5778 51.1556 63.3333 50.5778C64.0889 50 64.5333 49.1111 64.5333 48.1333V15.2444C64.5333 14.5333 64.2222 13.8667 63.6444 13.4222C63.0666 12.9778 62.3555 12.8444 61.6444 13.0667L33.3778 21.1111V43.8222L60.6666 51.1111Z"
        fill={color}
      />
      <path
        d="M24.8 69.6889C20.0889 69.6889 16.2667 65.8667 16.2222 61.2L14.2667 43.5111H31.4222L33.3778 61.1111C33.3778 65.8667 29.5556 69.6889 24.8 69.6889ZM17.2444 46.1778L18.8889 61.1111C18.8889 64.3556 21.5556 67.0222 24.8 67.0222C28.0444 67.0222 30.6667 64.4 30.7111 61.2L29.0222 46.1778H17.2444Z"
        fill={color}
      />
      <path
        d="M76.4444 34.3111H70.4889C69.7333 34.3111 69.1555 33.7333 69.1555 32.9778C69.1555 32.2222 69.7333 31.6444 70.4889 31.6444H76.4444C77.2 31.6444 77.7778 32.2222 77.7778 32.9778C77.7778 33.7333 77.2 34.3111 76.4444 34.3111Z"
        fill={color}
      />
      <path
        d="M70.4889 29.1556C70.1333 29.1556 69.8222 29.0222 69.5555 28.7555C69.0222 28.2222 69.0222 27.3778 69.5555 26.8889L73.7778 22.6667C74.3111 22.1333 75.1555 22.1333 75.6444 22.6667C76.1778 23.2 76.1778 24.0444 75.6444 24.5333L71.4222 28.7555C71.2 29.0222 70.8444 29.1556 70.4889 29.1556Z"
        fill={color}
      />
      <path
        d="M74.7111 43.1556C74.3556 43.1556 74.0444 43.0222 73.7778 42.7555L69.5555 38.5333C69.0222 38 69.0222 37.1556 69.5555 36.6667C70.0889 36.1333 70.9333 36.1333 71.4222 36.6667L75.6444 40.8889C76.1778 41.4222 76.1778 42.2667 75.6444 42.7555C75.3778 43.0222 75.0222 43.1556 74.7111 43.1556Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationMegaphone.defaultProps = {
  id: 'illustration-megaphone',
};

export default IllustrationMegaphone;
