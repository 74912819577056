import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCheckCircle: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-check-circle"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M9.70994 11.29C9.52164 11.1017 9.26624 10.9959 8.99994 10.9959C8.73364 10.9959 8.47825 11.1017 8.28994 11.29C8.10164 11.4783 7.99585 11.7337 7.99585 12C7.99585 12.2663 8.10164 12.5217 8.28994 12.71L11.2899 15.71C11.3834 15.8027 11.4942 15.876 11.616 15.9258C11.7379 15.9755 11.8683 16.0008 11.9999 16C12.1368 15.9957 12.2714 15.9632 12.3952 15.9047C12.5191 15.8463 12.6296 15.763 12.7199 15.66L19.7199 7.65999C19.8816 7.45932 19.9595 7.204 19.9375 6.94728C19.9155 6.69055 19.7952 6.45223 19.6018 6.282C19.4083 6.11178 19.1567 6.02279 18.8992 6.0336C18.6418 6.0444 18.3984 6.15416 18.2199 6.33999L11.9999 13.54L9.70994 11.29Z"
          fill={color}
        />
        <path
          d="M21.0001 11C20.7348 11 20.4805 11.1054 20.2929 11.2929C20.1054 11.4804 20.0001 11.7348 20.0001 12C20.0001 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1218 20 12.0001 20C10.4201 19.9993 8.87582 19.5308 7.56182 18.6535C6.24782 17.7763 5.22298 16.5297 4.61654 15.0708C4.0101 13.6119 3.8492 12.0061 4.15415 10.4559C4.45909 8.90569 5.21622 7.48048 6.33006 6.36C7.07076 5.60938 7.95374 5.01404 8.92731 4.60881C9.90089 4.20358 10.9455 3.99661 12.0001 4C12.6395 4.004 13.2766 4.07777 13.9001 4.22C14.0305 4.26035 14.1679 4.27356 14.3036 4.2588C14.4394 4.24404 14.5707 4.20163 14.6895 4.13418C14.8082 4.06673 14.9119 3.97567 14.9941 3.86662C15.0763 3.75757 15.1353 3.63284 15.1674 3.50011C15.1996 3.36739 15.2042 3.22948 15.181 3.0949C15.1579 2.96032 15.1074 2.83191 15.0326 2.71759C14.9579 2.60327 14.8606 2.50547 14.7466 2.43021C14.6327 2.35495 14.5045 2.30383 14.3701 2.28C13.5932 2.09724 12.7981 2.00331 12.0001 2C10.0244 2.01026 8.09604 2.60552 6.45835 3.71065C4.82066 4.81578 3.54703 6.38126 2.79818 8.20953C2.04932 10.0378 1.85882 12.0469 2.25069 13.9834C2.64257 15.9198 3.59927 17.6968 5.00006 19.09C6.85675 20.9475 9.37371 21.9939 12.0001 22C14.6522 22 17.1958 20.9464 19.0711 19.0711C20.9465 17.1957 22.0001 14.6522 22.0001 12C22.0001 11.7348 21.8947 11.4804 21.7072 11.2929C21.5196 11.1054 21.2653 11 21.0001 11Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineCheckCircle.defaultProps = {
  id: 'icon-outline-check-circle',
};

export default IconOutlineCheckCircle;
