import cdnImageLoader from '@/lib/loaders/cdnImageLoader';
import Image, { ImageProps } from 'next/image';

/**
 * CdnImage An image from the CDN
 *
 * @param {ImageProps} props - The props for the CdnImage component
 * @returns {React.FC<ImageProps>} Component
 */
const CdnImage: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Image
      data-testid={'cdn-image'}
      alt={'cdn image'}
      loader={cdnImageLoader}
      {...props}
    />
  );
};

export default CdnImage;
