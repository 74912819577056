import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color}
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineArrowUpToLine: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-arrow-up-to-line"
    >
      <rect x="5" y="2" width="14" height="2" rx="1" fill={color} />
      <path
        fill={color}
        d="M5.23176 12.637C5.31571 12.7383 5.41881 12.822 5.53516 12.8834C5.6515 12.9447 5.77881 12.9825 5.90979 12.9946C6.04077 13.0067 6.17284 12.9928 6.29844 12.9537C6.42405 12.9147 6.54071 12.8512 6.64176 12.767L11.0018 9.13705V20.997C11.0018 21.2623 11.1071 21.5166 11.2947 21.7042C11.4822 21.8917 11.7365 21.997 12.0018 21.997C12.267 21.997 12.5213 21.8917 12.7089 21.7042C12.8964 21.5166 13.0018 21.2623 13.0018 20.997V9.13705L17.3618 12.767C17.4629 12.8511 17.5796 12.9144 17.7051 12.9534C17.8307 12.9923 17.9628 13.0061 18.0937 12.9941C18.2246 12.982 18.3519 12.9443 18.4682 12.883C18.5846 12.8217 18.6877 12.7382 18.7718 12.637C18.8558 12.5359 18.9191 12.4192 18.9581 12.2937C18.997 12.1681 19.0109 12.0361 18.9988 11.9051C18.9867 11.7742 18.949 11.6469 18.8877 11.5306C18.8265 11.4142 18.7429 11.3111 18.6418 11.227L12.6418 6.22705L12.4918 6.13705L12.3618 6.06705C12.1301 5.97765 11.8734 5.97765 11.6418 6.06705L11.5118 6.13705L11.3618 6.22705L5.36176 11.227C5.2605 11.311 5.1768 11.4141 5.11545 11.5304C5.0541 11.6468 5.0163 11.7741 5.00422 11.9051C4.99215 12.0361 5.00603 12.1681 5.04507 12.2937C5.08412 12.4193 5.14756 12.536 5.23176 12.637Z"
      />
    </svg>
  );
};

export default IconOutlineArrowUpToLine;
