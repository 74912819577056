import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineGlobeLarge: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-globe-large"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0209 2C17.5324 2 22 6.5 22 12C22 17.5417 17.5324 22 12.0209 22C6.46764 22 2 17.5417 2 12C2 6.5 6.46764 2 12.0209 2ZM20.7056 12C20.7056 11.0833 20.5386 10.2083 20.2463 9.33333H17.1566C17.2818 10.2083 17.3653 11.0833 17.3653 12C17.3653 12.9583 17.2818 13.8333 17.1566 14.6667H20.2463C20.5386 13.8333 20.7056 12.9583 20.7056 12ZM12.0209 20.6667C13.357 20.6667 14.7766 18.875 15.4864 16H8.47182C9.22338 18.875 10.643 20.6667 12.0209 20.6667ZM8.17954 14.6667H15.8205C15.9457 13.875 16.0292 12.9583 16.0292 12C16.0292 11.0833 15.9457 10.1667 15.8205 9.33333H8.17954C8.05428 10.1667 8.01253 11.0833 8.01253 12C8.01253 12.9583 8.05428 13.875 8.17954 14.6667ZM3.33612 12C3.33612 12.9583 3.46138 13.8333 3.75365 14.6667H6.84342C6.71816 13.8333 6.63466 12.9583 6.63466 12C6.63466 11.0833 6.71816 10.2083 6.84342 9.33333H3.75365C3.46138 10.2083 3.33612 11.0833 3.33612 12ZM12.0209 3.33333C10.643 3.33333 9.22338 5.16667 8.47182 8H15.5282C14.7766 5.16667 13.357 3.33333 12.0209 3.33333ZM19.7036 8C18.7432 6.16667 17.1148 4.70833 15.1524 3.95833C15.904 5 16.5303 6.375 16.9061 8H19.7036ZM8.8476 3.95833C6.88518 4.70833 5.25679 6.16667 4.29645 8H7.09395C7.46973 6.375 8.09603 5 8.8476 3.95833ZM4.29645 16C5.25679 17.875 6.88518 19.3333 8.8476 20.0833C8.09603 19.0417 7.46973 17.6667 7.09395 16H4.29645ZM15.1524 20.0833C17.1148 19.3333 18.7432 17.875 19.7036 16H16.9061C16.5303 17.6667 15.904 19.0417 15.1524 20.0833Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineGlobeLarge;
