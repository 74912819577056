import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCircleSpinner: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-circle-spinner"
    >
      <path
        d="M18.1078 11.8911C18.1078 15.2796 15.3636 18 11.9989 18C8.61037 18 5.89 15.2796 5.89 11.8911C5.89 9.17072 7.72744 6.7367 10.3524 6.02081C10.5671 5.94922 10.7819 6.06854 10.8296 6.2833C10.8774 6.47421 10.758 6.68897 10.5671 6.76056C8.25243 7.381 6.65361 9.5048 6.65361 11.8911C6.65361 14.8501 9.03991 17.2364 11.9989 17.2364C14.934 17.2364 17.3442 14.8501 17.3442 11.8911C17.3442 9.5048 15.7215 7.381 13.4068 6.76056C13.2159 6.71284 13.0966 6.49807 13.1443 6.30717C13.1921 6.0924 13.4068 5.97309 13.6216 6.04468C16.2465 6.7367 18.1078 9.17072 18.1078 11.8911Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineCircleSpinner;
