import { PetStatus } from '@/lib/constants/types/analytics';
import { UserPetListingType } from '@/lib/constants/types/pets';

/**
 * The brand to use for Google Tag Manager
 *
 * @constant {string} googleTagManagerBrand
 */
export const googleTagManagerBrand = 'Lost';

/**
 * Map the pet listing type to the pet status for the event
 *
 * @type {Record<
 *   'foundUserPet' | 'lostUserPet' | 'safeAtHomePet',
 *   PetStatus
 * >}
 */
export const petListingTypeToPetStatusMap: Record<
  UserPetListingType,
  PetStatus
> = {
  foundUserPet: 'Found',
  lostUserPet: 'Lost',
  safeAtHome: 'Safe at Home',
};

/**
 * The flow type
 *
 * @typedef {string} FlowType
 */
export type FlowType = 'Lost' | 'Found';

/**
 * Map the pet status to the flow type
 *
 * @type {Record<string, FlowType>}
 */
export const typeToFlow: Record<string, FlowType> = {
  found: 'Found',
  lost: 'Lost',
};

/**
 * String to send when a value is not applicable
 *
 * @constant {string} notApplicableString
 */
export const notApplicableString = 'NA';
