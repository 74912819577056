import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineLink: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-link"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.716 5.3125C19.8397 4.4375 18.7131 4 17.5864 4C16.4597 4 15.3331 4.4375 14.4568 5.3125L13.111 6.65625C13.5805 6.90625 14.0186 7.21875 14.4255 7.59375C14.5194 7.6875 14.6133 7.8125 14.6758 7.90625L15.8651 6.71875C16.3346 6.28125 16.9292 6 17.5864 6C18.2436 6 18.8383 6.28125 19.3077 6.71875C19.7458 7.1875 19.9962 7.78125 19.9962 8.4375C19.9962 9.09375 19.7458 9.6875 19.3077 10.1562L15.1453 14.3125C14.6758 14.75 14.0812 15 13.424 15C12.7668 15 12.1721 14.75 11.7027 14.3125C11.2646 13.8438 10.9829 13.25 10.9829 12.5938C10.9829 11.9375 11.2333 11.3438 11.6714 10.875L11.8279 10.7812C11.7653 10.6562 11.6714 10.5312 11.5775 10.4375C11.3271 10.1562 10.9516 10 10.576 10C10.2005 10 9.85622 10.1562 9.57456 10.4062C8.63567 12.0938 8.85474 14.2812 10.2944 15.7188C11.1707 16.5938 12.2973 17 13.424 17C14.5507 17 15.6773 16.5938 16.5536 15.7188L20.716 11.5625C21.6236 10.6562 22.0305 9.5 21.9992 8.3125C21.9992 7.21875 21.561 6.15625 20.716 5.3125ZM9.32419 16.125L8.13493 17.3125C7.66548 17.75 7.07085 18 6.41363 18C5.75641 18 5.16178 17.75 4.69234 17.3125C4.25419 16.8438 4.00382 16.25 4.00382 15.5938C4.00382 14.9375 4.25419 14.3438 4.69234 13.875L8.85474 9.71875C9.32419 9.25 9.91881 9 10.576 9C11.2646 9 11.8279 9.28125 12.2973 9.71875C12.7355 10.1875 12.9859 10.7812 12.9859 11.4375C12.9859 12.0938 12.7355 12.6875 12.2973 13.1562L12.1721 13.25C12.2347 13.375 12.3286 13.5 12.4225 13.5938C12.6729 13.875 13.0484 14 13.424 14C13.7996 14 14.1438 13.875 14.4255 13.625C15.3644 11.9375 15.1453 9.75 13.7057 8.3125C12.8294 7.4375 11.7027 7 10.576 7C9.44937 7 8.3227 7.4375 7.44641 8.3125L3.284 12.4688C2.40771 13.3438 2.00085 14.4688 2.00085 15.5938C2.00085 16.7188 2.40771 17.875 3.284 18.7188C4.1603 19.5938 5.28696 20 6.41363 20C7.5403 20 8.66696 19.5938 9.54326 18.7188L10.889 17.375C10.4196 17.125 9.98141 16.8125 9.57456 16.4375C9.48067 16.3438 9.38678 16.2188 9.32419 16.125Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineLink;
