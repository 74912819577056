import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlinePhone: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-phone"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9358 14.9186L16.9452 13.1972C16.241 12.9233 15.3803 13.1189 14.9108 13.7449L13.5806 15.3099C11.5462 14.1753 9.82473 12.4538 8.69014 10.4194L10.2942 9.12833C10.8811 8.61972 11.0767 7.759 10.7637 7.05477L9.08138 3.06416C8.72926 2.28169 7.86854 1.85133 7.04695 2.04695L3.36933 2.90767C2.54773 3.06416 2 3.76839 2 4.62911C2 14.2144 9.7856 22 19.3709 22C20.2316 22 20.9358 21.4523 21.1315 20.6307L21.9531 16.9531C22.1487 16.1315 21.7183 15.2707 20.9358 14.9186ZM19.8795 20.3568C19.8404 20.5915 19.6448 20.7872 19.3709 20.7872C10.4898 20.7872 3.25196 13.5102 3.25196 4.62911C3.25196 4.35524 3.40845 4.15963 3.64319 4.1205L7.32081 3.25978C7.35994 3.25978 7.39906 3.22066 7.43818 3.22066C7.6338 3.22066 7.82942 3.37715 7.94679 3.57277L9.62911 7.52426C9.70735 7.759 9.66823 7.99374 9.47261 8.15024L7.51643 9.75431C7.28169 9.94992 7.20344 10.2629 7.35994 10.5368C8.65102 13.1581 10.8419 15.349 13.5023 16.6401C13.7371 16.7966 14.0501 16.7183 14.2457 16.4836L15.8498 14.5274C16.0063 14.3318 16.241 14.2926 16.4757 14.3709L20.4272 16.0532C20.662 16.1706 20.8185 16.4053 20.7402 16.6792L19.8795 20.3568Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlinePhone;
