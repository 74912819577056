import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineBell: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-bell"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1895 15.0859C19.0975 14.0703 18.2786 12.9766 18.2786 9.26562C18.2786 6.17969 15.7827 3.64062 12.663 3.32812V2.625C12.663 2.3125 12.351 2 12.039 2C11.688 2 11.415 2.3125 11.415 2.625V3.32812C8.25625 3.64062 5.76041 6.17969 5.76041 9.26562C5.76041 12.9766 4.90247 14.0703 3.81054 15.0859C3.26458 15.6328 3.10859 16.4141 3.38157 17.1172C3.69355 17.8203 4.3955 18.25 5.13645 18.25H18.8635C19.6435 18.25 20.3454 17.8203 20.6184 17.1172C20.8914 16.4141 20.7354 15.6328 20.1895 15.0859ZM18.9025 17H5.13645C4.59049 17 4.31751 16.375 4.70748 16.0234C6.07239 14.7344 7.00833 13.2891 7.00833 9.30469C7.00833 6.64844 9.27018 4.5 12.039 4.5C14.7688 4.5 17.0307 6.64844 17.0307 9.26562C17.0307 13.25 17.9666 14.6953 19.3315 15.9844C19.7215 16.375 19.4485 17 18.9025 17ZM13.9109 19.5C13.5989 19.5 13.3259 19.6953 13.2479 19.9297C13.0529 20.4375 12.585 20.75 12 20.75C11.376 20.75 10.9471 20.4375 10.7521 19.9297C10.7131 19.6953 10.4401 19.5 10.1281 19.5C9.73815 19.5 9.42617 19.8906 9.54316 20.2812C9.89414 21.2969 10.8691 22 12 22C13.1309 22 14.1059 21.2969 14.4568 20.2812C14.6128 19.8906 14.3008 19.5 13.9109 19.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineBell;
