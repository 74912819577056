import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineLinkedIn: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-social-media-linkedin"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0704 2H3.88291C3.12398 2 2.49898 2.66964 2.49898 3.47321V20.5714C2.49898 21.375 3.12398 22 3.88291 22H21.0704C21.8293 22 22.499 21.375 22.499 20.5714V3.47321C22.499 2.66964 21.8293 2 21.0704 2ZM8.52576 19.1429H5.57933V9.63393H8.52576V19.1429ZM7.05255 8.29464C6.07041 8.29464 5.31148 7.53571 5.31148 6.59821C5.31148 5.66071 6.07041 4.85714 7.05255 4.85714C7.99005 4.85714 8.74898 5.66071 8.74898 6.59821C8.74898 7.53571 7.99005 8.29464 7.05255 8.29464ZM19.6418 19.1429H16.6508V14.5C16.6508 13.4286 16.6508 12 15.1329 12C13.5704 12 13.3472 13.2054 13.3472 14.4554V19.1429H10.4008V9.63393H13.2133V10.9286H13.2579C13.6597 10.1696 14.6418 9.36607 16.0704 9.36607C19.0615 9.36607 19.6418 11.375 19.6418 13.9196V19.1429Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineLinkedIn;
