import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineChevronLeft: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-chevron-left"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M14.8664 7.09787C14.9733 7.16487 15 7.2542 15 7.34353C15 7.43287 14.9733 7.5222 14.8931 7.5892L10.029 11.9888L14.8664 16.4108C15.0267 16.5448 15.0267 16.7681 14.8396 16.9021C14.6793 17.0361 14.412 17.0361 14.2517 16.8798L9.12027 12.2345C8.95991 12.1005 8.95991 11.8995 9.12027 11.7655L14.2517 7.1202C14.4388 6.96387 14.706 6.96387 14.8664 7.09787Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineChevronLeft.defaultProps = {
  id: 'icon-outline-chevron-left',
};

export default IconOutlineChevronLeft;
