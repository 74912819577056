import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineStar: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-star"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9716 9.03509L15.5252 8.29825L13.0632 3.63158C12.8393 3.21053 12.429 3 11.9813 3C11.571 3 11.1607 3.21053 10.9368 3.63158L8.47479 8.29825L3.02843 9.03509C2.05853 9.17544 1.64818 10.2982 2.35696 10.9649L6.31116 14.5439L5.37857 19.6667C5.26666 20.4035 5.86352 20.9649 6.57229 20.9649C6.75881 20.9649 6.94533 20.9298 7.13185 20.8596L12.0187 18.4386L16.8682 20.8596C17.0547 20.9298 17.2412 21 17.4277 21C18.1365 21 18.7333 20.4035 18.6214 19.6667L17.6888 14.5789L21.643 11C22.3518 10.2982 21.9415 9.17544 20.9716 9.03509ZM16.4578 14.4737L17.4277 19.8421L12.2798 17.3158C12.0933 17.2105 11.9067 17.2105 11.7202 17.3158L6.57229 19.8421L7.6168 14.1579L3.21495 10.1579L8.95974 9.35088C9.14626 9.31579 9.33278 9.21053 9.40738 9.03509L12.0187 4.12281L14.5926 9.03509C14.6672 9.21053 14.8537 9.31579 15.0403 9.35088L20.7851 10.1579L16.6443 13.9825C16.4951 14.0877 16.4205 14.2632 16.4578 14.4737Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineStar;
