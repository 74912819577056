import IIconType from '../IIconType';

/**
 * IconOutlineQuote
 *
 * The outline icon of a quotation mark
 *
 * @param {IIconType} props - The props for the IconOutlineQuote component
 * @returns {React.FC<IIconType>} IconOutlineQuote Component
 */
const IconOutlineQuote: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-quote"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 26.6618C34.5 23.4265 32.3432 21.2426 29.1479 21.2426C28.3491 21.2426 27.7101 21.5662 27.5503 21.6471C27.7101 18.1691 30.5858 15.0147 33.7811 14.4485V10C29.068 10.4853 22.2781 14.5294 22.2781 24.6397C22.2781 29.0882 24.9941 32 28.6686 32C32.0237 32 34.5 29.5735 34.5 26.6618ZM19.7219 26.6618C19.7219 23.4265 17.4852 21.2426 14.3698 21.2426C13.571 21.2426 12.8521 21.5662 12.6923 21.6471C12.8521 18.1691 15.7278 15.0147 19.003 14.4485V10C14.2101 10.4853 7.5 14.5294 7.5 24.6397C7.5 29.0882 10.1361 32 13.8905 32C17.2456 32 19.7219 29.5735 19.7219 26.6618Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineQuote;
