import Button, { IButton } from '@/components/atoms/Button/Button';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import useWindowSize from '@/lib/hooks/windowSize/WindowSize';
import { useEffect, useState } from 'react';

/**
 * ICalloutWithButtons
 *
 * @property {string} title - The title text to display.
 * @property {string} description - The description text to display underneath
 *   the title.
 * @property {IButton[]} buttons - The buttons to display in the callout.
 * @property {string} classes - The classes to apply to the callout.
 * @returns ICalloutWithButtons Interface
 * @interface
 */
export interface ICalloutWithButtons {
  /** The title text to display. */
  title: string | JSX.Element;
  /** The description text to display underneath the title. */
  description: string | JSX.Element;
  /** The buttons to display in the callout. */
  buttons: IButton[];
  /**
   * The optional classes to apply to the callout.
   *
   * @default ''
   */
  classes?: string;
}
/**
 * CalloutWithButtons A Callout that allows input of multiple buttons
 *
 * @param {ICalloutWithButtons} props - The props for the CalloutWithButtons
 * @returns {React.FC<ICalloutWithButtons>} CalloutWithButtons Component
 */
const CalloutWithButtons: React.FC<ICalloutWithButtons> = ({
  title,
  description,
  buttons,
  classes,
}) => {
  const size = useWindowSize();
  const [fullWidthButton, setFullWidthButton] = useState(false);

  /**
   * Monitor the Breakpoint that is being used and set the fullWidthButtons
   * state
   */
  useEffect(() => {
    if (size.breakpoint === 'xs') {
      setFullWidthButton(true);
    } else {
      setFullWidthButton(false);
    }
  }, [size.breakpoint]);

  return (
    <div
      data-testid="callout-with-buttons"
      className={`text-center mx-auto max-w-[327px] sm:max-w-[446px] md:max-w-[664px] ${classes}`}
    >
      <Heading size="h2" className="mb-4">
        {title}
      </Heading>
      <Paragraph className="mb-8" size="body2" font="petco">
        {description}
      </Paragraph>
      <div className="space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {buttons &&
          buttons.map((button, index) => {
            return (
              <Button key={index} {...button} fullWidth={fullWidthButton} />
            );
          })}
      </div>
    </div>
  );
};

CalloutWithButtons.defaultProps = {
  classes: '',
};

export default CalloutWithButtons;
