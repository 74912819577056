import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPartyPopperOutlined: React.FC<IIconType> = ({
  size,
  color,
  id,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-party-popper-outlined"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.2 36.1333C40.8889 36.1333 40.6222 36.0445 40.3556 35.8222C39.7778 35.3778 39.6889 34.5333 40.1778 33.9556L55.3333 15.3333C55.7778 14.7556 56.6222 14.6667 57.2 15.1556C57.7778 15.6 57.8667 16.4445 57.3778 17.0222L42.2222 35.6445C41.9556 36 41.5556 36.1333 41.2 36.1333Z"
        fill={color}
      />
      <path
        d="M48.3556 42C48 42 47.6444 41.8667 47.3778 41.6C46.8889 41.0667 46.8889 40.2222 47.4222 39.7333C47.5555 39.6445 58.8 29.2 71.7333 35.4222C72.4 35.7333 72.6667 36.5333 72.3556 37.2C72.0444 37.8667 71.2444 38.1333 70.5778 37.8222C59.2889 32.4 49.6444 41.2889 49.2444 41.6889C49.0222 41.8667 48.6667 42 48.3556 42Z"
        fill={color}
      />
      <path
        d="M34.0444 30.3111C33.8222 30.3111 33.5555 30.2667 33.3333 30.1333C32.7111 29.7333 32.5333 28.9333 32.8889 28.3111C33.2 27.8222 39.9111 16.5778 32.3111 6.62223C31.8667 6.04445 31.9555 5.20001 32.5778 4.75556C33.1555 4.31112 34 4.40001 34.4444 5.02223C43.1555 16.4445 35.2444 29.6 35.1555 29.7333C34.8889 30.0889 34.4889 30.3111 34.0444 30.3111Z"
        fill={color}
      />
      <path
        d="M61.9111 29.7778C61.4222 29.7778 61.0222 29.6889 60.7111 29.6445C60.6222 29.6445 58.9333 29.2889 58.4444 28.6667C57.9555 28.0445 58.0444 26.2667 58.0444 26.2667C58.0889 25.6 58.1333 24.1778 59.3778 23.2C60.6667 22.1778 62.1778 22.5333 62.8889 23.4667C63.2444 23.9111 63.3333 24.4 63.3778 24.8445C63.7778 24.9778 64.2222 25.2 64.5778 25.6445C65.2889 26.5778 65.2889 28.1333 64 29.1111C63.2889 29.6445 62.5333 29.7778 61.9111 29.7778ZM61.0667 27.9111C61.8667 28.0889 62.4 28.0889 62.8889 27.6889C63.3333 27.3333 63.3333 26.8889 63.2 26.7111C63.0222 26.4889 62.5778 26.4889 62.4889 26.4889C62.1778 26.5333 61.8667 26.4 61.6889 26.1333C61.5111 25.8667 61.4667 25.5556 61.5555 25.2445C61.5555 25.2445 61.7333 24.7556 61.5111 24.5333C61.3778 24.3556 60.9333 24.2222 60.4889 24.5778C60 24.9778 59.8667 25.5111 59.8222 26.3111C59.8222 26.8 59.8667 27.2445 59.9111 27.5111C60.1778 27.6445 60.6222 27.8222 61.0667 27.9111Z"
        fill={color}
      />
      <path
        d="M58.0445 48.6667C56.8 48.6667 55.9556 47.9556 55.4667 47.6C55.4222 47.5556 54.0445 46.4444 53.9111 45.6889C53.7778 44.9333 54.6667 43.3778 54.6667 43.3778C55.0222 42.8 55.6889 41.5556 57.2445 41.2889C57.9556 41.1556 58.6667 41.2889 59.2445 41.6444C59.7778 42 60.1778 42.5333 60.2667 43.1111C60.3556 43.6889 60.2667 44.1778 60.0445 44.5333C60.3556 44.8444 60.6667 45.2444 60.7556 45.7778C60.9778 46.9333 60.2222 48.3111 58.6222 48.5778C58.4444 48.6667 58.2667 48.6667 58.0445 48.6667ZM55.7333 45.3778C55.9111 45.6 56.2222 45.9111 56.5778 46.2222C57.2 46.7111 57.6889 46.9778 58.3111 46.8889C58.8889 46.8 59.0667 46.4 59.0222 46.1333C58.9778 45.8667 58.5778 45.6444 58.4889 45.6C58.1778 45.4667 58 45.2444 57.9111 44.9333C57.8667 44.6222 57.9556 44.3111 58.2222 44.0889C58.2222 44.0889 58.5778 43.7333 58.5333 43.4222C58.5333 43.3333 58.4445 43.2 58.2667 43.1111C58.1333 43.0222 57.9111 42.9333 57.5556 43.0222C56.9333 43.1556 56.5778 43.5556 56.1778 44.2667C56 44.6667 55.8222 45.1111 55.7333 45.3778Z"
        fill={color}
      />
      <path
        d="M28.7111 25.2C27.9556 25.2 26.6222 24.1333 26.5333 24.0889C26 23.6444 24.9333 22.7556 24.8889 21.2C24.8444 19.5556 26.0444 18.5778 27.2 18.5333C27.7778 18.5333 28.2222 18.7111 28.5778 18.9778C28.9333 18.7111 29.3778 18.4889 29.9556 18.4889C31.1111 18.4889 32.3556 19.4222 32.3556 21.0667C32.4 22.6222 31.3333 23.5556 30.8 24.0444C30.8444 24 29.5111 25.2 28.7111 25.2ZM27.2889 20.2667C27.0222 20.2667 26.7111 20.5333 26.7111 21.1111C26.7111 21.7333 27.0667 22.1778 27.6889 22.6667C28.0444 22.9778 28.4444 23.2 28.7111 23.3333C28.9333 23.2 29.3333 22.9333 29.6889 22.6222C30.3111 22.0889 30.6222 21.6444 30.6222 21.0222C30.6222 20.4444 30.2667 20.1778 30 20.1778C29.6889 20.1778 29.4222 20.6222 29.4222 20.6222C29.2444 20.8889 28.9778 21.0667 28.6667 21.0667C28.3556 21.0667 28.0444 20.9333 27.9111 20.6667C27.8222 20.5778 27.5111 20.2667 27.2889 20.2667Z"
        fill={color}
      />
      <path
        d="M44.4445 16.7556C44.1333 16.7556 43.8667 16.7111 43.6445 16.6222C42.9333 16.2667 42.2667 14.6667 42.2222 14.6222C41.9556 14 41.4222 12.7111 42.0889 11.2889C42.4 10.6222 42.9333 10.1333 43.5556 9.91111C44.1778 9.68889 44.8 9.73334 45.3778 10C45.8667 10.2667 46.1778 10.6222 46.4 11.0222C46.8 10.9333 47.3333 10.9778 47.8222 11.2C48.8889 11.7333 49.5111 13.1556 48.8 14.6222C48.0889 16.0444 46.7111 16.3556 46.0889 16.5333C46.1333 16.5333 45.2445 16.7556 44.4445 16.7556ZM44.4445 11.5556C44.4 11.5556 44.3111 11.5556 44.2222 11.6C44.0889 11.6444 43.8667 11.7778 43.7333 12.0889C43.4667 12.6667 43.6 13.2 43.9111 13.9556C44.0889 14.4 44.3556 14.8 44.4889 15.0222C44.7556 15.0222 45.2445 14.9778 45.6889 14.8444C46.4889 14.6667 46.9778 14.4444 47.2445 13.8667C47.5111 13.3333 47.2889 12.9333 47.0667 12.8444C46.8 12.7111 46.3556 12.9778 46.3556 12.9778C46.0889 13.1556 45.7778 13.1556 45.4667 13.0222C45.1556 12.8889 44.9778 12.6222 44.9778 12.3111C44.9778 12.1333 44.8445 11.7778 44.6222 11.6444C44.6222 11.6 44.5333 11.5556 44.4445 11.5556Z"
        fill={color}
      />
      <path
        d="M42.6222 61.2444C42.3111 61.2444 42 61.1556 41.7778 60.9333L15.6 38.8889C15.2 38.5778 15.0667 38.0444 15.1556 37.6L17.2889 28C17.5111 26.9333 18.2667 26.0889 19.2889 25.7333C20.3111 25.3778 21.4222 25.5556 22.2667 26.2667L53.3333 51.5556C54.1778 52.2222 54.5778 53.2889 54.4445 54.3556C54.3111 55.4222 53.6445 56.3556 52.6222 56.8L43.1111 61.1111C43.0222 61.2 42.8 61.2444 42.6222 61.2444ZM17.9556 37.3778L42.8445 58.3556L51.6 54.4C51.7778 54.3111 51.8667 54.1333 51.8667 54.0444C51.8667 53.9556 51.8667 53.7778 51.6889 53.6444L20.6222 28.3556C20.4445 28.2222 20.2667 28.2667 20.1778 28.2667C20.0889 28.3111 19.9556 28.4 19.9111 28.5778L17.9556 37.3778Z"
        fill={color}
      />
      <path
        d="M31.5555 66.2222C31.2444 66.2222 30.9333 66.1333 30.7111 65.9111L12.9333 50.9333C12.5333 50.6222 12.4 50.0889 12.4889 49.6444L13.9555 43.1111C14.0444 42.6667 14.4 42.2667 14.8444 42.1333C15.2889 42 15.7778 42.0889 16.1333 42.3556L38.4444 61.1556C38.8 61.4667 38.9778 61.9111 38.8889 62.3556C38.8444 62.8 38.5333 63.2 38.1333 63.3778L32.1333 66.0889C31.9111 66.1778 31.7333 66.2222 31.5555 66.2222ZM15.2444 49.4222L31.7778 63.3333L35.0667 61.8222L16.0444 45.8222L15.2444 49.4222Z"
        fill={color}
      />
      <path
        d="M10.5778 75.5556C9.86667 75.5556 9.2 75.3333 8.62222 74.8444C7.68889 74.0889 7.28889 72.9333 7.55556 71.7778L11.3333 54.8C11.4222 54.3556 11.7778 53.9556 12.2222 53.8222C12.6667 53.6889 13.1556 53.7778 13.5111 54.0444L27.7333 66C28.0889 66.3111 28.2667 66.7556 28.1778 67.2C28.1333 67.6444 27.8222 68.0444 27.4222 68.2222L11.9111 75.2444C11.4667 75.4222 11.0222 75.5556 10.5778 75.5556ZM13.4667 57.5111L10.1778 72.3556C10.1333 72.5778 10.2667 72.7111 10.3111 72.8C10.4 72.8444 10.5778 72.9778 10.7556 72.8444L24.3556 66.6667L13.4667 57.5111Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationPartyPopperOutlined.defaultProps = {
  id: 'illustration-party-popper-outlined',
};

export default IllustrationPartyPopperOutlined;
