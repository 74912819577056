import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Icon, { IIcon } from '@/components/atoms/Icon/Icon';

/**
 * ILoadingSpinner - Interface for LoadingSpinner component
 *
 * @interface
 */
export interface ILoadingSpinner {
  /**
   * The optional classes for the component.
   *
   * @default ''
   */
  classes?: string;
  /**
   * The optional size of the spinner.
   *
   * @default 32
   */
  size?: number;
  /** The optional icon to be used in the spinner. */
  icon?: IIcon;
}

/**
 * Loading Spinner Spinning Loading SVG to be used when loading data.
 *
 * @param {ILoadingSpinner} props - The props for the LoadingSpinner component
 * @returns {React.FC<ILoadingSpinner>} Loading Spinner Component
 */
const LoadingSpinner: React.FC<ILoadingSpinner> = ({ classes, size, icon }) => {
  return (
    <div data-testid="loading-spinner" className={classes}>
      {icon ? (
        <Icon {...icon} classes="animate-spin-loader" />
      ) : (
        <CdnImage
          className="animate-spin-loader"
          src={process.env.NEXT_PUBLIC_CDN_BASE_URL + '/assets/lost/load.svg'}
          alt="loading"
          layout="fixed"
          width={size}
          height={size}
        />
      )}
    </div>
  );
};

LoadingSpinner.defaultProps = {
  classes: '',
  size: 32,
};
export default LoadingSpinner;
