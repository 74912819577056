import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineUpload: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-upload"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1719 8.83865C12.0688 8.73666 11.8969 8.73666 11.7938 8.83865L8.08125 12.5104C8.0125 12.5443 8.0125 12.6123 8.0125 12.6803C8.0125 12.7823 8.0125 12.8503 8.08125 12.8843C8.18438 12.9863 8.35625 12.9863 8.45938 12.8843L11.725 9.68858V17.168C11.725 17.338 11.8281 17.44 12 17.44C12.1375 17.44 12.275 17.338 12.275 17.168V9.68858L15.4719 12.8503C15.575 12.9523 15.7469 12.9523 15.85 12.8503C15.9531 12.7483 15.9531 12.5783 15.85 12.4764L12.1719 8.83865ZM19.4594 11.0145C19.5969 10.6405 19.7 10.2665 19.7 9.82457C19.7 8.02272 18.2219 6.56083 16.4 6.56083C15.7125 6.56083 15.0594 6.76482 14.5438 7.13879C13.6156 5.50692 11.8281 4.38501 9.8 4.38501C6.74063 4.38501 4.3 6.83281 4.3 9.82457C4.3 9.92656 4.3 10.0286 4.3 10.1305C2.375 10.7765 1 12.6123 1 14.7202C1 17.44 3.2 19.6158 5.95 19.6158H18.6C21.0063 19.6158 23 17.6779 23 15.2641C23 13.1903 21.4875 11.4224 19.4594 11.0145ZM18.6 19.0718H5.95C3.50938 19.0718 1.55 17.134 1.55 14.7202C1.55 12.8843 2.71875 11.2525 4.47188 10.6405L4.85 10.5045C4.85 10.5045 4.85 9.92656 4.85 9.82457C4.85 7.13879 7.05 4.92897 9.8 4.92897C11.5531 4.92897 13.2031 5.88089 14.0625 7.41076L14.3719 7.92072L14.8531 7.58075C15.3 7.27478 15.85 7.10479 16.4 7.10479C17.9125 7.10479 19.15 8.32869 19.15 9.82457C19.15 10.1645 19.0813 10.5045 18.9438 10.8105L18.7031 11.4224L19.3563 11.5584C21.1438 11.8984 22.45 13.4623 22.45 15.2641C22.45 17.372 20.6969 19.0718 18.6 19.0718Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineUpload;
