import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineSignPost: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-sign-post"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M19.2461 9.5C19.5586 9.5 19.9102 9.34375 20.1445 9.07031L21.8242 6.96094C22.0586 6.64844 22.0586 6.14062 21.8242 5.82812L20.1445 3.71875C19.8711 3.44531 19.5586 3.25 19.2461 3.25H12.6055V2.625C12.6055 2.3125 12.332 2 11.9805 2C11.5898 2 11.3555 2.3125 11.3555 2.625V3.25H4.16797C3.66016 3.25 3.26953 3.79688 3.26953 4.42188V8.32812C3.23047 8.99219 3.66016 9.5 4.16797 9.5H11.3555V10.75H4.71484C4.44141 10.75 4.08984 10.9453 3.85547 11.2188L2.17578 13.3281C1.94141 13.6406 1.94141 14.1484 2.17578 14.4609L3.85547 16.5703C4.08984 16.8438 4.44141 17 4.75391 17H11.3555V21.375C11.3555 21.7266 11.668 22 11.9805 22C12.293 22 12.6055 21.7266 12.6055 21.4141V17H19.793C20.3008 17 20.7305 16.4922 20.7305 15.8672V11.9219C20.7305 11.2969 20.3398 10.75 19.793 10.75H12.6055V9.5H19.2461ZM19.4805 15.75H4.83203L3.34766 13.875L4.83203 12H19.4805V15.75ZM4.51953 4.5H19.168L20.6523 6.375L19.168 8.25H4.48047L4.51953 4.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineSignPost.defaultProps = {
  id: 'icon-outline-sign-post',
};

export default IconOutlineSignPost;
