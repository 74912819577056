import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineExclamationTriangle: React.FC<IIconType> = ({
  size,
  color,
  id,
}) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-exclamation-triangle"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M22.56 16.3L14.89 3.58C14.5798 3.09481 14.1525 2.69552 13.6475 2.41894C13.1424 2.14235 12.5758 1.99738 12 1.99738C11.4241 1.99738 10.8576 2.14235 10.3525 2.41894C9.84743 2.69552 9.42012 3.09481 9.10998 3.58L1.43998 16.3C1.16903 16.7517 1.02177 17.2667 1.01299 17.7933C1.00421 18.3199 1.13423 18.8396 1.38998 19.3C1.68566 19.8183 2.11364 20.2487 2.63018 20.5474C3.14672 20.8461 3.7333 21.0023 4.32998 21H19.67C20.2627 21.0063 20.8467 20.8562 21.3629 20.5647C21.879 20.2733 22.3092 19.8508 22.61 19.34C22.8732 18.8748 23.0074 18.3477 22.9986 17.8132C22.9898 17.2787 22.8384 16.7563 22.56 16.3ZM20.86 18.35C20.7398 18.5566 20.5654 18.7264 20.3556 18.8409C20.1459 18.9555 19.9088 19.0105 19.67 19H4.32998C4.0912 19.0105 3.85409 18.9555 3.64434 18.8409C3.43458 18.7264 3.26016 18.5566 3.13998 18.35C3.05221 18.198 3.006 18.0255 3.006 17.85C3.006 17.6745 3.05221 17.502 3.13998 17.35L10.82 4.62C10.958 4.4377 11.1363 4.28986 11.3411 4.18807C11.5458 4.08628 11.7713 4.03331 12 4.03331C12.2286 4.03331 12.4541 4.08628 12.6589 4.18807C12.8636 4.28986 13.042 4.4377 13.18 4.62L20.85 17.34C20.9411 17.4923 20.9901 17.6661 20.9919 17.8436C20.9936 18.0211 20.9481 18.1959 20.86 18.35Z"
          fill={color}
        />
        <path
          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
          fill={color}
        />
        <path
          d="M12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineExclamationTriangle.defaultProps = {
  id: 'icon-outline-exclamation-triangle',
};

export default IconOutlineExclamationTriangle;
