import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationGerm: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-germ"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6889 71.9556C22.0889 71.9556 7.73334 57.6445 7.73334 40C7.73334 22.4 22.0444 8.04445 39.6889 8.04445C57.2889 8.04445 71.6445 22.3556 71.6445 40C71.6 57.6 57.2889 71.9556 39.6889 71.9556ZM39.6889 10.7111C23.5556 10.7111 10.4 23.8667 10.4 40C10.4 56.1333 23.5111 69.2889 39.6889 69.2889C55.8667 69.2889 68.9778 56.1778 68.9778 40C68.9333 23.8667 55.8222 10.7111 39.6889 10.7111Z"
        fill={color}
      />
      <path
        d="M28.7555 36.9333C27.2889 36.9333 24.7111 35.5556 24.6667 35.5556C23.5111 34.9333 21.1111 33.6445 20.4889 30.7111C20.2222 29.3778 20.4 28.0445 21.0667 26.9778C21.6889 25.9556 22.6667 25.2445 23.7333 25.0222C24.8444 24.8 25.8667 25.0667 26.6667 25.5111C27.2 24.8445 28 24.1333 29.1555 23.8667C30.2222 23.6445 31.4222 23.8667 32.4 24.5333C33.4222 25.2445 34.1778 26.3556 34.4444 27.6889C35.0667 30.6222 33.4222 32.7556 32.6222 33.8222C32.6222 33.8667 30.4889 36.5778 29.1111 36.8889C28.9778 36.8889 28.8444 36.9333 28.7555 36.9333ZM24.5333 27.6C24.4444 27.6 24.3555 27.6 24.2667 27.6445C23.9111 27.7333 23.5555 28 23.3333 28.3556C23.1111 28.7111 22.9333 29.2889 23.1111 30.1333C23.4667 31.7333 24.6667 32.4889 25.9556 33.2C26.9333 33.7333 28.0444 34.1333 28.5778 34.2222C28.9778 33.9111 29.7778 33.1556 30.5333 32.2222C31.4222 31.0667 32.1778 29.8667 31.8667 28.2667C31.6889 27.4222 31.2444 26.9778 30.9333 26.7556C30.5778 26.4889 30.1333 26.4 29.7778 26.4889C28.8889 26.6667 28.4444 27.9111 28.4444 27.9556C28.2667 28.4 27.9111 28.7111 27.4222 28.8C26.9778 28.8889 26.4889 28.7556 26.1778 28.4C26.0889 28.3556 25.3333 27.6 24.5333 27.6Z"
        fill={color}
      />
      <path
        d="M56.4889 41.5556C51.9111 41.5556 48.8444 39.3333 46.9778 37.2C44.4444 34.3111 43.5555 31.0222 43.5555 30.8889C43.3778 30.1778 43.7778 29.4667 44.4889 29.2444C45.2 29.0667 45.9111 29.4667 46.1333 30.1778C46.2667 30.6222 49.2 41.2 60.0444 38.3556C60.7555 38.1778 61.4667 38.5778 61.6889 39.2889C61.8667 40 61.4667 40.7111 60.7555 40.9333C59.2 41.3778 57.7778 41.5556 56.4889 41.5556Z"
        fill={color}
      />
      <path
        d="M37.1111 64.2667C36.7556 64.2667 36.3556 64.1333 36.0889 63.8222C25.3778 51.6444 36.0889 41.9555 36.2222 41.8667C36.7556 41.3778 37.6 41.4222 38.0889 42C38.5778 42.5333 38.5333 43.3778 37.9556 43.8667C37.6 44.1778 29.1556 51.9111 38.0889 62.0444C38.5778 62.5778 38.5333 63.4222 37.9556 63.9111C37.7333 64.1778 37.4222 64.2667 37.1111 64.2667Z"
        fill={color}
      />
      <path
        d="M44.8 56.6222C44.6667 56.6222 44.5333 56.6222 44.4 56.5778C43.6889 56.3556 43.3333 55.6 43.5556 54.8889C43.6 54.7556 44.7111 51.4222 48.1778 49.7778C50.8889 48.4889 54.2222 48.5778 58.0444 50.0889C58.7111 50.3556 59.0667 51.1111 58.8 51.8222C58.5333 52.4889 57.7778 52.8444 57.0667 52.5778C53.9556 51.3333 51.3333 51.2444 49.2889 52.1778C46.8889 53.3333 46.0889 55.6889 46.0444 55.7333C45.8667 56.2667 45.3333 56.6222 44.8 56.6222Z"
        fill={color}
      />
      <path
        d="M49.8667 26.3556C49.3333 26.3556 48.8 26 48.6222 25.4667C47.8222 23.2 46.7111 21.7778 45.2444 21.2C43.0222 20.3556 40.5778 21.7778 40.5333 21.7778C39.9111 22.1333 39.0667 21.9556 38.7111 21.2889C38.3556 20.6667 38.5333 19.8222 39.2 19.4667C39.3333 19.3778 42.7111 17.4222 46.1778 18.6667C48.4444 19.5111 50.1333 21.4667 51.2 24.5778C51.4222 25.2889 51.0667 26.0444 50.3556 26.2667C50.1778 26.3111 50.0444 26.3556 49.8667 26.3556Z"
        fill={color}
      />
      <path
        d="M41.0222 10.7111C40.2667 10.7111 39.6889 10.1333 39.6889 9.37778V3.11111C39.6889 2.35556 40.2667 1.77778 41.0222 1.77778C41.7778 1.77778 42.3555 2.35556 42.3555 3.11111V9.37778C42.3555 10.1333 41.7778 10.7111 41.0222 10.7111Z"
        fill={color}
      />
      <path
        d="M41.0222 78.2222C40.2667 78.2222 39.6889 77.6444 39.6889 76.8889V70.6222C39.6889 69.8667 40.2667 69.2889 41.0222 69.2889C41.7778 69.2889 42.3555 69.8667 42.3555 70.6222V76.8889C42.3555 77.6444 41.7778 78.2222 41.0222 78.2222Z"
        fill={color}
      />
      <path
        d="M76.8889 41.3333H70.2667C69.5111 41.3333 68.9333 40.7556 68.9333 40C68.9333 39.2444 69.5111 38.6667 70.2667 38.6667H76.8889C77.6444 38.6667 78.2222 39.2444 78.2222 40C78.2222 40.7556 77.6444 41.3333 76.8889 41.3333Z"
        fill={color}
      />
      <path
        d="M9.06666 41.3333H3.1111C2.35555 41.3333 1.77777 40.7556 1.77777 40C1.77777 39.2444 2.35555 38.6667 3.1111 38.6667H9.06666C9.82222 38.6667 10.4 39.2444 10.4 40C10.4 40.7556 9.82222 41.3333 9.06666 41.3333Z"
        fill={color}
      />
      <path
        d="M62.9778 71.6889C62.5778 71.6889 62.1778 71.5111 61.9111 71.1556L57.2445 65.0667C56.8 64.4889 56.8889 63.6444 57.5111 63.2C58.0889 62.7556 58.9333 62.8444 59.3778 63.4667L64.0445 69.5556C64.4889 70.1333 64.4 70.9778 63.7778 71.4222C63.5556 71.6 63.2445 71.6889 62.9778 71.6889Z"
        fill={color}
      />
      <path
        d="M21.0667 17.0667C20.6667 17.0667 20.2667 16.8889 20 16.5333L15.3333 10.4444C14.8889 9.86667 14.9778 9.02223 15.6 8.57778C16.1778 8.13334 17.0222 8.22223 17.4667 8.84445L22.1333 14.9333C22.5778 15.5111 22.4889 16.3556 21.8667 16.8C21.6 16.9778 21.3333 17.0667 21.0667 17.0667Z"
        fill={color}
      />
      <path
        d="M9.33334 64.6222C8.93334 64.6222 8.53334 64.4444 8.26667 64.0889C7.82222 63.5111 7.91111 62.6667 8.53334 62.2222L14.6222 57.5555C15.2 57.1111 16.0444 57.2 16.4889 57.8222C16.9333 58.4 16.8444 59.2444 16.2222 59.6889L10.1333 64.3555C9.91111 64.5333 9.6 64.6222 9.33334 64.6222Z"
        fill={color}
      />
      <path
        d="M63.9556 22.7111C63.5556 22.7111 63.1556 22.5333 62.8889 22.1778C62.4444 21.6 62.5333 20.7556 63.1556 20.3111L69.2444 15.6444C69.8222 15.2 70.6667 15.2889 71.1111 15.9111C71.5556 16.4889 71.4667 17.3333 70.8444 17.7778L64.7556 22.4444C64.5333 22.6222 64.2222 22.7111 63.9556 22.7111Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationGerm.defaultProps = {
  id: 'illustration-germ',
};

export default IllustrationGerm;
