import IIconType from '../IIconType';

/**
 * @param {IIconType} props - Icon props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFile: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.9219 13.7812C48.2344 15.0937 49 16.8437 49 18.7031V49C49 52.9375 45.8281 56 42 56H14C10.0625 56 7 52.9375 7 49V7C7 3.17187 10.0625 0 14 0H30.2969C32.1562 0 33.9062 0.765625 35.2187 2.07812L46.9219 13.7812ZM44.4062 16.1875L32.8125 4.59375C32.375 4.15625 31.9375 3.9375 31.5 3.82812V15.75C31.5 16.7344 32.2656 17.5 33.25 17.5H45.1719C45.0625 17.0625 44.8437 16.625 44.4062 16.1875ZM45.5 49V21H33.25C30.2969 21 28 18.7031 28 15.75V3.5H14C12.0312 3.5 10.5 5.14062 10.5 7V49C10.5 50.9687 12.0312 52.5 14 52.5H42C43.8594 52.5 45.5 50.9687 45.5 49Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineFile;
