import IIconType from '../IIconType';

/**
 * @param {IIconType} props - The props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineMap: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-map"
    >
      <path
        d="M7.90278 4.05913C8.00694 3.98775 8.14583 3.98775 8.25 4.02344L15.8542 6.27187L20.8542 4.20189C21.375 3.98775 22 4.38034 22 4.98705V16.943C22 17.2999 21.7917 17.6211 21.4444 17.7281L16.0625 19.9409C15.9583 20.0122 15.8542 20.0122 15.7153 19.9766L8.11111 17.7281L3.11111 19.7981C2.59028 20.0122 2 19.6197 2 19.0129V7.05703C2 6.70014 2.20833 6.37894 2.52083 6.27187L7.90278 4.05913ZM3.11111 7.23548V18.5847L7.55556 16.7645V5.41533L3.11111 7.23548ZM15.3333 18.6561V7.30686L8.66667 5.34395V16.6931L15.3333 18.6561ZM16.4444 18.5847L20.8889 16.7645V5.41533L16.4444 7.23548V18.5847Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineMap;
