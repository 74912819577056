import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationChatBubbles: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-chat-bubbles"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 51.2C13.6889 51.2 13.3778 51.1111 13.0667 50.9778C12.3556 50.6222 11.9111 49.9111 11.9111 49.1111V43.6H8.40002C5.24446 43.6445 2.66669 41.0667 2.66669 37.9111V10.1334C2.66669 6.9778 5.24446 4.40002 8.40002 4.40002H48.1778C51.3334 4.40002 53.9111 6.9778 53.9111 10.1334V37.9556C53.9111 41.1111 51.3334 43.6889 48.1778 43.6889H24.5334L15.2889 50.8445C14.8889 51.0667 14.4445 51.2 14 51.2ZM8.40002 7.06669C6.71113 7.06669 5.33335 8.44447 5.33335 10.1334V37.9556C5.33335 39.6445 6.71113 41.0222 8.40002 41.0222H12.8C13.7778 41.0222 14.5778 41.8222 14.5778 42.8V48L23.1556 41.3334C23.4667 41.1111 23.8667 40.9778 24.2667 40.9778H48.1778C49.8667 40.9778 51.2445 39.6 51.2445 37.9111V10.1334C51.2445 8.44447 49.8667 7.06669 48.1778 7.06669H8.40002Z"
        fill={color}
      />
      <path
        d="M66 75.6001C65.5556 75.6001 65.1111 75.4667 64.7111 75.1556L55.4667 68.0001H31.8667C28.7111 68.0001 26.1334 65.4223 26.1334 62.2667V46.0445C26.1334 45.2889 26.7111 44.7112 27.4667 44.7112C28.2223 44.7112 28.8 45.2889 28.8 46.0445V62.2667C28.8 63.9556 30.1778 65.3334 31.8667 65.3334H55.7778C56.1778 65.3334 56.5778 65.4667 56.8889 65.689L65.4667 72.3112V67.1112C65.4667 66.1334 66.2667 65.3334 67.2445 65.3334H71.6445C73.3334 65.3334 74.7111 63.9556 74.7111 62.2667V34.4889C74.7111 32.8001 73.3334 31.4223 71.6445 31.4223H56.2667C55.5111 31.4223 54.9334 30.8445 54.9334 30.0889C54.9334 29.3334 55.5111 28.7556 56.2667 28.7556H71.6445C74.8 28.7556 77.3778 31.3334 77.3778 34.4889V62.3112C77.3778 65.4667 74.8 68.0445 71.6445 68.0445H68.1334V73.5556C68.1334 74.3556 67.6889 75.0667 66.9778 75.4223C66.6222 75.5112 66.3111 75.6001 66 75.6001Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationChatBubbles.defaultProps = {
  id: 'illustration-chat-bubbles',
};

export default IllustrationChatBubbles;
