import ErrorTemplate from '@/components/templates/ErrorTemplate/ErrorTemplate';
import Head from '@/components/templates/Head/Head';
import * as Sentry from '@sentry/browser';
import withRouter, { WithRouterProps } from 'next/dist/client/with-router';
import React from 'react';

/**
 * Describe the props for the error boundary component
 *
 * @augments {React.PropsWithChildren<unknown>}
 * @augments {WithRouterProps}
 * @interface IErrorBoundaryProps
 */
export interface IErrorBoundaryProps
  extends React.PropsWithChildren<unknown>,
    WithRouterProps {}

/**
 * Describe the state of the error boundary component
 *
 * @interface IErrorBoundaryState
 */
interface IErrorBoundaryState {
  /**
   * Whether or not an error has been thrown
   *
   * @memberof IErrorBoundaryState
   * @member {boolean} hasError
   */
  hasError: boolean;
}

/**
 * Describe the error boundary component
 *
 * @augments React.Component
 * @interface IErrorBoundary
 */
interface IErrorBoundary extends React.Component {
  /**
   * Whether or not an error has been thrown
   *
   * @memberof IErrorBoundary
   * @member {Readonly<IErrorBoundaryState>} state
   */
  state: Readonly<IErrorBoundaryState>;
}

/**
 * The error boundary component that allow us to handle any unexpected error
 * that the application may throw
 *
 * Documentation:
 * https://nextjs.org/docs/pages/building-your-application/configuring/error-handling#handling-client-errors
 *
 * @augments React.Component
 * @class
 * @implements {IErrorBoundary}
 */
export class ErrorBoundary
  extends React.Component<IErrorBoundaryProps, IErrorBoundaryState>
  implements IErrorBoundary
{
  /**
   * Creates an instance of ErrorBoundary
   *
   * @param {IErrorBoundaryProps} props - The props for the component
   */
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  /**
   * Update the sate so the next render will show the fallback UI hasError:
   * boolean; children?: React.ReactNode;
   *
   * @returns {IErrorBoundaryState} - The state to update
   */
  static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true };
  }

  /**
   * The lifecycle method that is called when an error is thrown
   *
   * @param {Error} error - The error that was thrown
   */
  componentDidCatch(error: Error): void {
    Sentry.captureException(error);
  }

  /**
   * The lifecycle method that is called when the component is mounted
   *
   * @returns {void}
   */
  componentDidMount(): void {
    this.props.router.events.on(
      'routeChangeComplete',
      /** Clears the error state on route change */
      () => {
        this.setState({ hasError: false });
      }
    );
  }

  /**
   * The render method
   *
   * @returns {React.ReactElement} - The react element to render
   */
  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <section data-testid="error-boundary">
          <Head title="Something went wrong" noIndex={true} />
          <ErrorTemplate
            title="Ruh Roh"
            description="Looks like we ran into a problem!"
            errorCode={500}
          />
        </section>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
