import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color}
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCamera: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 7.07143H22.5625L22.0703 5.84375C21.5781 4.44866 20.2656 3.5 18.7891 3.5H13.1562C11.6797 3.5 10.3672 4.44866 9.875 5.84375L9.4375 7.07143H5.5C3.53125 7.07143 2 8.68973 2 10.6429V24.9286C2 26.9375 3.53125 28.5 5.5 28.5H26.5C28.4141 28.5 30 26.9375 30 24.9286V10.6429C30 8.68973 28.4141 7.07143 26.5 7.07143ZM28.25 24.9286C28.25 25.933 27.4297 26.7143 26.5 26.7143H5.5C4.51562 26.7143 3.75 25.933 3.75 24.9286V10.6429C3.75 9.6942 4.51562 8.85714 5.5 8.85714H10.6406L11.5156 6.45759C11.7891 5.78795 12.3906 5.28571 13.1562 5.28571H18.7891C19.5 5.28571 20.1562 5.78795 20.4297 6.45759L21.3047 8.85714H26.5C27.4297 8.85714 28.25 9.6942 28.25 10.6429V24.9286ZM16 11.0893C12.3359 11.0893 9.4375 14.1027 9.4375 17.7857C9.4375 21.5246 12.3359 24.4821 16 24.4821C19.6094 24.4821 22.5625 21.5246 22.5625 17.7857C22.5625 14.1027 19.6094 11.0893 16 11.0893ZM16 22.6964C13.3203 22.6964 11.1875 20.5201 11.1875 17.7857C11.1875 15.1071 13.3203 12.875 16 12.875C18.625 12.875 20.8125 15.1071 20.8125 17.7857C20.8125 20.5201 18.625 22.6964 16 22.6964Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineCamera;
