import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineArrowLeft: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-arrow-left"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M13.83 19C13.6806 19.0005 13.533 18.9675 13.3981 18.9035C13.2631 18.8395 13.1442 18.746 13.05 18.63L8.22004 12.63C8.07296 12.4511 7.99255 12.2266 7.99255 11.995C7.99255 11.7634 8.07296 11.5389 8.22004 11.36L13.22 5.36C13.3898 5.15578 13.6337 5.02736 13.8981 5.00298C14.1625 4.9786 14.4258 5.06026 14.63 5.23C14.8343 5.39974 14.9627 5.64365 14.9871 5.90808C15.0114 6.1725 14.9298 6.43578 14.76 6.64L10.29 12L14.61 17.36C14.7323 17.5068 14.81 17.6855 14.8339 17.8751C14.8578 18.0646 14.8268 18.257 14.7448 18.4296C14.6627 18.6021 14.533 18.7475 14.3709 18.8486C14.2088 18.9497 14.0211 19.0022 13.83 19Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineArrowLeft.defaultProps = {
  id: 'icon-outline-arrow-left',
};

export default IconOutlineArrowLeft;
