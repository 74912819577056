import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPoster: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-poster"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8889 6.22222C28.1334 6.22222 27.5556 5.64444 27.5556 4.88888V3.1111C27.5556 2.35555 28.1334 1.77777 28.8889 1.77777C29.6445 1.77777 30.2222 2.35555 30.2222 3.1111V4.88888C30.2222 5.64444 29.6445 6.22222 28.8889 6.22222Z"
        fill={color}
      />
      <path
        d="M51.5556 6.22222C50.8 6.22222 50.2222 5.64444 50.2222 4.88888V3.1111C50.2222 2.35555 50.8 1.77777 51.5556 1.77777C52.3111 1.77777 52.8889 2.35555 52.8889 3.1111V4.88888C52.8889 5.64444 52.3111 6.22222 51.5556 6.22222Z"
        fill={color}
      />
      <path
        d="M58.2222 54.6667H21.7778C21.0222 54.6667 20.4445 54.0889 20.4445 53.3333V49.3333C20.4445 48.5778 21.0222 48 21.7778 48H58.2222C58.9778 48 59.5556 48.5778 59.5556 49.3333V53.3333C59.5556 54.0889 58.9778 54.6667 58.2222 54.6667ZM23.1111 52H56.8889V50.6667H23.1111V52Z"
        fill={color}
      />
      <path
        d="M58.2222 64.3111H21.7778C21.0222 64.3111 20.4445 63.7333 20.4445 62.9777C20.4445 62.2222 21.0222 61.6444 21.7778 61.6444H58.2222C58.9778 61.6444 59.5556 62.2222 59.5556 62.9777C59.5556 63.7333 58.9778 64.3111 58.2222 64.3111Z"
        fill={color}
      />
      <path
        d="M38.2222 69.1111H21.7778C21.0222 69.1111 20.4445 68.5333 20.4445 67.7778C20.4445 67.0222 21.0222 66.4445 21.7778 66.4445H38.2222C38.9778 66.4445 39.5556 67.0222 39.5556 67.7778C39.5556 68.5333 38.9778 69.1111 38.2222 69.1111Z"
        fill={color}
      />
      <path
        d="M57.7778 69.1111H41.3333C40.5778 69.1111 40 68.5333 40 67.7778C40 67.0222 40.5778 66.4445 41.3333 66.4445H57.7778C58.5333 66.4445 59.1111 67.0222 59.1111 67.7778C59.1111 68.5333 58.5333 69.1111 57.7778 69.1111Z"
        fill={color}
      />
      <path
        d="M57.7778 59.4667H33.3333C32.5778 59.4667 32 58.8889 32 58.1333C32 57.3778 32.5778 56.8 33.3333 56.8H57.7778C58.5333 56.8 59.1111 57.3778 59.1111 58.1333C59.1111 58.8889 58.5333 59.4667 57.7778 59.4667Z"
        fill={color}
      />
      <path
        d="M29.7778 59.4667H21.7778C21.0222 59.4667 20.4445 58.8889 20.4445 58.1333C20.4445 57.3778 21.0222 56.8 21.7778 56.8H29.7778C30.5333 56.8 31.1111 57.3778 31.1111 58.1333C31.1111 58.8889 30.5333 59.4667 29.7778 59.4667Z"
        fill={color}
      />
      <path
        d="M36.1778 43.4667C35.0222 43.4667 33.9111 43.0667 32.8 42.3111C31.7334 41.6 31.0222 40.5334 30.8 39.2889C30.3111 36.7111 31.9111 34.0889 32.5778 33.1111C34.4445 30.4 37.9556 27.4222 40 27.4222C42.2667 27.4222 45.5556 30.3556 47.4222 33.1111C48.1334 34.1334 49.6889 36.7556 49.2 39.2889C48.9778 40.5334 48.2667 41.5556 47.2 42.3111C45.7334 43.2889 44.2667 43.6445 42.8 43.3334C41.6 43.1111 40.6222 42.4889 39.9556 41.8667C39.2889 42.4445 38.3556 43.0667 37.1111 43.3334C36.8445 43.4222 36.4889 43.4667 36.1778 43.4667ZM40 38.5778C40.4445 38.5778 40.8889 38.8 41.1111 39.1556C42.9334 41.7778 45.2889 40.4 45.7334 40.0889C46.2222 39.7333 46.4889 39.3334 46.6222 38.8C46.8445 37.7334 46.3111 36.1334 45.2445 34.6222C43.2889 31.7778 40.7556 30.0445 40 30.0445C39.3334 30.0889 36.6222 31.9111 34.7556 34.5778C33.7334 36.0889 33.2 37.6889 33.3778 38.7556C33.4667 39.2889 33.7778 39.7334 34.2667 40.0445C34.7111 40.3556 37.0667 41.7778 38.8889 39.1111C39.1556 38.8 39.5556 38.5778 40 38.5778Z"
        fill={color}
      />
      <path
        d="M62.6667 78.2222H17.3333C14.6222 78.2222 12.4445 76.0444 12.4445 73.3333V12C12.4445 9.28886 14.6222 7.11108 17.3333 7.11108H27.5556V8.44442C27.5556 9.19997 28.1333 9.77775 28.8889 9.77775C29.6445 9.77775 30.2222 9.19997 30.2222 8.44442V7.11108H50.2222V8.44442C50.2222 9.19997 50.8 9.77775 51.5556 9.77775C52.3111 9.77775 52.8889 9.19997 52.8889 8.44442V7.11108H62.6667C65.3778 7.11108 67.5556 9.28886 67.5556 12V73.3333C67.5556 76.0444 65.3778 78.2222 62.6667 78.2222ZM17.3333 9.77775C16.0889 9.77775 15.1111 10.7555 15.1111 12V73.3333C15.1111 74.5778 16.0889 75.5555 17.3333 75.5555H62.6667C63.9111 75.5555 64.8889 74.5778 64.8889 73.3333V12C64.8889 10.7555 63.9111 9.77775 62.6667 9.77775H55.3333C54.8 11.3333 53.2889 12.4444 51.5556 12.4444C49.8222 12.4444 48.3556 11.3333 47.7778 9.77775H32.6667C32.1333 11.3333 30.6222 12.4444 28.8889 12.4444C27.1556 12.4444 25.6889 11.3333 25.1111 9.77775H17.3333Z"
        fill={color}
      />
      <path
        d="M48.9778 32.1334C48.4889 32.1334 48 32 47.5111 31.7778C46.4889 31.2 46.0445 30.4445 45.9111 29.8667C45.6445 28.8445 45.8667 27.6889 46.6222 26.4C47.2445 25.2889 48.2667 24.4889 49.3333 24.2223C50.1778 24 51.0222 24.0889 51.6889 24.4889C53.1111 25.3334 54.0889 27.1556 52.5778 29.8223C51.7333 31.2889 50.3556 32.1334 48.9778 32.1334ZM50.2222 26.7556C50.1333 26.7556 50.0889 26.7556 50.0445 26.8C49.7333 26.8889 49.2889 27.1556 48.9333 27.7334C48.5333 28.4445 48.4445 28.9334 48.4889 29.1111C48.4889 29.1556 48.5333 29.2445 48.8 29.4223C49.1111 29.6 49.8222 29.2889 50.2667 28.4889C51.0222 27.1556 50.5333 26.8889 50.3556 26.7556C50.3111 26.7556 50.2667 26.7556 50.2222 26.7556Z"
        fill={color}
      />
      <path
        d="M31.0222 32.1334C29.6889 32.1334 28.2667 31.2889 27.4222 29.8223C25.8667 27.1556 26.8445 25.3334 28.3111 24.4889C28.9778 24.0889 29.8222 24 30.6667 24.2223C31.7778 24.4889 32.7556 25.2889 33.3778 26.4C34.1333 27.6889 34.3556 28.8445 34.0889 29.8667C33.9111 30.4445 33.5111 31.2 32.4889 31.7778C32 32 31.5111 32.1334 31.0222 32.1334ZM29.7778 26.7556C29.7333 26.7556 29.6889 26.7556 29.6445 26.8C29.4222 26.9334 28.9778 27.2 29.7333 28.5334C30.1778 29.3334 30.8889 29.6 31.2 29.4667C31.4667 29.3334 31.5111 29.2 31.5111 29.1556C31.5556 28.9334 31.4667 28.4889 31.0667 27.7778C30.7556 27.2 30.2667 26.8889 29.9556 26.8445C29.9111 26.7556 29.8667 26.7556 29.7778 26.7556Z"
        fill={color}
      />
      <path
        d="M43.2 25.7778C42.9334 25.7778 42.6222 25.7334 42.3556 25.6889C41.0667 25.3778 40.4 24.6222 40.0445 24.0445C39.4222 22.9778 39.3778 21.6 39.9111 19.9556C40.6667 17.5556 42.8445 16.0889 44.9778 16.5334C45.9111 16.7111 46.6667 17.2889 47.2 18.1334C47.8667 19.2445 47.9556 20.7556 47.4667 22.3111C46.8 24.4 45.0667 25.7778 43.2 25.7778ZM44.1778 19.1111C43.5556 19.1111 42.8 19.6445 42.4445 20.7111C42.1778 21.6 42.1334 22.3111 42.3556 22.7111C42.4 22.8 42.4889 22.9778 42.9778 23.0667C43.6445 23.2 44.5334 22.6667 44.8889 21.4667C45.1111 20.6667 45.1111 19.8667 44.8445 19.4222C44.7556 19.2445 44.6222 19.1556 44.4 19.1111C44.3556 19.1111 44.2667 19.1111 44.1778 19.1111Z"
        fill={color}
      />
      <path
        d="M35.7778 26.2223C33.9111 26.2223 32.1778 24.8445 31.5556 22.7112C31.0667 21.1556 31.2 19.6445 31.8222 18.5334C32.3111 17.6889 33.0667 17.1556 34.0444 16.9334C36.1778 16.4889 38.3556 17.9556 39.1111 20.3556C39.6 22 39.5556 23.4223 38.9778 24.4445C38.6667 25.0223 37.9556 25.7778 36.6667 26.0889C36.3556 26.1778 36.0889 26.2223 35.7778 26.2223ZM34.8 19.5556C34.7111 19.5556 34.6667 19.5556 34.5778 19.6C34.3556 19.6445 34.2222 19.7334 34.1333 19.9112C33.8667 20.3556 33.8667 21.1556 34.0889 21.9556C34.4444 23.1556 35.3778 23.7334 36 23.5556C36.4444 23.4667 36.5778 23.2445 36.6222 23.2C36.8444 22.8445 36.8 22.0889 36.5333 21.2C36.2222 20.0889 35.4667 19.5556 34.8 19.5556Z"
        fill={color}
      />
      <path
        d="M28.8889 12.4445C26.6667 12.4445 24.8889 10.6667 24.8889 8.44446C24.8889 6.40001 26.4889 4.62224 28.5334 4.44446C28.6667 4.44446 28.7556 4.44446 28.8889 4.44446C31.1111 4.44446 32.8889 6.22224 32.8889 8.44446C32.8889 10.6667 31.1111 12.4445 28.8889 12.4445ZM28.8889 7.11112C28.8445 7.11112 28.8 7.11112 28.7556 7.11112C28.0889 7.15557 27.5556 7.73335 27.5556 8.44446C27.5556 9.20001 28.1334 9.77779 28.8889 9.77779C29.6445 9.77779 30.2222 9.20001 30.2222 8.44446C30.2222 7.6889 29.6445 7.11112 28.8889 7.11112Z"
        fill={color}
      />
      <path
        d="M51.5556 12.4445C49.3334 12.4445 47.5556 10.6667 47.5556 8.44446C47.5556 6.40001 49.1556 4.62224 51.2 4.44446C51.3333 4.44446 51.4222 4.44446 51.5556 4.44446C53.7778 4.44446 55.5556 6.22224 55.5556 8.44446C55.5556 10.6667 53.7778 12.4445 51.5556 12.4445ZM51.5556 7.11112C51.5111 7.11112 51.4667 7.11112 51.4222 7.11112C50.7556 7.15557 50.2222 7.73335 50.2222 8.44446C50.2222 9.20001 50.8 9.77779 51.5556 9.77779C52.3111 9.77779 52.8889 9.20001 52.8889 8.44446C52.8889 7.6889 52.3111 7.11112 51.5556 7.11112Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationPoster.defaultProps = {
  id: 'illustration-poster',
};

export default IllustrationPoster;
