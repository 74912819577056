import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineRotate: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-rotate"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M13.6507 20.2095C13.7099 20.2413 13.7741 20.2636 13.8409 20.2757L17.8451 20.9848C18.1106 21.0299 18.3842 20.9736 18.6057 20.8282C18.8272 20.6828 18.9785 20.4603 19.0263 20.2095C19.0741 19.9588 19.0144 19.7004 18.8605 19.4912C18.7066 19.2819 18.4709 19.139 18.2054 19.0939L16.2034 18.7441C17.725 17.9571 18.981 16.781 19.8273 15.3507C20.6736 13.9204 21.076 12.2937 20.9882 10.6573C20.9004 9.02085 20.3261 7.44093 19.331 6.0987C18.336 4.75648 16.9606 3.70629 15.3625 3.0686C15.1169 2.97506 14.842 2.97726 14.5981 3.07472C14.3542 3.17218 14.1611 3.35696 14.0612 3.58859C14.0106 3.70399 13.9847 3.82771 13.985 3.95261C13.9854 4.07751 14.0119 4.20111 14.0631 4.31627C14.1143 4.43142 14.1891 4.53586 14.2833 4.62355C14.3775 4.71123 14.4891 4.78042 14.6117 4.82713C15.9363 5.33628 17.0668 6.21198 17.8547 7.33916C18.6426 8.46633 19.051 9.79225 19.0263 11.1427C19.0322 12.3371 18.7039 13.5117 18.0745 14.5477C17.4452 15.5837 16.5373 16.4443 15.4426 17.0423L15.7129 15.4823C15.7339 15.3581 15.7288 15.2313 15.698 15.109C15.6671 14.9867 15.611 14.8713 15.5329 14.7695C15.4548 14.6676 15.3563 14.5813 15.2429 14.5154C15.1295 14.4496 15.0035 14.4055 14.872 14.3856C14.7405 14.3657 14.6062 14.3705 14.4767 14.3997C14.3472 14.4288 14.2251 14.4818 14.1173 14.5555C14.0094 14.6293 13.918 14.7224 13.8483 14.8295C13.7786 14.9366 13.7318 15.0556 13.7108 15.1798L13.0001 19.1979L13.0001 19.3492C12.9987 19.4589 13.019 19.568 13.0601 19.6706C13.074 19.7053 13.0944 19.7373 13.1202 19.7652C13.1469 19.833 13.184 19.8967 13.2303 19.9543L13.3304 20.0299C13.379 20.0789 13.4327 20.1233 13.4906 20.1623C13.5413 20.1853 13.5952 20.2012 13.6507 20.2095Z"
          fill={color}
        />
        <path
          d="M9.91371 20.4008C9.96403 20.2848 9.98942 20.1604 9.98842 20.0349C9.98742 19.9095 9.96003 19.7855 9.90787 19.6701C9.8557 19.5547 9.77979 19.4503 9.68454 19.363C9.5893 19.2756 9.47663 19.207 9.35309 19.1612C8.04234 18.648 6.92468 17.7748 6.14473 16.6545C5.36477 15.5343 4.95842 14.2186 4.97823 12.8776C4.97233 11.6822 5.30069 10.5064 5.93005 9.46949C6.55942 8.43254 7.46744 7.57118 8.56221 6.97262L8.29191 8.53404L8.29191 8.68545C8.27067 8.93643 8.35577 9.1851 8.5285 9.37677C8.70122 9.56844 8.94742 9.68739 9.21293 9.70747C9.47844 9.72755 9.74152 9.64711 9.94428 9.48383C10.147 9.32056 10.2729 9.08784 10.2941 8.83686L10.9849 4.81502C11.005 4.71179 11.005 4.60596 10.9849 4.50273L10.9849 4.42703C10.9548 4.31993 10.9075 4.21781 10.8447 4.12421C10.7926 4.0604 10.732 4.00316 10.6645 3.95387L10.5644 3.8687C10.5041 3.83945 10.4402 3.81721 10.3742 3.80246C10.3231 3.7693 10.2651 3.74672 10.204 3.73622L6.19959 3.02648C6.06654 2.99609 5.92831 2.99181 5.79344 3.0139C5.65857 3.036 5.52992 3.08401 5.41546 3.15496C5.30099 3.22592 5.20313 3.3183 5.12793 3.42642C5.05273 3.53453 5.00178 3.65607 4.97823 3.78353L4.97823 3.95387C4.97437 4.17795 5.05475 4.39605 5.20508 4.56934C5.35541 4.74264 5.56593 4.85988 5.79914 4.90019L7.80137 5.25032C6.27923 6.0372 5.02252 7.21365 4.17534 8.64477C3.32815 10.0759 2.92481 11.7037 3.01155 13.3416C3.09829 14.9795 3.6716 16.5611 4.66575 17.9051C5.6599 19.2491 7.03463 20.301 8.63229 20.9402C8.87805 21.027 9.15001 21.019 9.38965 20.9182C9.62929 20.8173 9.81747 20.6315 9.91371 20.4008Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconOutlineRotate.defaultProps = {
  id: 'icon-outline-rotate',
};

export default IconOutlineRotate;
