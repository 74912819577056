import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationHeart: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-heart"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0889 73.3333C36.8444 73.3333 26.3555 67.0667 18.8444 60.6667C10.5778 53.6889 1.82222 44.9778 1.77777 30.4889C1.77777 21.7333 5.33333 16.1778 8.3111 13.0667C12.1333 9.06668 17.2444 6.75556 22.3111 6.75556C22.3111 6.75556 22.3111 6.75556 22.3555 6.75556C31.5555 6.75556 37.6444 13.6445 40.0444 16.9778C40.8889 15.8667 42.1778 14.3111 43.8667 12.7111C48.0889 8.80001 52.8889 6.71112 57.6889 6.71112C57.6889 6.71112 57.6889 6.71112 57.7333 6.71112C62.8 6.71112 67.9111 8.97779 71.7333 12.9778C74.7111 16.0889 78.3111 21.6445 78.3111 30.4C78.3555 44.8889 69.5555 53.6 61.3778 60.6667C54.2667 66.6222 43.7333 73.3333 40.0889 73.3333ZM22.3111 9.37779C13.5111 9.42223 4.44444 17.2889 4.44444 30.4889C4.44444 43.8222 12.7555 52.0445 20.5333 58.6667C28.1778 65.2 37.9555 70.6667 40.0444 70.6667C42.1778 70.6667 51.5111 65.4667 59.5111 58.5778C67.2889 51.9111 75.5555 43.6889 75.5111 30.3556C75.5111 22.4889 72.3111 17.5556 69.6889 14.8C66.3556 11.3333 61.9556 9.33334 57.6 9.33334C57.6 9.33334 57.6 9.33334 57.5555 9.33334C47.5555 9.33334 41.1111 19.9111 41.0667 20C40.8444 20.4 40.4 20.6222 39.9111 20.6667C39.4222 20.6667 39.0222 20.4 38.7555 20C38.7555 19.8667 32.6222 9.37779 22.3111 9.37779Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationHeart.defaultProps = {
  id: 'illustration-heart',
};

export default IllustrationHeart;
