import { ReactElement, ReactNode } from 'react';

/**
 * IParagraph - Interface for Paragraph component
 *
 * @interface
 */
export interface IParagraph extends React.HTMLAttributes<HTMLParagraphElement> {
  /** The styling of the paragraph. Regular, bold, or italic or boldItalic. */
  styling?: 'regular' | 'bold' | 'italic' | 'boldItalic';
  /**
   * The size of the paragraph.
   *
   * @default 'body1'
   */
  size?:
    | 'body1'
    | 'body2'
    | 'body3'
    | 'body4'
    | 'body5'
    | 'body-article'
    | 'overline';
  /**
   * The font of the paragraph.
   *
   * @default 'petco'
   */
  font?: 'petco' | 'amasis';
  /** The content of the paragraph. */
  children?: ReactElement | string | ReactNode;
}

/**
 * Paragraph Used to display a paragraph of text. Can be used as a generic
 * paragraph.
 *
 * @param {IParagraph} props - The props for the Paragraph component
 * @returns {React.FC<IParagraph>} Paragraph Component
 */
const Paragraph: React.FC<IParagraph> = ({
  styling,
  size,
  font,
  children,
  ...rest
}: IParagraph) => {
  // Set the styling Classes
  let stylingClass = '';
  switch (styling) {
    case 'bold':
      stylingClass = 'font-bold';
      break;
    case 'italic':
      stylingClass = 'italic';
      break;
    case 'boldItalic':
      stylingClass = 'font-bold italic';
      break;
    default:
      stylingClass = '';
      break;
  }

  const paragraphClass = `text-${size} font-${font} break-words ${stylingClass} ${rest.className}`;
  return (
    <p data-testid="paragraph" {...rest} className={paragraphClass}>
      {children}
    </p>
  );
};
Paragraph.defaultProps = {
  font: 'petco',
  size: 'body1',
  styling: 'regular',
  className: '',
};

export default Paragraph;
