import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPawPrint: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-paw-print"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.2222 52.4444C49.7778 52.4444 49.3333 52.4 48.8889 52.3555C45.8667 51.9111 43.5111 50.5778 42 48.4889C40.4889 46.4 40.0889 44 40.0444 42.3111C38.4 42.4 35.9556 42.2222 33.7333 40.8444C31.5111 39.4666 30.0444 37.2889 29.3333 34.2666C28.8444 32.0889 29.1556 30.0444 30.3111 28.1778C32.7556 24.2666 38.0889 22.7111 40.2667 22.2222C46.8 20.7555 54.8889 21.4222 57.2889 23.6444C60.0889 26.2666 60.8889 34.4 60.0444 40.4889C59.7333 42.7111 58.5778 48.1333 54.8889 50.8889C53.4667 51.9111 51.9111 52.4444 50.2222 52.4444ZM49.3333 49.2444C50.7111 49.4666 51.9111 49.1555 53.0222 48.3555C54.9333 46.9333 56.4 43.8222 56.9333 40C57.9111 33.2889 56.5333 27.1555 55.1556 25.8666C54 24.8 47.2 23.7778 40.9333 25.2C37.2 26.0444 34.1778 27.7333 32.9333 29.7778C32.2222 30.8889 32.0444 32.1333 32.3556 33.5111C32.8444 35.6444 33.8667 37.2 35.3333 38.1333C37.9111 39.7333 41.2444 38.9778 41.2889 38.9778C41.7778 38.8444 42.3111 38.9778 42.7111 39.3333C43.1111 39.6889 43.2889 40.2222 43.2 40.7111C43.2 40.7555 42.7111 44.1333 44.4889 46.5778C45.5111 48.0444 47.1556 48.9333 49.3333 49.2444Z"
        fill={color}
      />
      <path
        d="M67.9556 44.0445C65.2444 44.0445 62.9333 42.6667 62.1333 40.4445C61.6445 38.9778 61.6889 37.6445 62.3111 36.4445C63.1111 34.8889 64.7556 33.7334 67.2 32.9778C69.2889 32.3111 71.4667 32.4889 73.1111 33.3778C74.3556 34.0445 75.2 35.0667 75.5556 36.2667C76.5778 39.6 74.6667 42.4 70.4889 43.6889C69.6445 43.9111 68.7556 44.0445 67.9556 44.0445ZM69.7778 35.6889C69.2445 35.6889 68.6667 35.7778 68.0889 35.9556C66.5333 36.4445 65.4222 37.1111 65.0667 37.8667C64.9333 38.1334 64.7556 38.6223 65.0667 39.4667C65.5111 40.7556 67.5556 41.3334 69.5556 40.7111C73.2889 39.5556 72.7556 37.7334 72.5778 37.1556C72.4 36.6223 71.9556 36.3111 71.6445 36.1334C71.1111 35.8223 70.4889 35.6889 69.7778 35.6889Z"
        fill={color}
      />
      <path
        d="M41.6444 20.3112C41.1556 20.3112 40.6667 20.2667 40.1333 20.089C37.1111 19.3334 35.4222 15.7779 36.2667 12.0001C37.2444 7.73341 39.8667 5.60007 43.2889 6.35563C44.5333 6.62229 45.6 7.42229 46.3556 8.57785C47.3778 10.1779 47.6889 12.3112 47.2 14.4445C46.6222 16.9334 45.6 18.6667 44.1333 19.6001C43.3778 20.089 42.5778 20.3112 41.6444 20.3112ZM42 9.37785C41.2 9.37785 40 9.86674 39.3333 12.7556C38.8889 14.8001 39.6 16.8001 40.9333 17.1556C41.6 17.3334 42.0889 17.289 42.5333 17.0223C43.2444 16.5778 43.8222 15.4667 44.1778 13.8667C44.4889 12.5779 44.3111 11.2445 43.7333 10.3556C43.5111 10.0445 43.1556 9.60007 42.6222 9.51118C42.4889 9.42229 42.2667 9.37785 42 9.37785Z"
        fill={color}
      />
      <path
        d="M68.2667 27.689C65.9556 27.689 63.8667 26.7112 62.6222 24.889C61.6444 23.4223 61.2889 21.8667 61.6444 20.3556C62.0889 18.3556 63.7333 16.5334 66.3111 15.0223C70.0444 12.8445 74.4444 13.5112 76.5778 16.489C77.4667 17.7778 77.7778 19.2445 77.4667 20.8001C77.0222 22.9334 75.3333 24.9778 72.9333 26.4001C71.3778 27.289 69.7778 27.689 68.2667 27.689ZM70.9333 16.889C69.9556 16.889 68.8889 17.1556 67.9111 17.7334C66.0889 18.8001 64.9778 19.9556 64.7111 21.0667C64.5778 21.7334 64.7111 22.4001 65.2444 23.1556C66.3556 24.8001 69.0667 25.0667 71.3778 23.689C73.0222 22.7556 74.1778 21.3778 74.4444 20.1334C74.5778 19.4667 74.4889 18.8445 74.0889 18.3112C73.3778 17.3778 72.2222 16.889 70.9333 16.889Z"
        fill={color}
      />
      <path
        d="M56.6667 18.5778C55.6444 18.5778 54.6222 18.3111 53.6444 17.7334C50.4444 15.9111 49.4222 11.5556 51.3333 7.60003C52.5333 5.0667 54.4444 3.24447 56.5333 2.62225C58.0444 2.17781 59.5556 2.35558 60.8889 3.15558C64.0444 5.02225 65.0222 9.37781 63.1556 13.2889C61.8667 16 60.1333 17.7334 58.2222 18.3556C57.7333 18.5334 57.2 18.5778 56.6667 18.5778ZM54.1333 8.97781C52.9778 11.3778 53.4222 14.0889 55.1556 15.0667C55.9111 15.5111 56.6222 15.6445 57.2889 15.4223C58.3556 15.0667 59.4667 13.8667 60.3556 11.9556C61.5111 9.55558 61.0667 6.93336 59.2889 5.86669C58.7111 5.51114 58.0889 5.42225 57.4222 5.64447C56.2222 6.00003 54.9778 7.24447 54.1333 8.97781Z"
        fill={color}
      />
      <path
        d="M18.2222 78.2223C17.9111 78.2223 17.5556 78.1778 17.2 78.1334C14.8889 77.7778 13.1556 76.8001 12 75.2001C10.9778 73.8223 10.6222 72.2223 10.5333 70.9778C9.28889 70.9778 7.68889 70.7556 6.22222 69.8667C4.53333 68.8445 3.42222 67.1556 2.88889 64.8889C2.48889 63.2445 2.75556 61.6001 3.64445 60.2223C5.46667 57.2889 9.42222 56.1334 11.0222 55.7778C15.3333 54.8001 21.5556 55.0223 23.5556 56.8889C25.6889 58.8889 26.3111 64.7556 25.6444 69.2889C25.4222 70.9334 24.5778 74.9334 21.7778 77.0223C20.7556 77.8223 19.5111 78.2223 18.2222 78.2223ZM17.6444 75.0667C18.5333 75.2001 19.2444 75.0223 19.9111 74.5334C21.1556 73.6001 22.1778 71.4223 22.5333 68.8889C22.8444 66.8445 22.8444 64.5334 22.5333 62.5778C22.2222 60.4001 21.6444 59.4223 21.4222 59.2001C20.7111 58.6223 16.1778 57.8223 11.6889 58.8445C9.15556 59.4223 7.06667 60.5778 6.26667 61.9112C5.82222 62.6223 5.68889 63.3778 5.91111 64.2223C6.26667 65.6445 6.88889 66.6667 7.86667 67.2445C9.55556 68.2667 11.7333 67.7778 11.7778 67.7778C12.2667 67.6445 12.8 67.7778 13.2 68.1334C13.6 68.489 13.7778 69.0223 13.6889 69.5112C13.6889 69.5112 13.5111 70.8445 13.9556 72.1778C14.4444 73.8223 15.6889 74.7556 17.6444 75.0667Z"
        fill={color}
      />
      <path
        d="M30.8889 72.2222C28.7556 72.2222 26.9333 71.1555 26.3111 69.3333C25.8222 67.9111 26.1333 66.8 26.4445 66.1333C27.0667 64.9333 28.3111 64 30.1778 63.4222C31.7778 62.9333 33.4667 63.0222 34.7556 63.7333C35.7333 64.2666 36.4444 65.0667 36.7111 66.0444C37.3333 68.0889 36.6222 70.7111 32.8 71.9111C32.1778 72.1333 31.5111 72.2222 30.8889 72.2222ZM32.1778 66.2667C31.8222 66.2667 31.4667 66.3111 31.1111 66.4444C29.6889 66.8889 29.3333 67.3778 29.2 67.6C29.1556 67.6889 29.0667 67.9111 29.2 68.4C29.4222 69.0222 30.5778 69.4222 31.8667 69.0222C34.1333 68.3111 33.8222 67.3778 33.7333 67.0666C33.6445 66.8444 33.4667 66.6667 33.2889 66.5778C33.0222 66.3555 32.6222 66.2667 32.1778 66.2667Z"
        fill={color}
      />
      <path
        d="M12.0889 55.2888C11.7333 55.2888 11.3333 55.2444 10.9333 55.1555C8.53334 54.5333 7.2 51.7777 7.86667 48.8444C8.75556 44.9333 11.2889 44.0444 13.3778 44.4888C14.3556 44.7111 15.2444 45.3333 15.8667 46.2666C16.6667 47.5111 16.8889 49.1555 16.5333 50.8C16.0889 52.7111 15.2889 54.0444 14.1333 54.7555C13.6444 55.0222 12.9778 55.2888 12.0889 55.2888ZM12.4 47.4666C11.9556 47.4666 11.2889 47.7333 10.8889 49.5111C10.5778 50.8444 11.0667 51.9555 11.6889 52.1333C12.1778 52.2666 12.4 52.1333 12.4889 52.0888C12.6667 51.9555 13.1556 51.5555 13.4667 50.0888C13.6444 49.2888 13.5556 48.4444 13.2 47.9111C13.1111 47.7333 12.9333 47.5555 12.6667 47.5111C12.6222 47.5111 12.5333 47.4666 12.4 47.4666Z"
        fill={color}
      />
      <path
        d="M31.1111 60.5778C29.3333 60.5778 27.6889 59.8223 26.7111 58.4001C25.9556 57.2445 25.6889 56.0001 25.9556 54.8001C26.3111 53.2445 27.5111 51.8667 29.5111 50.7112C32.3556 49.0667 35.7778 49.5556 37.4222 51.9112C38.1333 52.9334 38.4 54.1334 38.1333 55.3334C37.7778 56.9778 36.5333 58.5334 34.6667 59.6001C33.5111 60.2223 32.2667 60.5778 31.1111 60.5778ZM33.0222 52.8445C32.4 52.8445 31.7333 53.0223 31.0667 53.4223C29.9111 54.0889 29.1556 54.8445 28.9778 55.5112C28.9333 55.7334 28.8889 56.0889 29.2889 56.6667C29.9556 57.6445 31.6444 57.7778 33.0667 56.9334C34.1333 56.3112 34.8889 55.4667 35.0667 54.6667C35.1556 54.3112 35.0667 54.0001 34.8889 53.6889C34.4444 53.1112 33.7778 52.8445 33.0222 52.8445Z"
        fill={color}
      />
      <path
        d="M22.8444 54.0445C22.0444 54.0445 21.2444 53.8222 20.4444 53.3778C17.9556 51.9556 17.1556 48.6222 18.6222 45.5556C19.5556 43.6445 20.9778 42.2667 22.6222 41.7778C23.8222 41.4222 25.0222 41.5556 26.0889 42.2222C28.5333 43.6889 29.2889 47.0222 27.8667 50C26.8889 52.0445 25.5556 53.3778 24.0444 53.8667C23.6889 54 23.2444 54.0445 22.8444 54.0445ZM23.8667 44.7111C23.7333 44.7111 23.6444 44.7111 23.5111 44.7556C22.7556 44.9778 21.9556 45.8222 21.4222 46.8889C20.7111 48.4 20.9333 50.0445 21.9556 50.6667C22.5333 51.0222 22.8889 50.9333 23.1111 50.8889C23.7333 50.6667 24.4444 49.8667 25.0222 48.6667C25.7333 47.1556 25.5111 45.5111 24.4444 44.8889C24.3111 44.7556 24.0889 44.7111 23.8667 44.7111Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationPawPrint.defaultProps = {
  id: 'illustration-',
};

export default IllustrationPawPrint;
