import IIconType from '../IIconType';

/**
 * @param {IIconType} props - The props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFilter: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-filter"
    >
      <path
        d="M19.5 15C19.75 15 20 15.25 20 15.5C20 15.7812 19.75 16 19.4688 16H8.9375C8.6875 17.1562 7.6875 18 6.5 18C5.09375 18 4 16.9062 4 15.5C4 14.125 5.09375 13 6.5 13C7.6875 13 8.6875 13.875 8.9375 15H19.5ZM6.5 17C7.3125 17 8 16.3438 8 15.5C8 14.6875 7.3125 14 6.5 14C5.65625 14 5 14.6875 5 15.5C5 16.3438 5.65625 17 6.5 17ZM4.5 9C4.21875 9 4 8.78125 4 8.5C4 8.25 4.21875 8 4.5 8H15.0312C15.2812 6.875 16.2812 6 17.5 6C18.875 6 20 7.125 20 8.5C20 9.90625 18.875 11 17.5 11C16.2812 11 15.2812 10.1562 15.0312 9H4.5ZM17.5 7C16.6562 7 16 7.6875 16 8.5C16 9.34375 16.6562 10 17.5 10C18.3125 10 19 9.34375 19 8.5C19 7.6875 18.3125 7 17.5 7Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineFilter;
