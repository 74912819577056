/**
 * Heading Font Classes Used to set particular classes for particular fonts
 *
 * @constant
 */
export const headingFontClasses = {
  petco: 'font-bold',
  amasis: '',
};

/**
 * Body Size Classes Used to the set the classes for the body size
 *
 * @constant
 */
export const bodySizeClasses = {
  body1: 'text-[22px] leading-8',
  body2: 'text-[20px] leading-7',
  body3: 'text-[18px] leading-[26px]',
  body4: 'text-[16px] leading-6',
  body5: 'text-[14px] leading-[22px]',
  overline: 'text-[12px] leading-5',
};

/**
 * Body Styling Classes Used to the set the classes for the body styling
 *
 * @constant
 */
export const bodyStylingClasses = {
  regular: '',
  bold: 'font-bold',
  italic: 'italic',
  boldItalic: 'font-bold italic',
  link: 'text-base-400 underline',
};
