import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineMinus: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-minus"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7" y="12" width="10" height="0.75" rx="0.375" fill={color} />
    </svg>
  );
};
IconOutlineMinus.defaultProps = {
  id: 'icon-outline-minus',
};
export default IconOutlineMinus;
