import LoadingSpinner from '@/components/atoms/LoadingSpinner/LoadingSpinner';
import { cva } from 'cva';
import Link from 'next/link';

/**
 * The valid sizes for the button.
 *
 * @typedef {'sm' | 'md' | 'lg'}
 */
type ButtonSize = 'sm' | 'md' | 'lg';

/**
 * The valid colors for the button.
 *
 * @typedef {'primary' | 'secondary'}
 */
type ButtonColor = 'primary' | 'secondary';

/**
 * The valid variants for the button.
 *
 * @typedef {'light' | 'dark' | 'destructive'}
 */
type Variant = 'light' | 'dark' | 'destructive';

/**
 * IButton Interface for the Button component
 *
 * @interface
 */
export interface IButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  /**
   * The size of the button. Can be 'sm', 'md', or 'lg'.
   *
   * @default 'lg'
   */
  size: ButtonSize;
  /**
   * The variant of the button. Can be 'light', 'dark', or 'destructive'.
   *
   * @default 'light'
   */
  variant: Variant;
  /**
   * The color of the button. Can be 'primary' or 'secondary'.
   *
   * @default 'primary'
   */
  color: ButtonColor;
  /**
   * The optional prop to make the button a full width component.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * The optional url to use button as a link
   *
   * @default ''
   */
  href?: string;
  /**
   * Optional prop to open to a new tab link
   *
   * @default false
   */
  newTab?: boolean;
  /**
   * The optional loading state of the button
   *
   * @default false
   */
  loading?: boolean;
  /**
   * The optional rel attribute for the link
   *
   * @default ''
   */
  rel?: string;
}

/**
 * Button Base Font Properties This is the base font properties for the button.
 *
 * @constant
 */
export const buttonBaseFont =
  'font-petco leading-6 text-[16px] rounded-full font-bold transition-colors duration-200 ease-in-out';

/**
 * Button Size Map This map is used to map the size prop to the appropriate
 * tailwind classes.
 *
 * @constant
 */
const sizes = {
  sm: 'px-6 py-1',
  md: 'px-10 py-2.5',
  lg: 'px-10 py-3.5',
};

/**
 * Button Loading Size Map This map is used to map the size prop to the
 * appropriate tailwind classes.
 *
 * @constant
 */
const loadingSizes = {
  sm: 'px-2 py-1',
  md: 'px-6 py-2.5',
  lg: 'px-6 py-3.5',
};

/**
 * Button Classes The classes for the button component using CVA.
 *
 * @constant
 */
const buttonClasses = cva(
  `${buttonBaseFont}
  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus-400`,
  {
    variants: {
      color: { primary: '', secondary: '' },
      variant: { light: '', dark: '', destructive: '' },
      state: {
        loading:
          'flex flex-row items-center justify-center gap-x-2 cursor-not-allowed',
        disabled: 'cursor-not-allowed',
      },
    },
    compoundVariants: [
      {
        variant: 'light',
        color: 'primary',
        class:
          'bg-base-400 hover:bg-base-500 text-neutral-100 border-2 border-base-400 hover:border-base-500',
      },
      {
        variant: 'light',
        color: 'secondary',
        class:
          'bg-invisible text-base-400 border-solid border-2 border-base-400 hover:bg-base-400 hover:text-neutral-100',
      },
      {
        variant: 'dark',
        color: 'primary',
        class:
          'bg-neutral-100 hover:bg-base-100 text-base-400 border-2 border-neutral-100',
      },
      {
        variant: 'dark',
        color: 'secondary',
        class:
          'bg-invisible text-neutral-100 border-solid border-2 border-neutral-100 hover:bg-neutral-100 hover:text-base-400',
      },

      {
        variant: 'destructive',
        color: 'primary',
        class:
          'bg-error-200 hover:bg-error-300 text-neutral-100 border-2 border-error-200 hover:border-error-300',
      },
      {
        variant: 'destructive',
        color: 'secondary',
        class:
          'bg-invisible text-error-200 border-solid border-2 border-error-200 hover:bg-error-200 hover:text-neutral-100',
      },

      {
        state: 'disabled',
        color: 'primary',
        class:
          'bg-neutral-300 text-neutral-200 hover:bg-neutral-300 hover:text-neutral-200 border-neutral-300 hover:border-neutral-300',
      },
      {
        state: 'disabled',
        color: 'secondary',
        class:
          'bg-invisible !text-neutral-400 border-solid border-2 border-neutral-400 hover:!bg-invisible hover:!text-neutral-400',
      },
    ],
  }
);

/**
 * Function to get the size classes for the button based on the size prop and
 * its loading state.
 *
 * @param {ButtonSize} size - The size prop for the button
 * @param {boolean} loading - The loading state of the button
 * @returns {string} The size classes for the button
 */
const getSizeClasses = (size: ButtonSize, loading?: boolean): string => {
  if (loading) {
    return loadingSizes[size];
  }
  return sizes[size];
};

/**
 * Button - Component Used as a generic Button across the site. Allows for
 * different sizes, colors, and variants.
 *
 * @param {IButton} props - The props for the Button component
 * @returns {React.FC<IButton>} Button Component
 */
const Button: React.FC<IButton> = ({
  size,
  variant,
  color,
  disabled,
  fullWidth,
  children,
  href,
  newTab = false,
  loading = false,
  rel,
  ...rest
}: IButton) => {
  /**
   * The size classes for the button
   *
   * @constant
   */
  const sizeCls = getSizeClasses(size, loading);

  /**
   * The data test id
   *
   * @constant
   */
  const dataTestId = `button-${size}-${variant}-${color}-${disabled}${
    loading ? '-loading' : ''
  }`;
  const fullWidthClass = 'w-full';

  /**
   * The button state
   *
   * @constant
   */
  const buttonState = disabled && !loading ? 'disabled' : 'loading';

  /**
   * The button classes
   *
   * @constant
   */
  const buttonClass = `${sizeCls} ${buttonClasses(
    disabled || loading
      ? {
          color: color,
          variant: variant,
          state: buttonState,
        }
      : {
          color: color,
          variant: variant,
        }
  )} ${fullWidth ? fullWidthClass : ''}
  ${rest.className}`;

  return (
    <>
      {href && !disabled && !loading ? (
        <>
          {!newTab ? (
            <Link href={href}>
              <a
                data-testid="button-link"
                className={`${buttonClass} inline-block text-center`}
                href={href}
                onClick={rest.onClick}
                rel={rel ?? ''}
              >
                {children}
              </a>
            </Link>
          ) : (
            <a
              href={href}
              target="_blank"
              data-testid="button-link"
              className={`${buttonClass} inline-block text-center`}
              onClick={rest.onClick}
              rel="noreferrer"
            >
              {children}
            </a>
          )}
        </>
      ) : (
        <button
          data-testid={dataTestId}
          {...rest}
          className={buttonClass}
          disabled={disabled}
        >
          {loading && (
            <LoadingSpinner size={24} classes="aspect-square w-6 h-6" />
          )}
          {children}
        </button>
      )}
    </>
  );
};

Button.defaultProps = {
  size: 'lg',
  children: '',
  variant: 'light',
  color: 'primary',
  disabled: false,
  href: '',
};

export default Button;
