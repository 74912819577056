import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationCat: React.FC<IIconType> = ({ size, color, id }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-cat"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.7555 66.2667C34.7555 66.2667 26.7111 61.6889 23.5111 59.7333C18.2666 56.5333 15.5999 51.5111 15.7777 45.2445C15.8666 41.7778 16.7999 38.8889 17.2888 37.5556C15.6888 35.0222 14.5333 29.5111 14.6222 26.1333C14.7999 20.8445 15.2444 15.6 18.1777 14.0889C21.3333 12.4889 24.6666 16.2222 25.2888 16.9333L30.6666 21.9111C32.4444 21.1556 36.6666 19.5556 40.7555 19.5556C45.1999 19.5556 49.1555 21.1111 50.8888 21.8667L56.2222 16.9778C56.8444 16.2667 60.1777 12.5333 63.3333 14.1333C66.2666 15.6 66.7111 20.8889 66.8888 26.1778C67.0222 29.5556 65.8666 35.0222 64.2222 37.6C64.7111 38.9333 65.6444 41.8222 65.7333 45.2889C65.9111 51.5556 63.2444 56.5778 57.9999 59.7778C54.7999 61.6889 46.7555 66.2667 40.7555 66.2667ZM19.8666 16.4C19.6888 16.4 19.5555 16.4444 19.4222 16.4889C17.6444 17.3778 17.4222 24.0445 17.3333 26.2222C17.1999 29.7778 18.6666 35.1556 19.8222 36.4445C20.1777 36.8444 20.2666 37.4222 20.0444 37.9111C19.8222 38.4445 14.3555 50.9778 24.9333 57.4667C31.1999 61.2889 37.1111 63.6 40.7999 63.6C44.4888 63.6 50.3999 61.2889 56.6666 57.4667C67.2444 50.9778 61.7777 38.4445 61.5555 37.9111C61.3333 37.4222 61.4222 36.8444 61.7777 36.4889C62.9333 35.2444 64.3999 29.8222 64.2666 26.2667C64.1777 24.0889 63.9555 17.4222 62.1777 16.5333C61.0666 16 59.0222 17.8222 58.2666 18.7556C58.2222 18.8 58.1777 18.8444 58.1333 18.8889L51.9999 24.4445C51.5999 24.8444 50.9777 24.8889 50.4888 24.6222C50.4444 24.5778 45.8666 22.1778 40.7555 22.1778C36.1777 22.1778 31.0222 24.6222 30.9777 24.6222C30.4888 24.8445 29.9111 24.7556 29.5111 24.4L23.4222 18.8889C23.3777 18.8444 23.3333 18.8 23.2888 18.7556C22.6222 17.9111 20.9777 16.4 19.8666 16.4Z"
        fill={color}
      />
      <path
        d="M32.9333 40.6667C34.3079 40.6667 35.4222 39.5524 35.4222 38.1778C35.4222 36.8032 34.3079 35.6889 32.9333 35.6889C31.5587 35.6889 30.4444 36.8032 30.4444 38.1778C30.4444 39.5524 31.5587 40.6667 32.9333 40.6667Z"
        fill={color}
      />
      <path
        d="M47.2444 40.6667C48.619 40.6667 49.7333 39.5524 49.7333 38.1778C49.7333 36.8032 48.619 35.6889 47.2444 35.6889C45.8699 35.6889 44.7556 36.8032 44.7556 38.1778C44.7556 39.5524 45.8699 40.6667 47.2444 40.6667Z"
        fill={color}
      />
      <path
        d="M38.8889 49.4667C38.8889 49.4667 36.7555 47.6889 36.1777 47.0222C35.6 46.3556 35.9555 44.9333 36.6666 44.7556C37.3333 44.5778 38.8889 44.4445 40.1333 44.4445C41.3777 44.4445 43.1111 44.4445 44 44.9333C44.8889 45.4222 44.3555 47.1111 43.6 47.8222C42.8444 48.5333 41.4666 49.4667 41.4666 49.4667C41.4666 49.4667 40.1333 50.9333 38.8889 49.4667Z"
        fill={color}
      />
      <path
        d="M35.6 57.9111C32.7111 57.9111 30.8 55.9556 30.1778 54.0445C29.6 52.2667 30.1333 50.5333 31.5111 49.5556C32.1333 49.1111 32.9333 49.2889 33.3778 49.8667C33.8222 50.4889 33.6444 51.2889 33.0667 51.7333C32.4889 52.1333 32.6667 52.9333 32.7555 53.2445C33.0667 54.2667 34.0889 55.2445 35.6444 55.2445C37.9555 55.2445 38.7555 53.9111 38.9778 53.4222V49.9111C38.9778 49.1556 39.5555 48.5778 40.3111 48.5778C41.0667 48.5778 41.6444 49.1556 41.6444 49.9111V53.6889C41.6444 53.8222 41.6444 53.9556 41.6 54.0889C41.5111 54.2222 40.3111 57.9111 35.6 57.9111Z"
        fill={color}
      />
      <path
        d="M44.8444 57.6889C40.1333 57.6889 38.9777 54.2222 38.9333 54.0889C38.8889 53.9556 38.8889 53.8222 38.8889 53.6889V49.9111C38.8889 49.1556 39.4666 48.5778 40.2222 48.5778C40.9777 48.5778 41.5555 49.1556 41.5555 49.9111V53.4222C41.7777 53.8667 42.5777 55.0222 44.8889 55.0222C46.4889 55.0222 47.6889 53.7778 48.0889 52.5333C48.1777 52.2667 48.4 51.4222 47.8666 51.0667C47.2444 50.6222 47.1111 49.8222 47.5555 49.2C48 48.5778 48.8 48.4445 49.4222 48.8889C50.7555 49.8222 51.2 51.5111 50.6222 53.3333C49.9555 55.4222 47.8666 57.6889 44.8444 57.6889Z"
        fill={color}
      />
      <path
        d="M54.7111 48.0444C54.2222 48.0444 53.7333 47.7778 53.5111 47.2889C53.2 46.6222 53.5111 45.8222 54.1778 45.5111L74.1333 36.2667C74.8 35.9556 75.6 36.2667 75.9111 36.9333C76.2222 37.6 75.9111 38.4 75.2444 38.7111L55.2889 47.9556C55.0667 48 54.8889 48.0444 54.7111 48.0444Z"
        fill={color}
      />
      <path
        d="M57.7333 51.4222C57.0222 51.4222 56.4 50.8445 56.4 50.0889C56.4 49.3333 56.9777 48.7556 57.7333 48.7556L70.8889 48.5778C71.6444 48.5778 72.2222 49.1556 72.2222 49.9111C72.2222 50.6667 71.6444 51.2445 70.8889 51.2445L57.7333 51.4222Z"
        fill={color}
      />
      <path
        d="M25.2889 48.2222C25.1111 48.2222 24.8889 48.1778 24.7111 48.0889L4.75553 38.8444C4.08886 38.5333 3.77775 37.7333 4.08886 37.0667C4.39997 36.4 5.19997 36.0889 5.86664 36.4L25.8222 45.6444C26.4889 45.9556 26.8 46.7556 26.4889 47.4222C26.2666 47.9556 25.7778 48.2222 25.2889 48.2222Z"
        fill={color}
      />
      <path
        d="M22.2667 51.6444L9.1111 51.4667C8.35555 51.4667 7.77777 50.8444 7.77777 50.1333C7.77777 49.4222 8.39999 48.8 9.1111 48.8L22.2667 48.9778C23.0222 48.9778 23.6 49.6 23.6 50.3111C23.5555 51.0667 22.9778 51.6444 22.2667 51.6444Z"
        fill={color}
      />
    </svg>
  );
};
IllustrationCat.defaultProps = {
  id: 'illustration-cat',
};

export default IllustrationCat;
