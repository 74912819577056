/**
 * Abstract Storage Base Abstract Class for Storage Methods
 *
 * @abstract
 * @class
 */
export default abstract class AbstractStorage {
  /**
   * Get the data from the storage.
   *
   * @abstract
   * @param {string} id - The id of the data to get.
   * @returns {object | null} - The data or null if not found.
   */
  abstract get(id: string): object | null;

  /**
   * Set the data in the storage.
   *
   * @abstract
   * @param {string} id - The id of the data to set.
   * @param {object | string} data - The data to set.
   */
  abstract set(id: string, data: object | string): void;

  /**
   * Delete the data from the storage.
   *
   * @abstract
   * @param {string} id - The id of the data to delete.
   */
  abstract delete(id: string): void;
}
