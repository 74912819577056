import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineTwitter: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      data-testid="icon-social-media-twitter"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9648 7.97108C19.9648 8.16386 19.9648 8.31807 19.9648 8.51084C19.9648 13.8699 15.8552 20 8.30137 20C5.95303 20 3.80039 19.3446 2 18.188C2.31311 18.2265 2.62622 18.2651 2.97847 18.2651C4.89628 18.2651 6.65753 17.6096 8.06654 16.5301C6.26614 16.4916 4.73973 15.3349 4.23092 13.7157C4.50489 13.7542 4.73973 13.7928 5.0137 13.7928C5.36595 13.7928 5.75734 13.7157 6.07045 13.6386C4.19178 13.253 2.78278 11.6337 2.78278 9.66747V9.62892C3.33072 9.93735 3.99609 10.0916 4.66145 10.1301C3.52642 9.39759 2.82192 8.16386 2.82192 6.7759C2.82192 6.00482 3.01761 5.31084 3.36986 4.73253C5.40509 7.16145 8.45793 8.78072 11.863 8.97349C11.7847 8.66506 11.7456 8.35663 11.7456 8.04819C11.7456 5.81205 13.5851 4 15.8552 4C17.0294 4 18.0861 4.46265 18.8689 5.27229C19.7691 5.07952 20.6693 4.73253 21.4521 4.26988C21.1389 5.23373 20.5127 6.00482 19.6517 6.50602C20.4736 6.42892 21.2955 6.19759 22 5.88916C21.4521 6.6988 20.7476 7.39277 19.9648 7.97108Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineTwitter;
